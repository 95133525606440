import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import { discoverySessionSummary, updateDiscoverySessionSummary } from "../../../constants/api-config";
import './selfPage.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../assests/logo.png';
import { withRouter } from 'react-router-dom';
import DirectiveLoader from '../../Functional-component/Loader/loader.component';

class Selfpage2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: '',
            arr: '',
            textareaContent: "",
            story: "",
            token: "",
            discoverySessionId: "",
            summaryId: '',
            prevTextContent: '',
            prevStory: '',
            isBool: '',
            sessionName: '',
            startLoading: true,
            placeholder: ''


        }
    }

    postDiscoverSummaryApiCall = () => {
        let token = this.state.token
        let discoverySessionId = this.state.discoverySessionId
        let longSummary = this.state.textareaContent
        let shortSummary = this.state.story
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },
            body: JSON.stringify({
                long_summary: longSummary,
                short_summary: shortSummary,
                discovery_session: discoverySessionId
            })
        }
        fetch(discoverySessionSummary, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                    }
                    else {
                        this.notifyWarning(result.message)
                    }
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    // function that handle Submit

    onSubmit = () => {
        if (this.state.isBool) {
            if (this.state.textareaContent === this.state.prevTextContent && this.state.story === this.state.prevStory) {
                this.props.history.push('/selfLanding')
            }
            else if (this.state.textareaContent !== this.state.prevTextContent || this.state.story !== this.state.prevStory) {
                this.updateDiscoverySessionSummary()
            }
        }
        else if (!this.state.isBool) {
            this.postDiscoverSummaryApiCall()
        }
    }

    // function that update discoverySessionSummary

    updateDiscoverySessionSummary = () => {
        let token = localStorage.getItem('token')
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "summary_id": this.state.summaryId,
                "long_summary": this.state.textareaContent,
                "short_summary": this.state.story
            })
        }

        fetch(updateDiscoverySessionSummary, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.success === "true") {
                    this.notifySuccess(result.message)
                }
            })
    }

    highLight = () => {
        document.execCommand("foreColor", false, "#FF0000");
        if (this.state.story.length > 0) {
            let data = `${this.state.story}, ${window.getSelection().toString()}`
            this.setState({ story: data })
        } else {
            this.setState({ story: window.getSelection().toString() });
        }
    }

    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let sessionName = localStorage.getItem('sessionName')
        this.setState({ sessionName: sessionName, placeholder: sessionName })
        let data = this.props.location.state.textareaData;
        let short_summary = this.props.location.state.shortSummary;
        let summary_id = this.props.location.state.summary_id;
        let isBool = this.props.location.state.isBool;
        this.setState({ textareaContent: data, story: short_summary, prevTextContent: data, prevStory: short_summary, isBool: isBool, startLoading: false });
        let value = localStorage.getItem('token')
        let service_id = localStorage.getItem('discoverySessionId')
        this.setState({ token: value, discoverySessionId: service_id, summaryId: summary_id })
    }

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/selfLanding') }
    });

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    render() {
        return <>
            {
                this.state.startLoading ? <DirectiveLoader /> :

                    <div className="col-sm-12 self-container">
                        <div className="col-sm-12 self-header">
                            <h4 className="self-header-h2" style={{ marginLeft: "20px" }} >Converging Session2 : {" " + this.state.sessionName}</h4>
                            <img src={logo} className="selfPage1Img" alt="" />
                        </div>
                        <div className="testChild1">
                            <header className="test-link-div">
                                <ContentEditable
                                    className="textEditor"
                                    style={{ height: "100%", color: 'black', overflow: "scroll" }}
                                    html={this.state.textareaContent}
                                    disabled={false}
                                    onChange={(e) => {
                                        this.setState({ textareaContent: e.target.value })
                                    }}
                                />
                            </header>
                            <header className="test-link-div">
                                <ContentEditable
                                    className="textEditor"
                                    placeholder={"Summarize your " + this.state.placeholder}
                                    html={this.state.story}
                                    style={{ height: "100%", color: 'black', overflow: "scroll" }}

                                    disabled={false}
                                    onChange={(e) => {
                                        this.setState({ story: e.target.value })
                                    }}
                                />
                            </header>
                        </div>
                        <div className="col-sm-12 self-footer-next-button">
                            <button onClick={this.onSubmit}>Save</button>
                        </div>
                        <ToastContainer hideProgressBar={false}></ToastContainer>
                    </div>
            }
        </>
    }
}

export default withRouter(Selfpage2)