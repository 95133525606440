import React, { Component } from 'react'
import './selfPage.css'
import ContentEditable from 'react-contenteditable'
import { getAllAnswers, discoverySessionSummary } from '../../../constants/api-config'
import logo from '../../../assests/logo.png'
import { withRouter } from 'react-router-dom'
import DirectiveLoader from '../../Functional-component/Loader/loader.component'

class Selfpage1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            arr: '',
            textareaContent: "",
            shortSummary: '',
            summaryId: '',
            isBool: '',
            testResult: '',
            sessionName: '',
            placeholder: '',
            startLoading: true
        }
        this.onSubmit = this.onSubmit.bind(this)
    }

    async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let sessionName = localStorage.getItem('sessionName')
        let token = localStorage.getItem('token')
        let discovery_session_id = localStorage.getItem('discoverySessionId')
        console.log(sessionName)
        await this.setState({ sessionName: sessionName, placeholder: sessionName, startLoading: false })
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },
            body: JSON.stringify({
                discovery_session_id: discovery_session_id
            })
        }
        // call API to get All answers
        fetch(getAllAnswers, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    let data1 = result.question_responses;
                    let resResult = result.test_result;
                    this.setState({ testResult: resResult })
                    console.log("res", this.state.testResult[0])
                    let questionHtml = `<h5 className="Summary">Test Result</h5>
                    <p>${this.state.testResult[0]}</p>`
                    data1.map(item => {
                        return questionHtml = questionHtml + `<div>
                        <h6>${item.question}</h6>
                        <p>${item.answer}</p>
                    </div>`})
                    this.setState({ data: questionHtml })
                },
                (error) => {
                    console.log("error", error)
                }
            )

        let discoverySessionSummaryURL = discoverySessionSummary + "?discovery_session_id=" + discovery_session_id;
        const requestOptionsForDiscoverySessionSummary = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },
        }
        // call API to get discoverySessionSummary
        fetch(discoverySessionSummaryURL, requestOptionsForDiscoverySessionSummary)
            .then(res => res.json())
            .then(
                (result) => {
                    let longSummary = result.long_summary
                    let shortSummary = result.short_summary
                    let summary_id = result.summary_id
                    if (longSummary === undefined && shortSummary === undefined) {
                        this.setState({ textareaContent: "", shortSummary: "", summaryId: "", isBool: false })
                    }
                    else {
                        this.setState({ textareaContent: longSummary, shortSummary: shortSummary, summaryId: summary_id, isBool: true })
                    }
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    onSubmit() {
        this.props.history.push({
            pathname: '/Selfpage2', state: {
                textareaData: this.state.textareaContent,
                shortSummary: this.state.shortSummary,
                summary_id: this.state.summaryId,
                isBool: this.state.isBool
            }
        })
    }

    render() {
        return <>
            {this.state.startLoading ? <DirectiveLoader /> :
                <div className="self-container">
                    <div className="self-header">
                        <h4 className="self-header-h2"  >Converging Session1 :{" " + this.state.sessionName}</h4>
                        <img src={logo} className="selfPage1Img" alt="" />
                    </div>
                    <div className="testChild1">
                        <header className="test-link-div">
                            <ContentEditable
                                className="textEditor text1"
                                html={this.state.data}
                                style={{ height: "100%", color: 'black', overflow: "scroll" }}

                                disabled={true}
                                onChange={(e) => {
                                    this.setState({ textareaContent: e.target.value })
                                }}
                            />
                        </header>
                        <header className="test-link-div">
                            <ContentEditable
                                className="textEditor text1"
                                style={{ height: "100%", color: 'black', overflow: "scroll" }}

                                placeholder={"List your " + this.state.placeholder}
                                html={this.state.textareaContent}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textareaContent: e.target.value })
                                }}
                            />
                        </header>
                    </div>
                    <div className="selfButton">
                        <button onClick={this.onSubmit}>Next</button>
                    </div>
                </div>

            }
        </>
    }
}

export default withRouter(Selfpage1)