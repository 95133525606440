import React, { Component } from 'react'
import './Failed.css'

export default class Failed extends Component {
    render() {
        return (
            <div id="parent-container">
                <div id="child">
                    <input id='inp' name="email" placeholder="Email" />
                    <button id="failedBtn" >Resend Email</button>
                </div>

            </div>
        )
    }
}
