import React from 'react'

export default function PathData(props) {
    return (
        <div className="col-sm-12 selected-mupps">
            <div className="selected-mupps-h4">
                <h4>{props.path}</h4>
                <input className="selected-mupps-checkbox" type="checkbox" defaultChecked={props.checked} onClick={props.click}  />
            </div>
        </div>
    )
}
