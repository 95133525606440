import React from 'react'

export default function Addtask(props) {
    return (
        <div className="col-sm-12 add-task-header">
            <h4>{props.Addtask}</h4>
            <button onClick={props.onClick}>{props.buttonname}</button>
        </div>
    )
}
