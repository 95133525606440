import './Privacy.css';

const Privacy = () => {
    return (
        <div className="container " >
            <h3 className="privacy">PRIVACY NOTICE</h3>
            <p>Last updated March 29, 2021 </p> 
            <p>Thank you for choosing to be part of our community at i-Infinity.in (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;).
                We are committed to protecting your personal information and your right to privacy. If you have any
                questions or concerns about this privacy notice, or our practices with regards to your personal
                information, please contact us at <a href = "mailto: bsuri@i-infinity.in" style={{fontSize:"15px"}}>bsuri@i-Infinity.in.</a>
            </p>

            <p>When you visit our website i-Infinity.in (the &quot;Website&quot;), use our mobile application, as the case may
                be (the &quot;App&quot;) and more generally, use any of our services (the &quot;Services&quot;, which include
                the Website and App), we appreciate that you are trusting us with your personal information. We
                take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way
                possible what information we collect, how we use it and what rights you have in relation to it. We
                hope you take some time to read through it carefully, as it is important.
            </p>

            <p>This privacy notice applies to all information collected through our Services (which, as described
                above, includes our Website and App), as well as any related services, sales, marketing or events.
            </p>

            <p><b>Please read this privacy notice carefully as it will help you understand what we do with the
                information that we collect.</b>
            </p>

            <h5>TABLE OF CONTENTS</h5>

            <ol style={{fontSize:'15px'}}>
                <li ><a href="#1" className="tableContent"> WHAT INFORMATION DO WE COLLECT?</a></li>
                <li><a href="#2" className="tableContent"> HOW DO WE USE YOUR INFORMATION?</a></li>
                <li><a href="#3" className="tableContent"> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
                <li><a href="#4" className="tableContent"> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
                <li><a href="#5" className="tableContent"> IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a></li>
                <li><a href="#6" className="tableContent">HOW LONG DO WE KEEP YOUR INFORMATION? </a></li>
                <li><a href="#7" className="tableContent"> HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                <li><a href="#8" className="tableContent">  DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                <li><a href="#9" className="tableContent">  WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                <li><a href="#10" className="tableContent"> CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                <li><a href="#11" className="tableContent"> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
                <li><a href="#12" className="tableContent">  DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                <li><a href="#13" className="tableContent"> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                <li><a href="#14" className="tableContent"> HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
                <li><a href="#15" className="tableContent capital"> Subscription Pricing and Duration</a></li>
                <li><a href="#16" className="tableContent capital"> Governing Law and Jurisdiction</a></li>
                <li><a href="#17" className="tableContent capital"> Updates</a></li>
                <li><a href="#18" className="tableContent capital"> Privacy Policy And Terms of use</a></li>
            </ol>

            <h6 id="1">1. WHAT INFORMATION DO WE COLLECT?</h6>
            <p>Information collected through our App.</p>
            <p><b>In Short: </b> We collect information regarding your mobile device when you use our App</p>
            <p>If you use our App, we also collect the following information:</p>
            <ul>
                <li>Mobile Device Access. We may request access or permission to certain features from your
                    mobile device, including your mobile device's camera, storage, and other features. If you
                    wish to change our access or permissions, you may do so in your device's settings.
                </li>
                <li>
                    In general, You can visit this Website without telling Us who You are or revealing any
                    information about Yourself. We, may use automated means to collect various types of
                    Information about You, Your computer or other device used to access Our Website. A
                    representative, non-exhaustive list of the types of automatically collected information may
                    include: network or Internet protocol address and type of browser You are using (e.g.,
                    Chrome, Safari, Firefox, Internet Explorer), the type of operating system You are using, (e.g.,
                    Microsoft Windows or Mac OS), mobile network, device identifiers, device settings, browser
                    settings, the web pages of the Website You have visited, Website visited before and after
                    You visited our Website, the type of handheld or mobile device used to view the Website
                    (e.g., iOS, Android), location information, and the content and advertisements You have
                    accessed, seen, forwarded and/or clicked on.
                </li>
            </ul>

            <p>This information is primarily needed to maintain the security and operation of our App, for
                troubleshooting and for our internal analytics and reporting purposes.
            </p>


            <h6 id="2">2. HOW DO WE USE YOUR INFORMATION?</h6>
            <p><b>In Short:</b> We process your information for purposes based on legitimate business interests, the
                fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
            </p>
            <p>We use personal information collected via our Services for a variety of business purposes described
                below. We process your personal information for these purposes in reliance on our legitimate
                business interests, in order to enter into or perform a contract with you, with your consent, and/or for
                compliance with our legal obligations. We indicate the specific processing grounds we rely on next
                to each purpose listed below.
            </p>
            <p>We use the information we collect or receive:</p>
            <ul>
                <li><b>To facilitate account creation and logon process.</b> If you choose to link your account with
                    us to a third-party account (such as your Google or Facebook account), we use the
                    information you allowed us to collect from those third parties to facilitate account creation
                    and logon process for the performance of the contract.
                </li>
                <li><b>To post testimonials.</b> We post testimonials on our Services that may contain personal
                    information. Prior to posting a testimonial, we will obtain your consent to use your name and
                    the content of the testimonial. If you wish to update, or delete your testimonial, please contact
                    us at bsuri@i-Infinity.in and be sure to include your name, testimonial location, and contact
                    information.
                </li>
                <li><b>Request feedback.</b> We may use your information to request feedback and to contact you
                    about your use of our Services.
                </li>
                <li><b>To enable user-to-user communications.</b> We may use your information in order to enable
                    user-to-user communications with each user's consent.
                </li>
                <li><b>To manage user accounts.</b> We may use your information for the purposes of managing our
                    account and keeping it in working order.
                </li>
                <li><b>To send administrative information to you. </b>We may use your personal information to send
                    you product, service and new feature information and/or information about changes to our
                    terms, conditions, and policies.
                </li>
                <li><b>To protect our Services.</b> We may use your information as part of our efforts to keep
                    our Services safe and secure (for example, for fraud monitoring and prevention).
                </li>
                <li><b>To enforce our terms, conditions and policies for business purposes, to comply with
                    legal and regulatory requirements or in connection with our contract.
                </b></li>
                <li><b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal
                    request, we may need to inspect the data we hold to determine how to respond.
                </li>
                <li><b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage your
                    orders, payments, returns, and exchanges made through the Services.
                </li>
                <li><b>Administer prize draws and competitions. </b>We may use your information to administer
                    prize draws and competitions when you elect to participate in our competitions.
                </li>
                <li><b>To deliver and facilitate delivery of services to the user. </b>We may use your information to
                    provide you with the requested service.
                </li>
                <li><b>To respond to user inquiries/offer support to users.</b> We may use your information to
                    respond to your inquiries and solve any potential issues you might have with the use of our
                    Services.
                </li>
                <li><b>To send you marketing and promotional communications.</b> We and/or our third-party
                    marketing partners may use the personal information you send to us for our marketing
                    purposes, if this is in accordance with your marketing preferences. For example, when
                    expressing an interest in obtaining information about us or our Services, subscribing to
                    marketing or otherwise contacting us, we will collect personal information from you. You
                    can opt-out of our marketing emails at any time (see the <a href="#" className="tableContent">"WHAT ARE YOUR PRIVACY
                    RIGHTS" </a> below).</li>
                <li><b>Deliver targeted advertising to you.</b> We may use your information to develop and display
                    personalized content and advertising (and work with third parties who do so) tailored to your
                    interests and/or location and to measure its effectiveness.
                </li>
                <li>Enforce the legal terms (including without limitation our policies and terms of service) that
                    govern your use of our Services, and/or for the purposes for which you provided the
                    Information.
                </li>
                <li>Prevent fraud or potentially illegal activities (including, without limitation, copyright
                    infringement) on or through Our Website or Services,
                </li>
                <li>Protect the safety of our other subscribers or users,</li>
                <li>Perform analysis regarding how you use the Services or any part thereof such market
                    research, including statistical analysis of user behaviour which We may disclose to third
                    parties in depersonalised, aggregated form.
                </li>
                <li>In order to enable Us to comply with any requirements imposed on Us by applicable law.</li>
                <li>In order to send You periodic communications (this may include e-mail), about features,
                    products and services, events and special offers. Such communications from Us may include
                    promotion of programs being organized by third parties on our website.
                </li>
            </ul>


            <h6 id="3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h6>
            <p><b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with
                services, to protect your rights, or to fulfill business obligations.
            </p>
            <p>We may process or share your data that we hold based on the following legal basis:</p>
            <ul>
                <li><b>Consent:</b> We may process your data if you have given us specific consent to use your
                    personal information for a specific purpose.
                </li>
                <li><b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve
                    our legitimate business interests.
                </li>
                <li><b>Performance of a Contract:</b> Where we have entered into a contract with you, we may
                    process your personal information to fulfill the terms of our contract.
                </li>
                <li><b>Legal Obligations:</b> We may disclose your information where we are legally required to do
                    so in order to comply with applicable law, governmental requests, a judicial proceeding,
                    court order, or legal process, such as in response to a court order or a subpoena (including in
                    response to public authorities to meet national security or law enforcement requirements).
                </li>
                <li><b>Vital Interests:</b> We may disclose your information where we believe it is necessary to
                    investigate, prevent, or take action regarding potential violations of our policies, suspected
                    fraud, situations involving potential threats to the safety of any person and illegal activities,
                    or as evidence in litigation in which we are involved.
                </li>
            </ul>
            <p>More specifically, we may need to process your data or share your personal information in the
                following situations:
            </p>
            <ul>
                <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or
                    during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                    a portion of our business to another company.
                </li>
                <li>In response to legal process, for example, in response to a court order or a subpoena, a law
                    enforcement or government agency's request or similar request.
                </li>
                <li>With third parties in order to investigate, prevent, or take action (in our sole discretion)
                    regarding potentially illegal activities, suspected fraud, situations involving potential threats
                    to any person, us, or the Website, or violations of our policies, the law or our Terms of Use,
                    to verify or enforce compliance with the policies governing our Website.
                </li>
                <li>We may share the User Information with Our affiliates or group companies, so they can
                    provide, improve and communicate with You about their own, or their marketing partners’
                    products and services.
                </li>
                <li>We reserve the right to disclose and transfer the User Information outside India. We will
                    comply with all relevant Data Protection legislation in relation to the period for which We
                    retain any User Information.
                </li>
                <li>To service providers or partners that we have engaged to perform business-related functions
                    on our behalf. This may include service providers that:
                </li>
                <ol type="a">
                    <li>conduct research and analytics.</li>
                    <li>create content.</li>
                    <li>provide customer, technical or operational support.</li>
                    <li>conduct or support marketing (such as email or advertising platforms).</li>
                    <li>fulfill orders and user requests.</li>
                    <li>host our Services, forums and online communities.</li>
                    <li>administer the Website.</li>
                    <li>maintain databases.</li>
                    <li>otherwise support our Services.</li>
                </ol>
            </ul>


            <h6 id="4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
            <p><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.</p>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
               store information. Specific information about how we use such technologies and how you can refuse
               certain cookies is set out in our Cookie Notice.
            </p>


            <h6 id="5">5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </h6>
            <p><b>In Short: </b>We may transfer, store, and process your information in countries other than your own.</p>
            <p>Our servers are located in. If you are accessing our Services from outside, please be aware that your
                information may be transferred to, stored, and processed by us in our facilities and by those third
                parties with whom we may share your personal information (see <a href="#" className="tableContent">"WILL YOUR INFORMATION
                BE SHARED WITH ANYONE?"</a> above), in and other countries.
            </p>
            <p>If you are a resident in the European Economic Area, then these countries may not necessarily have
                data protection laws or other similar laws as comprehensive as those in your country. We will
                however take all necessary measures to protect your personal information in accordance with this
                privacy notice and applicable law.
            </p>


            <h6 id="6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
            <p><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
            </p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>

            <h6 id="7">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
            <p><b>In Short: </b>We aim to protect your personal information through a system of organizational and technical security measures.</p>
            <p>Security is very important to Us. All security procedures are in place to protect the confidentiality, integrity and availability of Your User Information. We maintain strict physical, electronic, and administrative safeguards to protect Your User Information including your personal information from unauthorized or inappropriate access.</p>
            <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
            <p>We follow generally accepted standards to collect, store and protect personal data, including the use of encryption. We retain personal data for as long as it is needed to provide the services you have requested, and thereafter for legal and service purposes. These may include retention periods mandated by legal, contractual, or similar obligations; for resolving, preserving, enforcing or defending our legal and contractual rights; needed to maintain adequate and accurate business and financial records or how you access, update, or delete your data etc.</p>
            <p>This website will take all reasonable efforts to ensure the confidentiality of personal data, uploaded information etc. and will take reasonable efforts to ensure that the information received from you is not misused. This website also reveals personal data/information uploaded by you in connection with any lawful process. While this website will take the above reasonable measures to guard against misuse of personal data/information submitted to it by you, this website cannot guarantee that someone will not overcome our security measures, including, without limitation, the security measures implemented on this Web site. Therefore, your posting of personal data/ information on this Web site constitutes your acceptance of this risk, and by posting of personal data/information, you waive any right to seek legal relief from this website due to any misuse of your information.</p>


            <h6 id="8">8. DO WE COLLECT INFORMATION FROM MINORS?</h6>
            <p><b>In Short: </b> We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at bsuri@i-Infinity.in.</p>


            <h6 id="9">9. WHAT ARE YOUR PRIVACY RIGHTS?</h6>
            <p><b>In Short:</b>  You may review, change, or terminate your account at any time.</p>
            <p>If you have questions or comments about your privacy rights, you may email us at bsuri@i-infinity.in.</p>
            <p><b>Account Information</b></p>
            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
            <ul><li>Contact us using the contact information provided.</li></ul>
            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</p>
            <p><b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit <a href="http://www.aboutads.info/choices/" target="_blank" className="tableContent">http://www.aboutads.info/choices/.</a></p>
            <p><b>Opting out of email marketing:</b> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</p>
            <ul><li>Contact us using the contact information provided.</li></ul>


            <h6 id="10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice. </p>


            <h6 id="11">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h6>
            <p><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>
            <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
            <p>If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>


            <h6 id="12">12. DO WE MAKE UPDATES TO THIS NOTICE? </h6>
            <p><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>


            <h6 id="13">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
            <p>If you have questions or comments about this notice, you may email us at bsuri@i-Infinity.in or by post to:</p>
            <p>i-Infinity.in <br/>1104, Tower 5, Unitech Uniworld Garden, Sohna Road <br/>Gurgaon, Haryana 122018 <br/>India</p>

            <h6 id="14">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h6>
            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking <a href="#" className="tableContent">here</a>. We will respond to your request within 30 days.</p>
            
            <h6 id ="15">15. Subscription Pricing and Duration</h6>
            <p>We have divided our all features based on time and  the user does not have any level of access restrictions , if you buy any subscription you’ll get all features of the app. You can buy any of the following subscription
            </p>
            <ul>
                <li>Pro Access For One month <br></br>
	                Duration- 1 Month <br></br>
	                Pricing-    $0.49
                </li>
                <li>Pro Access For Six month <br></br>
	                Duration- 6 Month <br></br>
	                Pricing-    $5.49
                </li>
                <li>
                    Pro Access For One Year <br></br>
	                Duration- 1 Year <br></br>
	                Pricing-   $8.99
                </li>
            </ul>

            <h6 id="16">16. Governing Law and Jurisdiction</h6>
            <p>This Privacy Policy is governed by and operated in accordance with the laws of India. If any of the parties wish to seek legal recourse, they may do so by using the courts of law in New Delhi.</p>

            <h6 id="17">17. Updates</h6>
            <p>We may change this privacy policy from time to time and You should check these regularly. Your use of the Website will be deemed an acceptance of the privacy policy existing at that time.</p>
            
            <h6 id="18">18.Privacy Policy And Terms of use</h6>
            <p>Terms of use: <a className="tableContent" href="https://i-infinitytransformations.com/TermsConditions/">https://i-infinitytransformations.com/TermsConditions/</a></p>
            <p>Privacy policy: <a className="tableContent" href="https://i-infinitytransformations.com/privacy/">https://i-infinitytransformations.com/privacy/</a>
</p>
        </div>
    );
};



export default Privacy;

