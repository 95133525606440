


import React, { Component } from 'react';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { picos } from "../../../constants/api-config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import './taskmodalStyle.css'

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class Picosmodal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image1: null,
            image2: null,
            heading: "",
            description: "",
            imagePreviewOne: null,
            imagePreviewTwo: null
        }
    }

    handleFirstImageChange = (e) => {
        console.log("e.target.file", e.target.files[0])
        if (e.target.files && e.target.files[0]) {
            this.setState({ image1: e.target.files[0] });
            let reader = new FileReader();
            reader.onload = function (ev) {
                this.setState({ imagePreviewOne: ev.target.result.split(',')[1] });
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }

        //this.setState({ image1: e.target.files[0] })
    }

    handleSecondImageChange = (e) => {
        console.log("e.target.file", e.target.files[0])
        if (e.target.files && e.target.files[0]) {
            this.setState({ image2: e.target.files[0] });
            let reader = new FileReader();
            reader.onload = function (ev) {
                this.setState({ imagePreviewTwo: ev.target.result.split(',')[1] });
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
        //this.setState({ image2: e.target.files[0] })
    }

    handleSubmit = (e) => {

        const data = new FormData();
        data.append("mupp", this.props.muppId);
        data.append("heading", this.state.heading);
        data.append("rough_text", " mobile demo text ");
        data.append("description", this.state.description);
        if (this.state.image1) {
            data.append("image1", this.state.image1);
        } else {
            data.append("image1", "");
        }
        if (this.state.image2) {
            data.append("image2", this.state.image2);
        } else {
            data.append("image2", "");
        }

        const requestOptions = {
            method: 'POST',
            headers: { "Authorization": `JWT ${this.props.token}`, },
            body: data
        };

        fetch(picos, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res", result)
                    if (result.success === 'true') {
                        this.notifySuccess(result.message)

                    }
                    else {
                        this.notifyWarning(result.message)
                    }

                },
                (error) => {
                    console.log("Registration Failed")
                }
            )
    };

    removeFirstImage = () => {
        this.setState({
            image1: null,
            imagePreviewOne: null
        })
    }

    removeSecondImage = () => {
        this.setState({
            image2: null,
            imagePreviewTwo: null
        })
    }



    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 3000,
    });

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 3000,
        onClose: () => this.props.addModal()

    });


    render() {
        return (
            <div style={{ marginTop: "20%" }}>
                <Modal open={this.props.open} onClose={this.props.onCloseModal} onOpen={this.props.onOpen}>
                    <div style={{ height: "65vh" }}>
                        <hr></hr>
                        <input
                            style={{ height: "15%", width: "100%", marginTop: "1%", marginBottom: '5%' }}
                            placeholder="Picos Heading"
                            onChange={e => this.setState({ heading: e.target.value })}
                        />
                        <br />
                        <input
                            style={{ height: "15%", width: "100%", marginBottom: '5%' }}
                            placeholder="Picos Description"
                            onChange={e => this.setState({ description: e.target.value })}
                        />
                        <br />

                        <div>
                            <div style={{ display: 'flex', paddingLeft: "30px", marginTop: "5%" }}>
                                <div className="image1">
                                    <h6 style={{ color: 'black' }}>Image 1</h6>

                                    <input
                                        id="files"
                                        type="file"
                                        name="image1"
                                        onChange={this.handleFirstImageChange}
                                        className={!this.state.imagePreviewOne ? 'image-input' : 'hidden'}
                                        accept="image/x-png,image/jpeg,image/jpg" />
                                    {this.state.imagePreviewOne && <i className="fa fa-close cross-icon" onClick={this.removeFirstImage}></i>}
                                    {this.state.imagePreviewOne &&
                                        <label htmlFor="files">
                                            <img src={"data:image/png;base64," + this.state.imagePreviewOne} className="thumbnail" alt="image1" />
                                        </label>
                                    }


                                </div>
                                <div className="image1">
                                    <h6 style={{ color: 'black' }}>Image 2</h6>

                                    <input
                                        id="files"
                                        type="file"
                                        name="image2"
                                        onChange={this.handleSecondImageChange}
                                        className={!this.state.imagePreviewTwo ? 'image-input' : 'hidden'}
                                        accept="image/x-png,image/jpeg,image/jpg" />
                                    {this.state.imagePreviewTwo && <i className="fa fa-close cross-icon" onClick={this.removeSecondImage}></i>}
                                    {this.state.imagePreviewTwo &&
                                        <label htmlFor="files">
                                            <img src={"data:image/png;base64," + this.state.imagePreviewTwo} className="thumbnail" alt="image1" />
                                        </label>
                                    }


                                </div>
                            </div>


                        </div>
                        <button onClick={e => this.handleSubmit()} style={{ marginLeft: "30%", marginTop: "10%", borderRadius: "12px" }}>Save</button>

                    </div>
                    <ToastContainer hideProgressBar={false}
                    >
                    </ToastContainer>

                </Modal>
            </div >
        );
    }
}

export default Picosmodal;
