import React, { Component } from "react";
import Header from "../Functional-component/Header/Header";
import {
  getTodayTodo,
  unselectTaskTodayAPI,
  completeDailyHabit,
  completeTask,
  getTasksByProject,
  postTask,
  deleteTask,
} from "../../constants/api-config";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./todoStyle.css";
import DirectiveLoader from "../Functional-component/Loader/loader.component";
import Taskmodal from "../Functional-component/task-modal/Taskmodal";
import { THIS_WEEK, AWAITING, LATER } from "../../constants/app-defaults";
import EditTask from "../shared/editTask";
import DeleteTask from "../shared/deleteTask";

import MoveTask from "../shared/moveTask";
import Movefolder from "../../assests/moveFolder.png";

const Tabs = ({ active, count, onClick }) => {
  return (
    <li onClick={onClick} className={active ? "active" : ""}>
      {count}
    </li>
  );
};
export default class Todo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dailyHabbitData: "",
      reminderData: "",
      tasksData: "",
      isdaily: true,
      isReminder: false,
      isTasks: false,
      isLoading: false,
      tabResults: [
        { key: 1, name: "Tasks" },
        { key: 2, name: "Daily Habit" },
        { key: 3, name: "Reminder" },
      ],
      chosen: "Tasks",
      isModalVisibile: false,
      projectId: "",
      taskName: "",
      taskDate: null,
      taskType: "THIS_WEEK",
      completed: false,
      taskID: "",
      editTaskName: "",
      editTaskDate: "",
      editTaskVisibleDate: null,
      taskID: "",
      editTaskType: [],

      //moveStates
      moveTaskName: "",
      moveTaskDate: "",
      moveTaskId: "",
      moveTaskType: "",
      moveMuppCategoryId: "",
      moveMuppPath: "",
      moveProjectId: "",
      moveProjectName: "",
      moveMuppID: "",
    };
  }

  setSelected = (tab) => {
    this.setState({ selected: tab });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.getTodayTodoAPICall();
  }

  getTodayTodoAPICall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getTodayTodo, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === true) {
            this.setState({
              dailyHabbitData: result.daily_habits,
              reminderData: result.reminders,
              tasksData: result.tasks,
              isLoading: true,
            });
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  completeDailyHabitAPICall = (id) => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        habit_id: id,
      }),
    };
    fetch(completeDailyHabit, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.getTodayTodoAPICall();
            this.notifySuccess(result.message);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  dailyHabitSelect = (item) => {
    this.completeDailyHabitAPICall(item.id);
  };

  dailyHabitList = () => {
    if (this.state.dailyHabbitData && this.state.dailyHabbitData.length > 0) {
      return this.state.dailyHabbitData ? (
        this.state.dailyHabbitData.map((item) => (
          <ul className="todo-tabs" style={{ marginTop: "1em" }}>
            <li className="col-sm-12 todo-tab-li">
              <div className="col-sm-10 padding-0">
                <h6
                  data-tip={item.name}
                  data-for="registerTipforDailyHabit"
                  className="elipse-text-for-title"
                >
                  {item.name}
                </h6>
              </div>
              <ReactTooltip
                id="registerTipforDailyHabit"
                type="info"
                place="right"
                effect="solid"
              ></ReactTooltip>
              <div className="col-sm-2 padding-0 dailyHabbitBtn ">
                <button onClick={this.dailyHabitSelect.bind(this, item)}>
                  Done
                </button>
              </div>
            </li>
          </ul>
        ))
      ) : (
        <DirectiveLoader />
      );
    } else {
      return (
        <ul className="inbox-tabs" style={{ marginTop: "1em" }}>
          <li className="nodata">No Results Found</li>
        </ul>
      );
    }
  };

  reminderList = () => {
    if (this.state.reminderData && this.state.reminderData.length > 0) {
      return this.state.reminderData ? (
        this.state.reminderData.map((item) => (
          <ul className="todo-tabs" style={{ marginTop: "1em" }}>
            <li className="todo-tab-li">{item.name}</li>
          </ul>
        ))
      ) : (
        <DirectiveLoader />
      );
    } else {
      return (
        <ul className="inbox-tabs" style={{ marginTop: "1em" }}>
          <li className="nodata">No Results Found</li>
        </ul>
      );
    }
  };

  completeTaskAPICall = (id) => {
    console.log("task id in complete task ", id);
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(completeTask, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result", result);

          if (result.success === "true") {
            this.getTodayTodoAPICall();
            this.notifySuccess(result.message);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  taskSelect = (item) => {
    this.setState({
      isModalVisibile: true,
      projectId: item.proj_id,
      taskName: item.task_name,
      taskID: item.task_id,
    });
    console.log(item);
  };
  onCloseModal = () => {
    this.setState({ isModalVisibile: false });
  };

  handleNow = () => {
    this.openNavR();
    this.onCloseModal();
  };

  openNavR = () => {
    document.getElementById("rightSlidePanel").style.display = "block";
    document.getElementById("closeIcon").style.display = "block";
  };

  handleDate = (e) => {
    console.log(e.target.value);
    this.setState({ taskDate: e.target.value });
  };

  handleTaskType = (e) => {
    console.log(e.target.value);
    this.setState({ taskType: e.target.value });
  };

  handleSubmitTask = () => {
    this.addTaskAPIcall();

    this.completeTaskAPICall(this.state.taskID);
    this.onClose();
  };

  addTaskAPIcall = () => {
    this.value = localStorage.getItem("token");

    var data = {
      project: this.state.projectId,
      name: this.state.taskName,
      due_date: this.state.taskDate,
      task_type: this.state.taskType,
      completed: this.state.completed,
    };
    fetch(postTask, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.notifySuccess("Successfully");
      })
      .catch((err) => {
        this.notifyWarning("Something Went Wrong");
      });
  };

  handleLater = () => {
    let id = this.state.taskID;
    this.completeTaskAPICall(id);
    this.onCloseModal();
  };

  onClose = () => {
    console.log("hitt");
    document.getElementById("rightSlidePanel").style.display = "none";
    document.getElementById("closeIcon").style.display = "none";
  };

  renderTask = () => {
    return (
      <div>
        <i
          className="fa fa-close close-icon"
          id="closeIcon"
          style={{ display: "none" }}
          onClick={() => this.onClose()}
          aria-hidden="true"
        ></i>
        <ul className="render-task">
          <div
            className="task-accordion"
            id="rightSlidePanel"
            style={{ display: "none" }}
          >
            <div className="form-group">
              <textarea
                type="text"
                defaultValue={this.state.taskName}
                name="Title"
                style={{ height: "20vh", marginTop: "20px" }}
                className="form-control"
                required
                onChange={(e) => this.setState({ taskName: e.target.value })}
              />
            </div>
            <div className="form-group">
              <select
                name="task-type"
                onChange={this.handleTaskType}
                className="form-control"
              >
                <option value="DEFAULT">Select Task type</option>
                <option value="THIS_WEEK">THIS_WEEK</option>
                <option value="LATER">LATER</option>
                <option value="AWAITING">AWAITING</option>
              </select>
            </div>

            <div className="form-group">
              <input
                onChange={this.handleDate}
                min={new Date().toISOString().split("T")[0]}
                type="date"
                name="date"
                className="form-control"
                required
              />
            </div>
            <div className="col-sm-12 add-mupps-button">
              <button type="submit" onClick={this.handleSubmitTask}>
                Save
              </button>
            </div>
          </div>
        </ul>
      </div>
    );
  };

  getTaskColor = (item) => {
    let color = "";
    if (item.task_type === "THIS_WEEK") {
      color = THIS_WEEK;
      return color;
    } else if (item.task_type === "AWAITING") {
      color = AWAITING;
      return color;
    } else if (item.task_type === "LATER") {
      color = LATER;
      return color;
    } else return color;
  };

  updateTask = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    var data = {
      task_id: this.state.taskID,
      task_name: this.state.editTaskName,
      date: this.state.editTaskVisibleDate ? this.state.editTaskDate : null,
      task_type: this.state.editTaskType,
      completed: false,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(data),
    };
    fetch(getTasksByProject, requestOptions)
      .then((response) => response.json())
      .then(async (response) => {
        console.log("response after updating ", response.data);
        await this.closePanelForEdit();
        if (response.success === "true") {
          this.notifySuccess(response.message);
          this.getTodayTodoAPICall();
        }
        // document.getElementById('rightSlidePanel').style.display = 'none';
        document.getElementById("editTaskNameTextArea").value = "";
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  openEditModal = async (task) => {
    await this.setState({
      editTaskName: task.task_name,
      editTaskType: task.task_type,
      editTaskDate: task.date,
      editTaskVisibleDate: task.date,
      taskID: task.task_id,
    });
    console.log(task);
    document.getElementById("rightSlidePanel").style.display = "block";
    document.getElementById("editTaskNameTextArea").value = task.task_name;
    document.getElementById("editTaskDateTextArea").value = task.date;
  };

  closePanelForEdit = async () => {
    await this.setState(
      { editTaskName: "", editTaskVisibleDate: "", editTaskDate: "" },
      () => {
        if (document.getElementById("editTaskNameTextArea")) {
          document.getElementById("editTaskNameTextArea").value = "";
          console.log("value is ");
          document.getElementById("rightSlidePanel").style.display = "none";
        }
      }
    );
  };

  closePanel = async () => {
    console.log("closed");
    document.getElementById("moveTaskRightSlidePanel").style.display = "none";
    document.getElementById("moveTaskCloseIcon").style.display = "none";
    await this.setState({
      moveTaskName: "",
      moveTaskDate: "",
      moveTaskId: "",
      moveTaskType: "",
      moveMuppCategoryId: "",
      moveMuppPath: "",
      moveProjectId: "",
      moveProjectName: "",
      moveMuppID: "",
    });
  };

  handleEditTaskName = (e) => {
    this.setState({ editTaskName: e.target.value });
  };

  handleEditTaskType = (e) => {
    this.setState({ editTaskType: e.target.value });
  };

  handleEditDate = (e) => {
    this.setState({
      editTaskDate: e.target.value,
      editTaskVisibleDate: e.target.value,
    });
  };

  clearEditDate = () => {
    this.setState({ editTaskDate: "", editTaskVisibleDate: null });
  };

  deleteTaskApicall = (id) => {
    let deleteTaskURL = deleteTask + "?" + "task_id=" + id;

    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(deleteTaskURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("res3", result);
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.getTodayTodoAPICall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - waiting.js",
            error
          );
        }
      );
  };

  deleteTask = (id) => {
    console.log("Delete TASK ID", id);
    this.deleteTaskApicall(id);
  };
  moveTask = async (item) => {
    await this.setState({
      moveTaskName: item.task_name,
      moveTaskDate: item.due_date,
      moveTaskId: item.task_id,
      moveTaskType: item.task_type,
      moveMuppCategoryId: item.mupp_category_id,
      moveMuppPath: item.mupp_path,
      moveProjectId: item.proj_id,
      moveProjectName: item.project_name,
      moveMuppID: item.mupp_id,
    });

    //localStorage.setItem("task", JSON.stringify(task));
    document.getElementById("moveTaskRightSlidePanel").style.display = "block";
    document.getElementById("moveTaskCloseIcon").style.display = "block";
  };

  unselectTaskTodayAPI = (id) => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(unselectTaskTodayAPI, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.getTodayTodoAPICall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  tasksList = () => {
    if (this.state.tasksData && this.state.tasksData.length > 0) {
      return this.state.tasksData ? (
        this.state.tasksData.map((item) => {
          console.log(item);
          return (
            <ul className="todo-tabs" style={{ marginTop: "1em" }}>
              <li
                style={{
                  borderStyle: "solid",
                  borderLeftColor: this.getTaskColor(item),
                  borderWidth: "8px",
                }}
                className="col-sm-12 todo-tab-li"
              >
                <div className="col-sm-9 padding-0">
                  <h5
                    onClick={this.openEditModal.bind(this, item)}
                    style={{
                      ...styles.textMultiLineLimit,
                      marginBottom: "0 !important",
                    }}
                    data-tip={item.task_name}
                    data-for="registerTipforTask"
                  >
                    {item.task_name}
                  </h5>
                  <ReactTooltip
                    id="registerTipforTask"
                    type="info"
                    place="top"
                    effect="solid"
                  ></ReactTooltip>
                  <div className="dis-flex">
                    <label
                      data-tip={item.project_name}
                      data-for="registerTipforProject"
                      className="elipse-text"
                      style={{
                        marginRight: "1em",
                        marginBottom: "0 !important",
                      }}
                    >
                      {item.project_name}
                    </label>
                    <ReactTooltip
                      id="registerTipforProject"
                      type="info"
                      place="top"
                      effect="solid"
                    ></ReactTooltip>
                    <label
                      data-tip={item.mupp_path}
                      data-for="registerTipforPath"
                      className="elipse-text"
                      style={{ marginBottom: "0 !important" }}
                    >
                      {item.mupp_path}
                    </label>

                    <ReactTooltip
                      id="registerTipforPath"
                      place="top"
                      type="info"
                      effect="solid"
                    ></ReactTooltip>
                    <label>{item.task_type}</label>
                    <label style={{ marginLeft: "20px" }}>
                      <i
                        className="fa fa-calendar calendar-icon"
                        aria-hidden="true"
                      ></i>
                      {item.due_date ? item.due_date : "No Date"}
                    </label>
                  </div>
                </div>
                <div className="col-sm-3 padding-0 dailyHabbitBtn">
                  <button
                    onClick={this.unselectTaskTodayAPI.bind(this, item.task_id)}
                  >
                    Deselect
                  </button>

                  <DeleteTask
                    deleteTask={this.deleteTask.bind(this, item.task_id)}
                  />

                  <img
                    onClick={this.moveTask.bind(this, item)}
                    src={Movefolder}
                    height="25px"
                    width="25px"
                    style={{
                      background: "white",
                      cursor: "pointer",
                      marginLeft: "20px",
                      marginTop: "-10px",
                    }}
                  />
                  <i
                    class="fa fa-check fa-2x"
                    onClick={this.taskSelect.bind(this, item)}
                    style={{
                      background: "white",
                      cursor: "pointer",
                      marginLeft: "20px",
                    }}
                  ></i>
                </div>
                {this.state.isModalVisibile && (
                  <Taskmodal
                    open={this.state.isModalVisibile}
                    onCloseModal={this.onCloseModal}
                    nowText={this.handleNow}
                    laterText={this.handleLater}
                    isvisibile={true}
                  />
                )}

                {
                  <EditTask
                    closePanelForEdit={this.closePanelForEdit}
                    editTaskName={this.state.editTaskName}
                    handleEditTaskName={this.handleEditTaskName}
                    handleTaskType={this.handleEditTaskType}
                    editTaskType={this.state.editTaskType}
                    editTaskDate={this.state.editTaskDate}
                    clearEditDate={this.clearEditDate}
                    handleEditDate={this.handleEditDate}
                    updateTask={this.updateTask}
                  />
                }
              </li>
            </ul>
          );
        })
      ) : (
        <DirectiveLoader />
      );
    } else {
      return (
        <ul className="inbox-tabs" style={{ marginTop: "1em" }}>
          <li className="nodata">No Results Found</li>
        </ul>
      );
    }
  };

  notifyWarning = (text) =>
    toast.warning(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  toggle = (item) => {
    this.setState({ chosen: item.name });
  };

  render() {
    let chosenMuppCategory = {};
    return (
      <div>
        <Header header="Today TODO" />
        <ul className="btn-nav-tabs-todo">
          {this.state.tabResults.map((item, index) => {
            return (
              <Tabs
                key={index}
                count={item.name}
                active={item.name === this.state.chosen}
                onClick={this.toggle.bind(this, item)}
              />
            );
          })}
        </ul>
        <ul className="all-categories-tab padding-0">
          {this.state.chosen == "Daily Habit" && this.dailyHabitList()}
          {this.state.chosen == "Reminder" && this.reminderList()}
          {this.state.chosen == "Tasks" && this.tasksList()}
        </ul>
        {this.renderTask()}
        <ToastContainer hideProgressBar={false}></ToastContainer>
        {
          <MoveTask
            taskType={this.state.moveTaskType}
            taskName={this.state.moveTaskName}
            taskDate={this.state.moveTaskDate}
            isLoading={this.state.isLoading}
            taskID={this.state.moveTaskId}
            notifySuccess={this.notifySuccess}
            notifyWarning={this.notifyWarning}
            closePanel={this.closePanel}
            fetchTaskData={this.getTodayTodoAPICall}
            projectID={this.state.moveProjectId}
            projectName={this.state.moveProjectName}
            muppID={this.state.moveMuppID}
            muppPath={this.state.moveMuppPath}
            moveMuppCategoryId={this.state.moveMuppCategoryId}
          />
        }
      </div>
    );
  }
}

let styles = {
  elipseTextMupps: {
    width: "25%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  elipseTextForTitleMupp: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  taskWrapper: {
    display: "flex",
    display: "block",
    flexDirection: "row",
  },
  textMultiLineLimit: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
};
