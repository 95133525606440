/**
 * Local setup
 */
// http://103.46.239.133:8876/
// const url = "https://infinitybackend.oodleslab.com/";

const url = "https://api.i-infinitytransformations.com/";
// const url = "http://172.105.33.81:8076/"
// const url = "https://22bc-2401-4900-1202-dda-4ed-4754-5876-4e74.ngrok.io/";

// local 
// const url = "http://127.0.0.1:8000/"

const API_CONFIG = {
  baseUrl: url,
  CDN: "https://d1xgj9xx6egt0d.cloudfront.net/media/",
  imageAPI: "https://picsum.photos/200/300?random=",
  tokenKey: "access-token",
  registerUser: url + "register_user/",
  userLogin: url + "login_user/",
  resetPassword: url + "reset_password/",
  resendVerificationMail: url + "resend_verification_email/",
  verifyEmail: url + "verify_email/",
  forgetPassword: url + "forget_password/",
  notification: url + "notification/",
  getMuppCategories: url + "get_mupp_categories/",
  mupp: url + "mupp/",
  muppOrderDD: url + "muppo/",
  getActiveServices: url + "get_active_services/",
  getDiscoverySession: url + "get_discovery_sessions/",
  project: url + "project/",
  questions: url + "get_questions/",
  postAnswer: url + "post_answer/",
  getAllAnswers: url + "get_all_answers/",
  addPicos: url + "picos/",
  getAllSummary: url + "get_all_summaries/",
  postTestResult: url + "post_test_result/",
  getAnswers: url + "get_answers/",
  discoverySessionSummary: url + "discovery_session_summary/",
  getTestLink: url + "get_test_link/",
  getTasksByType: url + "tasks_by_type/",
  updateAnswer: url + "update_answer/",
  postMissionStatement: url + "post_mission_statement/",
  getMuppPicosProjects: url + "get_mupp_picos_projects",
  updateMissionStatement: url + "post_mission_statement/",
  postMupp: url + "mupp/",
  updateDiscoverySessionSummary: url + "discovery_session_summary/",
  muppRating: url + "mupp_rating/",
  postSelectMUPP: url + "select_mupps/",
  getAllMupps: url + "get_all_mupps/",
  selectTaskForToday: url + "select_task_for_today/",
  updateProjects: url + "update_project_ranks/",
  UpdateProjectmuppRanks : url + "update_project_ranks_mupps_after_dels/",
  EditProjecttitle : url + "edit_project_title/",
  getTasksByProject: url + "task/",
  postDailyHabit: url + "daily_habits/",
  getDailyHabit: url + "daily_habits/",
  updateDailyHabit: url + "daily_habits/",
  postInbox: url + "inbox/",
  getInbox: url + "inbox/",
  updateInbox: url + "inbox/",
  postReminder: url + "reminder/",
  getReminder: url + "reminder/",
  updateReminder: url + "reminder/",
  getTodayTodo: url + "get_today_todos/",
  getAllTasksList: url + "get_task_types",
  completeDailyHabit: url + "complete_daily_habit/",
  completeTask: url + "complete_task/",
  postTask: url + "task/",
  moveToProject: url + "move_inbox_task/",
  moveProject: url + "move_project/",
  unselectTaskTodayAPI: url + "unselect_task_for_today/",
  updateMupp: url + "mupp/",
  picos: url + "picos/",
  buyService: url + "index/?price_id=",
  getTaskByWeek: url + "get_tasks_by_week/",
  deleteDailyHabbit: url + "daily_habits/",
  deleteTask: url + "task/",
  deleteInbox: url + "inbox/",
  DeleteProjecttitle: url + "delete_project_title/",
  getProject: url + "project/",
  getMissionStatement: url + "get_mission_statement/",
  deleteReminder: url + "reminder/",
  getMupp: url + "mupp/",
  checkSession: url + "session_answers_completed/",
  allSessionCompleted: url + "all_sessions_completed/",
  moveTask: url + "move_task/",
  searchAPI: url + "search/",
  getAllReminder: url + "get_all_reminder/",
  updateMuppRanks: url + "update_mupp_ranks/",
  UpdateMuppRanksafterDD: url + "update_mupp_ranks_dd/",
  UpdateMuppPath :url + "update_mupp_paths/",
  getPos: url + "get_pos/",
  getMuppsWithAllPos: url + "get_mupps_with_all_pos/",
  getBackup: url + "get_backup/",
  learningLog: url + "learning_log/",
  learningLogExp: url + "learning_log_expansion/",
  dailyJournal: url + "daily_journal/",
  dailyJournalExp: url + "daily_journal_expansion/",
  dailyJournalPass: url + "daily_journal_passcode/",
  sessionList: url + "sessionlist/",
  articleList: url + "articlelist/",
  videoList: url + "videolist/",
  pdfList: url + "pdflist/",
};

export const {
  baseUrl,
  CDN,
  imageAPI,
  muppOrderDD,
  tokenKey,
  notification,
  registerUser,
  userLogin,
  resetPassword,
  resendVerificationMail,
  UpdateMuppRanksafterDD,
  forgetPassword,
  getMuppCategories,
  mupp,
  UpdateProjectmuppRanks,
  getActiveServices,
  getTasksByType,
  project,
  questions,
  postAnswer,
  verifyEmail,
  getAllAnswers,
  addPicos,
  postTestResult,
  getAnswers,
  discoverySessionSummary,
  getAllSummary,
  getDiscoverySession,
  getTestLink,
  updateAnswer,
  postMissionStatement,
  getMuppPicosProjects,
  updateMissionStatement,
  postMupp,
  updateDiscoverySessionSummary,
  muppRating,
  postSelectMUPP,
  getAllMupps,
  selectTaskForToday,
  updateProjects,
  DeleteProjecttitle,
  EditProjecttitle,
  getTasksByProject,
  postDailyHabit,
  getDailyHabit,
  updateDailyHabit,
  postInbox,
  getInbox,
  updateInbox,
  postReminder,
  getReminder,
  updateReminder,
  getTodayTodo,
  getAllTasksList,
  completeDailyHabit,
  completeTask,
  postTask,
  moveToProject,
  unselectTaskTodayAPI,
  updateMupp,
  picos,
  buyService,
  getTaskByWeek,
  deleteDailyHabbit,
  deleteTask,
  UpdateMuppPath,
  deleteInbox,
  getProject,
  getMissionStatement,
  deleteReminder,
  getMupp,
  checkSession,
  allSessionCompleted,
  moveTask,
  moveProject,
  searchAPI,
  getAllReminder,
  updateMuppRanks,
  getPos,
  getMuppsWithAllPos,
  getBackup,
  learningLog,
  learningLogExp,
  dailyJournal,
  dailyJournalExp,
  dailyJournalPass,
  sessionList,
  articleList,
  videoList,
  pdfList,
} = API_CONFIG;
