import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./addLogmodal.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: "",
      type: props.type,
    };
  }

  render() {
    return (
      <div style={{ backgroundColor: "green" }}>
        <Modal
          open={this.props.open}
          onClose={this.props.onCloseModal}
          onOpen={this.props.onOpen}
          classNames={{
            modal: "logCustomModal",
          }}
        >
          <div style={{ width: "50vw" }}>
            <input
              type="text"
              // className="textAreamodal"
              className="form-control my-3"
              onChange={this.props.onChange}
              defaultValue={this.props.value}
              placeholder="Enter heading . . ."
            />
            <div
              style={{
                height: "100%",
                maxHeight: "30vh",
                overflow: "scroll",
                marginBottom: "2rem",
              }}
            >
              <ReactQuill
                // className="textAreamodal"
                // className="form-control my-3"
                theme="snow"
                onChange={this.props.onLearningChange}
                defaultValue={this.props.defaultLearning}
                // value={this.props.learning}
                className="react-quill"
                placeholder="Enter Learning . . ."
                // style={{ height: "100%", zIndex: "100" }}
              />{" "}
            </div>
            <div
              style={{
                height: "100%",
                maxHeight: "30vh",
                overflow: "scroll",
                marginBottom: "2rem",
              }}
            >
              <ReactQuill
                // className="textAreamodal"
                // className="form-control my-3"

                theme="snow"
                placeholder="Enter Commitment . . ."
                onChange={this.props.onCommitmentChange}
                defaultValue={this.props.defaultCommitment}
                // value={this.props.commitment}
                className="react-quill"
                // style={{ height: "100%", color: "black" }}
              />
            </div>
            <button
              onClick={this.props.saveText}
              className="log__monthlymodalButton"
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
