import React, { Component } from 'react';
import Header from '../Functional-component/Header/Header';
import { searchAPI, getMuppCategories, getTasksByType, selectTaskForToday, unselectTaskTodayAPI, deleteTask, getTasksByProject, completeTask, postTask, moveTask, getProject, getAllMupps } from '../../constants/api-config';

import './Search.css';
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calendar from '../../assests/calendar.png'
import { THIS_WEEK, AWAITING, LATER } from '../../constants/app-defaults'
import DeleteTask from "../shared/deleteTask";
import EditTask from "../shared/editTask";
import MoveTask from "../shared/moveTask";
import CompleteTask from "../shared/completeTask";
import Movefolder from '../../assests/moveFolder.png'
import Taskmodal from '../Functional-component/task-modal/Taskmodal';



export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getTaskData: [],
            noData: false,
            text: "",
            //Edit vars
            editTaskName: "",
            editTaskType: "",
            editTaskDate: "",
            editTaskVisibleDate: "",
            editTaskID: "",
            editCompletedStatus: "",
            //move vars

            moveTaskName: "",
            moveTaskDate: "",
            moveTaskId: "",
            moveTaskType: "",
            moveMuppCategoryId: "",
            moveMuppPath: "",
            moveProjectId: "",
            moveProjectName: "",
            moveMuppID: "",
            //complete
            completeCreateTaskName: "",
            completeCreateTaskType: "",
            completeCreateVisibleDate: "",
            completeCreateTaskDueDate: "",
            completeCreateProjectId: "",
            completeCreateMuppId: "",
            completeCreateTaskId: "",

            //modal visible
            isModalVisibile: ""
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    searchApiCall = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "string": this.state.text
            })
        }
        fetch(searchAPI, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("Search Result", result)
                    if (result.success === "True") {
                        this.setState({ getTaskData: result.data, noData: false })
                        console.log("Search Data", this.state.getTaskData);
                    }
                    else {
                        this.setState({ getTaskData: [], noData: true })
                    }
                },
                (error) => {
                    console.log("error in Search api, file name - Search.js", error);
                })
    }


    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    getTaskColor = item => {
        let color = "";
        if (item.task_type === "THIS_WEEK") {
            color = THIS_WEEK;
            return color;
        } else if (item.task_type === "AWAITING") {
            color = AWAITING;
            return color;
        } else if (item.task_type === "LATER") {
            color = LATER;
            return color;
        } else return color;
    };


    deleteTaskApicall = (id) => {
        let deleteTaskURL = deleteTask + "?" + "task_id=" + id;

        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(deleteTaskURL, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res3", result)
                    if (result.success === 'true') {
                        this.notifySuccess(result.message)
                        this.searchApiCall()
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - waiting.js", error);
                })
    }

    openEditModal = async (task) => {
        await this.setState({ editTaskName: task.task_name, editTaskType: task.task_type, editTaskDate: task.date, editTaskVisibleDate: task.date, editTaskID: task.task_id, editCompletedStatus: task.completed })
        console.log(task)
        document.getElementById('rightSlidePanel').style.display = 'block';
        document.getElementById('editTaskNameTextArea').value = task.task_name;
        document.getElementById('editTaskDateTextArea').value = task.date;
    }
    closePanelForEdit = async () => {
        await this.setState({ editTaskName: "", editTaskType: "", editTaskDate: "", editTaskVisibleDate: "", editTaskID: "" }, () => {
            if (document.getElementById('editTaskNameTextArea')) {
                document.getElementById('editTaskNameTextArea').value = "";
                document.getElementById('rightSlidePanel').style.display = 'none';
            }
        })
    }
    handleEditTaskName = (e) => {
        this.setState({ editTaskName: e.target.value })
    }

    handleEditTaskType = (e) => {
        this.setState({ editTaskType: e.target.value })
    }

    handleEditDate = (e) => {
        this.setState({ editTaskDate: e.target.value, editTaskVisibleDate: e.target.value })
    }

    clearEditDate = () => {
        this.setState({ editTaskDate: "", editTaskVisibleDate: null });
    }
    updateTask = (e) => {
        e.preventDefault();
        let token = localStorage.getItem('token');
        var data = {
            task_id: this.state.editTaskID,
            task_name: this.state.editTaskName,
            date: this.state.editTaskVisibleDate ? this.state.editTaskDate : null,
            task_type: this.state.editTaskType,
            completed: this.state.editCompletedStatus
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify(data)
        }
        fetch(getTasksByProject, requestOptions)
            .then(response => response.json())
            .then(async (response) => {
                console.log("response after updating ", response.data)
                await this.closePanelForEdit()
                if (response.success === 'true') {
                    this.notifySuccess(response.message)
                    this.searchApiCall()
                }
                // document.getElementById('rightSlidePanel').style.display = 'none';
                document.getElementById("editTaskNameTextArea").value = "";
            })
            .catch(err => {
                //this.notifyWarning("Something Went Wrong");
            });
    }

    selectTaskTodayAPI = (id) => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "task_id": id
            })
        }
        fetch(selectTaskForToday, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.searchApiCall()

                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    unselectTaskTodayAPI = (id) => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "task_id": id
            })
        }
        fetch(unselectTaskTodayAPI, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.searchApiCall()

                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })

    }

    selectTask = (item) => {
        if (item.selected) {
            this.unselectTaskTodayAPI(item.task_id)
            console.log("unselect")
        }
        else {
            this.selectTaskTodayAPI(item.task_id)
        }
    }

    moveTask = async (item) => {
        console.log("move task click", item)


        await this.setState({
            moveTaskName: item.task_name,
            moveTaskDate: item.date,
            moveTaskId: item.task_id,
            moveTaskType: item.task_type,
            moveMuppCategoryId: item.mupp_category_id,
            moveMuppPath: item.mupp_path,
            moveProjectId: item.project_id,
            moveProjectName: item.project_name,
            moveMuppID: item.mupp_id,

        });
        //localStorage.setItem("task", JSON.stringify(task));
        document.getElementById('moveTaskRightSlidePanel').style.display = 'block';
        document.getElementById('moveTaskCloseIcon').style.display = 'block';

    }

    closePanel = async () => {
        console.log("closed")
        document.getElementById('moveTaskRightSlidePanel').style.display = 'none';
        document.getElementById('moveTaskCloseIcon').style.display = 'none';
        await this.setState({
            moveTaskName: "",
            moveTaskDate: "",
            moveTaskId: "",
            moveTaskType: "",
            moveMuppCategoryId: "",
            moveMuppPath: "",
            moveProjectId: "",
            moveProjectName: "",
            moveMuppID: "",
        });
    }

    onCloseCompleteModal = () => {
        this.setState({ isModalVisibile: false })
    }

    taskSelect = (item) => {
        this.setState({ isModalVisibile: true, completeCreateTaskName: item.task_name, completeCreateTaskType: item.task_type, completeCreateVisibleDate: "", completeCreateTaskDueDate: item.date, completeCreateProjectId: item.project_id, completeCreateMuppId: item.mupp_id, completeCreateTaskId: item.task_id })

    }

    handleNow = () => {
        this.openCompleteNavR()
        this.onCloseCompleteModal()
    }

    handleLater = () => {
        let id = this.state.completeCreateTaskId
        this.completeTaskAPICall(id)
        this.onCloseCompleteModal()
    }
    openCompleteNavR = () => {
        document.getElementById('completeRightSlidePanel').style.display = 'block';
        document.getElementById('completeCloseIcon').style.display = 'block';
    }

    onCloseCompleteNavR = () => {
        console.log("hitt")
        document.getElementById('completeRightSlidePanel').style.display = 'none';
        document.getElementById('completeCloseIcon').style.display = 'none';
    }

    handleSubmitTask = () => {
        this.addTaskAPIcall()
        this.completeTaskAPICall(this.state.completeCreateTaskId)
        this.onCloseCompleteNavR()
    }
    handleTaskName = (e) => {
        this.setState({ completeCreateTaskName: e.target.value })
    }
    handleTaskType = (e) => {
        this.setState({ completeCreateTaskType: e.target.value })
    }
    handleTaskDate = (e) => {
        this.setState({ completeCreateTaskDueDate: e.target.value })
    }


    completeTaskAPICall = (id) => {
        console.log("task id in complete task ", id);
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "task_id": id
            })
        }
        fetch(completeTask, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("result", result);

                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.searchApiCall()
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    addTaskAPIcall = () => {
        this.value = localStorage.getItem('token');

        var data = {
            project: this.state.completeCreateProjectId,
            name: this.state.completeCreateTaskName,
            due_date: this.state.completeCreateTaskDueDate === "" ? null : this.state.completeCreateTaskDueDate,
            task_type: this.state.completeCreateTaskType,
            completed: false,
            mupp: this.state.completeCreateMuppId
        }
        fetch(postTask, {
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.value}`,
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                this.notifySuccess("Successfully");
                this.searchApiCall()
            })
            .catch(err => {
                this.notifyWarning("Something Went Wrong");
            });
    }


    render() {
        var { getTaskData } = this.state;
        return (
            <div>
                <Header header="Search" />
                <div class="input-group mt-3 p-2">
                    <input type="text" class="form-control" onChange={e => this.setState({ text: e.target.value })} value={this.state.text} />
                    <div class="input-group-append">
                        <button class="btn btn-primary" style={{ zIndex: "auto", marginTop: "0px" }} onClick={this.searchApiCall}><i class="fa fa-search"></i></button>
                    </div>
                </div>
                <ul className="all-categories-tab">
                    {getTaskData.length > 0 ? getTaskData.map((item, i) => {
                        return (

                            <li style={{ borderStyle: 'solid', borderLeftColor: this.getTaskColor(item), borderWidth: '8px' }} className="all-categories-li this-week-li" key={i}>
                                <div className="col-sm-9 padding-0">
                                    <h4 onClick={this.openEditModal.bind(this, item)} style={styles.textMultiLineLimit} data-tip={item.task_name} data-for="registerTipforTask" >{item.task_name}</h4>

                                    <ReactTooltip id="registerTipforTask" type="info" place="top" effect="solid">
                                    </ReactTooltip>
                                    <div className="dis-flex">
                                        <label data-tip={item.project_title} data-for="registerTipforProject" className="elipse-text">{item.project_name}</label>
                                        <ReactTooltip id="registerTipforProject" type="info" place="top" effect="solid">
                                        </ReactTooltip>
                                        <label data-tip={item.mupp_path} data-for="registerTipforPath" className="elipse-text" >{item.mupp_path}</label>
                                        <ReactTooltip id="registerTipforPath" place="top" type="info" effect="solid">
                                        </ReactTooltip>
                                        <label>{item.task_type}</label>
                                        {
                                            item.date ?
                                                <label><img src={Calendar} height="25px" width="25px" />{item.date}</label>
                                                :
                                                <label><img src={Calendar} height="25px" width="25px" />No Date Available</label>}
                                    </div>
                                </div>
                                <div className="col-sm-3 padding-0">
                                    {item.selected ? <button style={{ backgroundColor: 'green', padding: "12px 35px" }} onClick={this.selectTask.bind(this, item)} >selected</button>
                                        : <button onClick={this.selectTask.bind(this, item)} >select</button>}

                                    <DeleteTask deleteTask={this.deleteTaskApicall.bind(this, item.task_id)} />
                                    {/* <img  src={Movefolder} height="25px" width="25px" style={{ background: "white", cursor: "pointer", marginLeft: "10px" }} /> */}


                                    <img
                                        onClick={this.moveTask.bind(this, item)}
                                        src={Movefolder} height="25px" width="25px"
                                        style={{ background: "white", cursor: "pointer", marginLeft: "20px", marginTop: "-10px" }}
                                    />
                                    <i class="fa fa-check fa-2x"
                                        onClick={this.taskSelect.bind(this, item)}
                                        style={{ background: "white", cursor: "pointer", marginLeft: "20px" }}>
                                    </i>

                                </div>
                                {
                                    this.state.isModalVisibile && <Taskmodal
                                        open={this.state.isModalVisibile}
                                        onCloseModal={this.onCloseCompleteModal}
                                        nowText={this.handleNow}
                                        laterText={this.handleLater}
                                        isvisibile={true}
                                    />
                                }
                            </li>
                        )
                    }) :
                        <div style={{ marginTop: "25%", marginLeft: "47%" }}>
                            <p style={{ fontWeight: "300", fontSize: '24px' }}>No Data </p></div>}
                </ul>
                {<EditTask
                    closePanelForEdit={this.closePanelForEdit}
                    editTaskName={this.state.editTaskName}
                    handleEditTaskName={this.handleEditTaskName}
                    handleTaskType={this.handleEditTaskType}
                    editTaskType={this.state.editTaskType}
                    editTaskDate={this.state.editTaskDate}
                    clearEditDate={this.clearEditDate}
                    handleEditDate={this.handleEditDate}
                    updateTask={this.updateTask}
                />}
                {
                    //     <MoveTask
                    //     taskType={this.state.moveTaskType}
                    //     taskName={this.state.moveTaskName}
                    //     taskDate={this.state.moveTaskDate}
                    //     isLoading={this.state.isLoading}
                    //     taskID={this.state.moveTaskID}
                    //     notifySuccess={this.notifySuccess}
                    //     notifyWarning={this.notifyWarning}
                    //     closePanel={this.closePanel}
                    //     fetchTaskData={this.searchApiCall}
                    // />

                    <MoveTask
                        taskType={this.state.moveTaskType}
                        taskName={this.state.moveTaskName}
                        taskDate={this.state.moveTaskDate}
                        isLoading={this.state.isLoading}
                        taskID={this.state.moveTaskId}
                        notifySuccess={this.notifySuccess}
                        notifyWarning={this.notifyWarning}
                        closePanel={this.closePanel}
                        fetchTaskData={this.searchApiCall}
                        projectID={this.state.moveProjectId}
                        projectName={this.state.moveProjectName}
                        muppID={this.state.moveMuppID}
                        muppPath={this.state.moveMuppPath}
                        moveMuppCategoryId={this.state.moveMuppCategoryId}

                    />

                }
                {<CompleteTask
                    onClose={this.onCloseCompleteNavR}
                    handleTaskType={this.handleTaskType}
                    handleDate={this.handleTaskDate}
                    handleTaskName={this.handleTaskName}
                    handleSubmitTask={this.handleSubmitTask}
                    taskName={this.state.completeCreateTaskName}
                    taskType={this.state.completeCreateTaskType}
                    taskDate={this.state.completeCreateTaskDueDate}
                />}
                <ToastContainer hideProgressBar={false}></ToastContainer>
            </div>
        )
    }
}

let styles = {
    textMultiLineLimit: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}
