export const SidebarData1 = [
    {
        title: "Home",
        value: 'home',
        link: "/Cards",
    },
    {
        title: "Mailbox",
        value: 'mail',

        link: "/mailbox",
    },
    {
        title: "Analytics",
        value: 'analytics',
        link: "/analytics",
    },
    {
        title: "Dashboard",
        link: "/dashboard",
    },
    {
        title: "Friends",
        link: "/friends",
    },
    {
        title: "Images",
        link: "/images",
    },
];