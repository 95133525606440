


import React, { Component } from 'react';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import './taskmodalStyle.css'

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class Taskmodal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <div style={{ marginTop: "20%" }}>
                <Modal open={this.props.open} onClose={this.props.onCloseModal} onOpen={this.props.onOpen}>
                    <p style={{ color: "black", fontWeight: '400', marginTop: '10%', textAlign: 'center' }}>Do you want to populate the next action now or later?</p>
                    <div style={{ display: 'flex', marginTop: "10%" }}>
                        <button onClick={this.props.nowText} className="nowButton"  >Now</button>
                        <button onClick={this.props.laterText} className="laterButton" >Later</button>
                    </div>

                </Modal>
            </div>
        );
    }
}

export default Taskmodal;
