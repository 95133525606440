import React, { Component } from 'react'
import Addtask from '../Functional-component/Add-task/Addtask'
import Header from '../Functional-component/Header/Header'
import ModalComponent from '../Modal/Modal'
import { postDailyHabit, getDailyHabit, updateDailyHabit, deleteDailyHabbit } from '../../constants/api-config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Delete from '../../assests/delete.jpg'
import Edit from '../../assests/Edit.png'
import DirectiveLoader from '../Functional-component/Loader/loader.component'
export default class Dailyhabbit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            text: '',
            habbitData: '',
            habitId: '',
            value: '',
            isUpdate: false,
            isLoading: false
        }
    }

    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        this.getDailyHabitAPI()
    }

    postDailyHabitAPI = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "name": this.state.text
            })
        }
        fetch(postDailyHabit, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.getDailyHabitAPI()
                        this.onClose();
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    getDailyHabitAPI = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(getDailyHabit, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ habbitData: result.habits, isLoading: true })
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    updateDailyHabitAPI = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "habit_id": this.state.habitId,
                "name": this.state.text
            })
        }
        fetch(updateDailyHabit, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.onClose();
                        this.getDailyHabitAPI()
                        this.setState({ value: '' })
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    deleteDailyHabitAPI = (id) => {

        let deleteDailyHabitURL = deleteDailyHabbit + "?" + "habit_id=" + id;


        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(deleteDailyHabitURL, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.onClose();
                        this.getDailyHabitAPI()
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    onClose = () => {
        this.setState({ show: false })
    }

    onOpen = () => {
        this.setState({ show: true })
    }

    Addtask = () => {
        this.onOpen()
        this.setState({ isUpdate: false })
    }

    saveText = () => {
        if (this.state.isUpdate) {
            this.updateDailyHabitAPI()
        }
        else {
            if (this.state.text === "") {
                this.notifyWarning("Field can not be blank")
            }
            else {
                this.postDailyHabitAPI()
            }
        }
    }

    onChangeText = (e) => {
        this.setState({ text: e.target.value })
    }

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    updateHabit = (item) => {
        this.setState({ value: item.habit_name, habitId: item.habit_id, isUpdate: true })
        this.onOpen()
    }

    deleteHabit = (item) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            this.deleteDailyHabitAPI(item.habit_id)
        }
    }

    habbitList = () => {
        if (this.state.habbitData && this.state.habbitData.length > 0) {
            return this.state.habbitData.map(item =>
                <ul className="inbox-tabs">
                    <li className="col-sm-12 inbox-tab-li">
                        <div className="col-sm-10"><h4 className="text-ellipses-h4">{item.habit_name}</h4></div>
                        <div className="col-sm-2 test-align-right">
                            <img onClick={this.deleteHabit.bind(this, item)} src={Delete} height="25px" width="25px" style={{ background: "white", cursor: "pointer", marginRight: "10px" }} />
                            <img onClick={this.updateHabit.bind(this, item)} src={Edit} height="25px" width="25px" style={{ background: "white", cursor: "pointer" }} />
                        </div>
                    </li>
                </ul>
            )
        }
        else {
            return <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontSize: '24px', fontWeight: '400' }}>No Data</p></div>
        }
    }

    render() {
        return (
            <div style={{ position: 'relative', }}>
                <Header header="Daily Habits" />
                <Addtask
                    buttonname="Add"
                    Addtask="Add Task"
                    onClick={this.Addtask}
                />
                {this.state.isLoading ? this.habbitList() : <div style={{ marginLeft: "48%", marginTop: "25%" }}><DirectiveLoader /></div>}

                <ModalComponent open={this.state.show}
                    onCloseModal={this.onClose}
                    onOpen={this.onOpen}
                    saveText={this.saveText}
                    onChange={this.onChangeText}
                    value={this.state.value} />
                <ToastContainer hideProgressBar={false}
                >
                </ToastContainer>
            </div>
        )
    }
}
