import React, { Component } from 'react';
import './Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarData1 } from './data';


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            hidden1: false
        }
        this.home = this.home.bind(this)
    }

    home = (item) => {

        this.setState({ hidden: !this.state.hidden })
    }

    home1 = () => {
        this.setState({ hidden1: !this.state.hidden1 })
    }

    render() {
        return (
            <>
                <div className="homeContainer">
                    <div className="sideBar">
                        <ul className="SidebarList">
                            {SidebarData1.map((val, key) => {
                                return (
                                    <li
                                        key={key}
                                        className="row"
                                        id={window.location.pathname === val.link ? "active" : ""}
                                        onClick={() => {
                                            window.location.pathname = val.link;
                                        }}
                                    >
                                        <div id="icon">{val.icon}</div> <div id="title">{val.title}</div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="content">


                    </div>
                </div>
            </>
        )

    }
}
