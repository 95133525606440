import React, { Component } from 'react'
import './Reset.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgetPassword } from '../../constants/api-config'

export default class Reset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
        this.handleEmail = this.handleEmail.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    // function that handle email

    handleEmail = async function (e) {
        await this.setState({ email: e.target.value })
    }

    // function that handle on Submit

    onSubmit() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: this.state.email,
            })
        }

        //Api call for forgetPassword

        fetch(forgetPassword, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess()
                    }
                },

                (error) => {
                    this.notifyWarning()
                }
            )
    }

    // function that run on notifySuccess

    notifySuccess = () => toast.success("Resend password link is send to your E-mail", {
        hideProgressBar: false,
        autoClose: 3000,

    });

    // function that run on notifyWarning


    notifyWarning = () => toast.warning("something went wrong", {
        hideProgressBar: false,
        autoClose: 1000,

    });


    render() {
        return (
            <div className="parent">
                <div className="child">
                    <h3 id="h6">Reset Password</h3>
                    <input name="Email" placeholder="Email" onChange={this.handleEmail} />
                    <button id="btn1" onClick={this.onSubmit}>Submit</button>
                </div>
                <ToastContainer hideProgressBar={false}
                >
                </ToastContainer>
            </div>
        )
    }
}
