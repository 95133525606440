import React, { Component } from 'react'
import './Page4.css'
import { Link, useHistory } from 'react-router-dom'
import logo from '../../assests/logo.png'

const Page4 = (props) => {
    const history = useHistory();
    const handleClick = () => {
        history.push('/video');
    }
    return (
        <div className="Page4-container">
            <div className="Page4-childContainer">
                <div className="imgContainer">
                    <img src={logo} id="PageImage" />
                    <div id="childContainer1">
                        <Link

                            to="video"
                            className="page4-title"><h2>Paradigm shift</h2></Link><br />
                        <Link to="SelfLanding" className="page4-title"><h2>Self Discovery</h2></Link><br />
                        <Link to="MuppFinalization" className="page4-title"><h2>Mupp Finalization</h2></Link><br />
                        <Link to="picture_success" className="page4-title"><h2>Picture of Success</h2></Link><br />
                        <Link to="work_mupp_picos" className="page4-title"><h2>Work that Matters Most</h2></Link><br />
                        <Link to="project_review" className="page4-title"><h2>Review</h2></Link>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Page4;