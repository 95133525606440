import React, { useEffect, useState } from "react";
import Header from "../Functional-component/Header/Header";
import Addtask from "../Functional-component/Add-task/Addtask";
import ModalComponent from "./AddLogModal";
import axios from "axios";
import { toast } from "react-toastify";
import Delete from "../../assests/delete.jpg";
import Edit from "../../assests/Edit.png";
import { learningLog, learningLogExp } from "../../constants/api-config";
export default function Log() {
  const [showAddModal, setshowAddModal] = useState(false);
  const [editTask, seteditTask] = useState(false);
  const [learningLogdata, setlearningLogdata] = useState({
    success: false,
    response: [],
  });
  const [logID, setlogID] = useState(0);
  const [addTaskValue, setaddTaskValue] = useState("");
  const [commitment, setcommitment] = useState("");
  const [defaultCommitment, setdefaultCommitment] = useState("");
  const [learning, setlearning] = useState("");
  const [defaultLearning, setdefaultLearning] = useState("");
  const [text, setText] = useState("");
  const token = localStorage.getItem("token");
  const onCloseAddModal = () => {
    setshowAddModal(false);
    setaddTaskValue("");
    setdefaultCommitment("");
    setdefaultLearning("");
    setText("");
    setlearning("");
    setcommitment("");
  };
  const onOpenAddModal = () => setshowAddModal(true);

  useEffect(() => {
    learningLogGet();
  }, []);

  const notifyWarning = (text) =>
    toast.warning(text, {
      hideProgressBar: false,
      autoClose: 2000,
    });
  const notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 2000,
    });
  const saveTextHandler = () => {
    console.log("😇");
    if (editTask) {
      // if (text === "") return learningLogUpdate(addTaskValue);
      learningLogUpdate();
    } else {
      console.log(text);
      if (text === "") {
        notifyWarning("Field can not be blank");
      } else {
        learningLogPost();
      }
    }
  };
  const addTaskHandler = () => {
    onOpenAddModal();
    seteditTask(false);
  };
  const learningLogGet = async () => {
    try {
      const {
        data: { Success, response },
      } = await axios.get(learningLog, {
        headers: { Authorization: `JWT ${token}` },
      });
      console.log(Success, response);
      setlearningLogdata({ success: Success, response: response });
    } catch (error) {
      console.error("🔴 LearningLogGet:", error);
    }
  };

  const learningLogUpdate = async () => {
    try {
      const body = {
        id: logID,
        heading: text === "" ? addTaskValue : text,
        learning: learning === "" ? defaultLearning : learning,
        commitment: commitment === "" ? defaultCommitment : commitment,
      };
      const { data } = await axios.put(learningLog, body, {
        headers: { Authorization: `JWT ${token}` },
      });
      console.log(data);
      notifySuccess(data.message);
      onCloseAddModal();
      learningLogGet();
    } catch (error) {
      console.error("🔴 LearningLogUpdate:", error);
    }
  };
  const learningLogPost = async () => {
    try {
      const body = {
        heading: text,
        learning: learning,
        commitment: commitment,
      };
      const { data } = await axios.post(learningLog, body, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      console.log(data);
      notifySuccess(data.message);
      onCloseAddModal();
      if (data.Success) learningLogGet();
    } catch (error) {
      console.error("🔴 LearningLogPost:", error);
    }
  };
  const learningLogDelete = async (item) => {
    if (!window.confirm("Are you sure to delete this task")) {
      return;
    }
    try {
      const body = {
        id: item.id,
        heading: item.heading,
        learning: learning === "" ? defaultLearning : learning,
        commitment: commitment === "" ? defaultCommitment : commitment,
      };
      const { data } = await axios.delete(`${learningLog}?id=${body.id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      console.log(data);
      notifySuccess(data.message);
      if (data.success) learningLogGet();
    } catch (error) {
      console.error("🔴 LearningLogDelete:", error);
    }
  };
  const LogsEdit = async (item) => {
    try {
      const {
        data: { response },
      } = await axios.get(`${learningLogExp}?id=${item.id}`, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      console.log(response);
      setaddTaskValue(response[0].heading);
      setdefaultCommitment(response[0].commitment);
      setdefaultLearning(response[0].learning);
    } catch (error) {
      console.error("🔴 LearningLogListExp:", error);
    }
    setlogID(item.id);
    seteditTask(true);
    onOpenAddModal();
  };
  return (
    <div>
      <Header header="Learning Log" />
      <Addtask buttonname="Add" Addtask="Add Task" onClick={addTaskHandler} />
      {learningLogdata.success ? (
        learningLogdata.response.map((item, i) => (
          <ul className="inbox-tabs" key={i}>
            <li className="col-sm-12 inbox-tab-li">
              <div className="col-sm-10">
                <h4 className="text-ellipses-h4">{item.heading}</h4>
              </div>
              <div className="col-sm-2 test-align-right">
                <img
                  // onClick={this.deleteTask.bind(this, item.inbox_id)}
                  onClick={() => learningLogDelete(item)}
                  src={Delete}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  height="25px"
                  width="25px"
                  alt=""
                />
                <img
                  // onClick={this.editInbox.bind(this, item)}
                  onClick={() => LogsEdit(item)}
                  src={Edit}
                  height="25px"
                  width="25px"
                  style={{ background: "white", cursor: "pointer" }}
                  alt=""
                />
              </div>
            </li>
          </ul>
        ))
      ) : (
        <div className="text-center">No Data</div>
      )}
      <ModalComponent
        open={showAddModal}
        onCloseModal={onCloseAddModal}
        onOpen={onOpenAddModal}
        onChange={(e) => setText(e.target.value)}
        value={addTaskValue}
        onCommitmentChange={setcommitment}
        commitment={commitment}
        saveText={saveTextHandler}
        defaultCommitment={defaultCommitment}
        onLearningChange={(e) => {
          console.log({ learningChange: e });
          setlearning(e);
        }}
        learning={learning}
        defaultLearning={defaultLearning}
      />
    </div>
  );
}
