import React from "react";
import { useHistory } from "react-router-dom";
import MessageGIF from "../../assests/404.gif";
export default function PageNotFound() {
  const history = useHistory();
  return (
    <div style={styles.mainContainer}>
      <img
        src={MessageGIF}
        alt=""
        style={styles.image}
        onClick={() => history.push("/")}
      />
    </div>
  );
}

const styles = {
  mainContainer: {
    height: "100vh",
  },
  image: {
    height: "100%",
    objectFit: "cover",
    maxWidth: "100%",
  },
};
