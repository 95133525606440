import { React, Component } from 'react';
import Loader from 'react-loader-spinner'

export default class DirectiveLoader extends Component {
    render() {
        return (
            <div>
                <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={50}
                    width={50}
                    timeout={1000000} //3 secs
                />
            </div>
        )
    }
}