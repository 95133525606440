import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Header from '../../Functional-component/Header/Header'
class Continue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionName :localStorage.getItem('sessionName')
        }
        this.handleScience = this.handleScience.bind(this)
        this.continue = this.continue.bind(this)
    }

    handleScience = () => {
        this.props.history.push('TestLink')
    }

    handleArt = () => {
        this.props.history.push('Art')
    }

    continue = () => {
        this.props.history.push("/Selfpage1")
    }

    render() {
        return (
            <div className="col-sm-12 padding-0">
                <Header header={this.state.sessionName} />
            <div className="self-discovery-div" style={{ marginTop: "15%" }}>
                <p onClick={this.handleScience}>Science</p>
                <p onClick={this.handleArt}>Art</p>
                <button className="continueButton" onClick={this.continue}>Continue</button>
            </div>
            </div>
        )
    }
}
export default withRouter(Continue)