import React, { Component } from 'react'
import './Verifyemail.css'
import { resendVerificationMail } from '../../constants/api-config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Verifyemail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        }
        this.handleEmail = this.handleEmail.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

    }


    handleEmail = async function (e) {
        await this.setState({ email: e.target.value })
        console.log("hi", this.state.email)
    }

    onSubmit() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: this.state.email

            })
        }
        fetch(resendVerificationMail, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res", result)
                    if (result.success === 'true') {
                        this.notifySuccess(result.message)
                    }
                    else {
                        this.notifyWarning(result.message)
                    }
                },
                (error) => {
                    console.log("error", error)
                }
            )


    }
    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });


    render() {
        return (
            <div className="parent">
                <div className="child">
                    <h3 id="h6">Verify Email</h3>
                    <input name="Email" placeholder="Email" onChange={this.handleEmail} />
                    <button id="btn1" onClick={this.onSubmit}>Submit</button>
                </div>
                <ToastContainer hideProgressBar={false}></ToastContainer>

            </div>
        )
    }
}
