import React, { Component } from 'react'
import './testlink.css'
import ContentEditable from 'react-contenteditable'
import { getTestLink, postTestResult } from '../../../constants/api-config'
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Header from '../../Functional-component/Header/Header';

class Testlink extends Component {
    constructor(props) {
        super(props);
        this.contentEditable = React.createRef();
        this.state = {
            userToken: "",
            serviceId: "",
            textareaContent1: "",
            textareaContent2: "",
            tempVar: "",
            response1: "",
            response2: "",
            testLinkId: null,
            testResultId: null,
            link: '',
            discoverySessionId: '',
            noTest: false,
            sessionName :localStorage.getItem('sessionName')
        }
    }

    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        this.getTestLink()
    }

    getTestLink = async () => {
        let discovery_session_id = localStorage.getItem('discoverySessionId')
        console.log("DISCOVERY", discovery_session_id)
        let value = localStorage.getItem('token')
        await this.setState({ userToken: value, discoverySessionId: discovery_session_id });
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${value}`,
            },
            body: JSON.stringify({
                discovery_session_id: this.state.discoverySessionId
            })
        }
        fetch(getTestLink, requestOptions)
            .then(response => response.json())
            .then((result) => {
                let link = result.test_link
                console.log("LINK", link);
                this.setState({ link: link, testLinkId: result.test_link_id, noTest: false  })
                if (result.test_result_id) {
                    this.setState({ response1: result.response_1, testResultId: result.test_result_id, response2: result.response_2, noTest: false  })
                    this.setState({ textareaContent1: result.response_1, textareaContent2: result.response_2, noTest: false })
                }
                else {
                    this.setState({ noTest: true })

                }
            })

    }

    go = () => {
        document.execCommand("foreColor", false, "#FF0000");
        if (this.state.textareaContent2.length > 0) {
            let data = `${this.state.textareaContent2} 
            <br/>
            ${window.getSelection().toString()}`
            console.log(data);
            this.setState({ textareaContent2: data })
        } else {
            this.setState({ textareaContent2: window.getSelection().toString() });
        }
    }

    submit = () => {
        if (this.state.response1 === this.state.textareaContent1 && this.state.response2 === this.state.textareaContent2) {
            this.props.history.push('/science')
        }
        else if (this.state.response1 !== this.state.textareaContent1 || this.state.response2 !== this.state.textareaContent2) {
            this.postTestResultAPICall()
        }
    }

    postTestResultAPICall = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}`,
            },
            body: JSON.stringify({
                test: this.state.testLinkId,
                test_result_id: this.state.testResultId,
                response_1: this.state.textareaContent1,
                response_2: this.state.textareaContent2
            })
        }
        fetch(postTestResult, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.success === "true") {
                    this.setState({ textareaContent1: '', textareaContent2: '' })
                    this.notifySuccess(result.message)
                }
                else {
                    console.log("Else")
                    this.notifyWarning("Field may not be blank")
                }
            })
    }

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => {
            this.props.history.push({
                pathname: '/science',
                state: {
                    completed: false
                }
            })
        }
    });

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    clickgo = () => {
        window.open(this.state.link, '_blank')
    }

    render() {
        return (
            <div className="test-container col-sm-12 padding-0">
                <Header header={this.state.sessionName} />
                <div className="test-header">
                    <h6 style={{ marginTop: '20px' }}>Test Link<a rel={'external'} target="_blank" href={`${this.state.link}`}> {this.state.link}</a></h6>
                </div>
                {this.state.link ?

                    <div className="testChild">
                        <header className="test-link-div1">
                            <div>
                                <button className="highlightBtn" onClick={this.go}>Highlight</button>
                            </div>
                            <ContentEditable
                                className="textEditor content-editor-one"
                                innerRef={this.contentEditable}
                                html={this.state.textareaContent1}
                                style={{ height: "100%", color: 'black', overflow: "auto" }}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textareaContent1: e.target.value })
                                }}
                            />
                        </header>
                        <br></br>
                        <header className="test-link-div2">
                            <ContentEditable
                                style={{ height: "100%", color: 'black', overflow: "auto" }}
                                className="textEditor content-editor-two"
                                html={this.state.textareaContent2}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textareaContent2: e.target.value })
                                }}
                            />
                        </header>
                        <button id="btn" onClick={this.submit}>save</button>
                    </div>:
                    
                    <div className="test-header">
                    <h6 style={{ marginTop: '50px', textAlign: 'center' }}>No Test found for this Discovery Session</h6>
                    <br></br>
                    <button onClick={() => {
                        this.props.history.push('/science')

                    }}>Go Back</button>
                </div>
                }
                <ToastContainer hideProgressBar={false} ></ToastContainer>
            </div>
        )
    }
}

export default withRouter(Testlink)