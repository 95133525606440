import React, { Component } from 'react'
import ContentEditable from 'react-contenteditable'
import { postMissionStatement, updateMissionStatement, getAllSummary, getMissionStatement } from '../../constants/api-config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assests/logo.png';
import { htmlToText } from 'html-to-text';
import { withRouter } from 'react-router-dom';
import './missionStyle.css'
class Missionstatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textContent1: '',
            textContent2: '',
            textContent3: '',
            userToken: '',
            missionStatementId: '',
            resData: '',
            data: '',
            story: '',
            prevResponse1: '',
            prevResponse2: '',
            prevResponse3: '',
            isupdate: false

        }
        this.Submit = this.Submit.bind(this)
    }

    // call API for getting all summaries

    async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let service_id = localStorage.getItem('id')
        let value = localStorage.getItem('token')
        await this.setState({ userToken: value, missionStatementId: service_id });

        let getAllSummaryURL = getAllSummary + "?" + "service_id=" + service_id;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${value}`,
            },
        }
        fetch(getAllSummaryURL, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result)
                if (result.length > 0) {
                    console.log("length hai")
                    let responseData = result

                    let questionHtml = ""
                    responseData.map(item => {
                        questionHtml = questionHtml + `<div>
                        <h5>${item.name}</h5>
                        <p>${htmlToText(item.summary, {
                            wordwrap: 130,
                        })}</p >
                    </div > `})
                    this.setState({ textContent1: questionHtml, resData: result })
                }
                else {
                    console.log("nahi hai")
                }








            })

        let getMissionStatementURL = getMissionStatement + "?" + "service_id=" + service_id;
        await fetch(getMissionStatementURL, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log("getres", result)
                let isData = Object.keys(result).length === 0
                console.log('isData', isData)
                if (isData) {
                    console.log("1", isData)
                }
                else {
                    console.log("2", isData)
                    let id = result.id;
                    let longSummaries = result.long_summaries;
                    let statement = result.statement;
                    let summariesBrief = result.summaries_brief
                    this.setState({ prevResponse1: longSummaries, prevResponse2: summariesBrief, prevResponse3: statement, isupdate: true })
                    this.setState({ textContent1: longSummaries, textContent2: summariesBrief, textContent3: statement, missionStatementId: id })
                }

            })

    }

    // function that post mission statement

    postMissionStatmentApiCall = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}`,
            },
            body: JSON.stringify({
                "service": this.state.missionStatementId,
                "statement": this.state.textContent3,
                "long_summaries": this.state.textContent1,
                "summaries_brief": this.state.textContent2
            })
        }

        fetch(postMissionStatement, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.success === "true") {

                    this.notifySuccess(result.message)
                    localStorage.setItem('left', this.state.textContent1)
                    localStorage.setItem('right', this.state.textContent3)
                }
                else if (result.success === "false") {
                    this.updateMissionStatementApi()
                }
            })

    }

    //function that update mission statement

    updateMissionStatementApi = () => {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}`,
            },
            body: JSON.stringify({
                "id": this.state.missionStatementId,
                "statement": this.state.textContent3,
                "long_summaries": this.state.textContent1,
                "summaries_brief": this.state.textContent2
            })
        }

        fetch(updateMissionStatement, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.notifySuccess(result.message)
                localStorage.setItem('left', this.state.textContent1)
                localStorage.setItem('right', this.state.textContent3)
            })
    }

    // function that call on submit

    Submit() {
        const { textContent1, textContent2, textContent3, prevResponse1, prevResponse2, prevResponse3, isupdate } = this.state
        console.log("pre1", prevResponse1)
        console.log("pre2", prevResponse2)
        console.log("pre3", prevResponse3)
        if (prevResponse1 === textContent1 && prevResponse2 === textContent2 && prevResponse3 === textContent3) {
            this.props.history.push('/addmupp')
            localStorage.setItem('left', this.state.textContent1)
            localStorage.setItem('right', this.state.textContent3)

        }
        else if (isupdate) {
            this.updateMissionStatementApi()

        }
        else {
            this.postMissionStatmentApiCall()

        }

    }

    //function that hightlight editor text

    highLight = () => {
        document.execCommand("foreColor", false, "#FF0000");
        document.execCommand("bold", false);

        if (this.state.textContent2.length > 0) {
            let data = `${this.state.textContent2}
            <br/>
            ${window.getSelection().toString()}`
            this.setState({ textContent2: data })
        } else {
            this.setState({ textContent2: window.getSelection().toString() });
        }
    }

    // notify bar for failed result

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    //notiify bar for success result

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 5000,
        onClose: () => {
            this.props.history.push({
                pathname: '/addmupp',
                state: {
                    left: this.state.textContent1,
                    right: this.state.textContent3
                }
            })
        }
    });

    render() {
        return (
            <div className="col-sm-12 self-container">
                <div className="col-sm-12 self-header">
                    <h4 className="self-header-h2">Design Your Mission Statement</h4>
                    <img src={logo} className="missionStatementImg" />
                </div>
                <div className="col-sm-12">
                    <button className="highlightBtn" onClick={this.highLight} style={{marginLeft:"20px"}}>Highlight</button>
                </div>

                <div className="col-sm-12 missionContainer">
                    <div className="col-sm-6 padding-0 ">
                        <div style={{ height: "100%" }}>
                            <ContentEditable
                                className="textEditor"
                                html={this.state.textContent1}
                                disabled={false}
                                style={{ height: "100%", color: 'black', overflowY: "auto",maxHeight: "60vh" }}
                                onChange={(e) => {
                                    this.setState({ textContent1: e.target.value })
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-sm-6 padding-0">
                        <ContentEditable
                            placeholder={"Please write your mission statement"}
                            className="textEditor"
                            html={this.state.textContent2}
                            disabled={false}
                            style={{ height:"29vh",maxHeight:"30vh",overflowY: "auto",}}
                            onChange={(e) => {
                                this.setState({ textContent2: e.target.value })
                            }}
                        />
                        <div style={{marginTop:"12px"}}>
                            <ContentEditable
                                className="textEditor"
                                placeholder={"Summarize your mission statement"}
                                html={this.state.textContent3}
                                style={{ height:"29vh",maxHeight: "30vh", overflowY: "auto" }}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textContent3: e.target.value })
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 self-footer-next-button">
                    <button onClick={this.Submit}>save</button>
                </div>
                <ToastContainer hideProgressBar={false}
                >
                </ToastContainer>

            </div>

        )
    }
}

export default withRouter(Missionstatement)