import React, { useState, useEffect } from "react";
import {
  getAllMupps,
  moveProject,
  getMuppCategories,
} from "../../constants/api-config";

const MoveProjects = (props) => {
  const [muppCategoryId, setMuppCategoryId] = useState();
  const [categoryArr, setCategoryArr] = useState([]);
  const [allMupp, setAllMupp] = useState();
  const [muppList, setMuppList] = useState([]);
  const [muppItem, setMuppItem] = useState();
  const [projectList, setProjectList] = useState([]);
  const [project_Id, setProject_Id] = useState();
  const [minDate, setMinDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const getAllMuppApiCall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getAllMupps, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (Object.keys(result).length > 0 && props.moveProjectMuppCategory) {
            setAllMupp(result);
            const muppData = result.filter(
              (item) => item.category_id === props.moveProjectMuppCategory
            );

            if (
              muppData[0].mupps === undefined ||
              muppData[0].mupps.length === 0
            ) {
              setMuppList([]);
            } else {
              let muppArray = muppData[0].mupps.map((item) => {
                return { value: item.id, label: item.path };
              });
              setMuppList(muppArray);
            }
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };
  const getMuppCategoryApiCall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getMuppCategories, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("getMuppCategories result ", result);
          if (result) {
            if (
              Object.keys(result.category).length === 0 &&
              result.category.constructor === Object
            ) {
              setCategoryArr([]);
            } else {
              let categoryArray = result.category.map((item) => {
                return { value: item.category_id, name: item.name };
              });
              console.log(" categoryArray ", categoryArray);

              setCategoryArr(categoryArray);
            }
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  useEffect(() => {
    const setPropsToState = async () => {
      setProject_Id(props.moveProjectId);
      setMuppCategoryId(props.moveProjectMuppCategory);
      setMuppItem(props.moveProjectMuppId);
    };
    console.log(
      "props changed props.moveProjectMuppCategory",
      props.moveProjectMuppCategory
    );
    setPropsToState().then(() => {
      getMuppCategoryApiCall();
      getAllMuppApiCall();
    });
  }, [props]);

  const muppDropdownData = (categoryId) => {
    if (categoryId && categoryId > 0) {
      const muppData = allMupp.filter(
        (item) => item.category_id === parseInt(categoryId)
      );
      if (muppData[0].mupps === undefined || muppData[0].mupps.length === 0) {
        setMuppList([]);
      } else {
        let muppArray = muppData[0].mupps.map((item) => {
          return { value: item.id, label: item.path };
        });
        setMuppList(muppArray);
        setMuppItem(muppArray[0].value);
      }
    }
  };

  const moveProjectApiCall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        project_id: project_Id,
        mupp_id: muppItem,
      }),
    };
    fetch(moveProject, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.Success === "true") {
            props.notifySuccess(result.message);
            props.closePanel();
            props.fetchTaskData();
          } else {
            props.notifyWarning(result.message);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  const closeAndReset = () => {
    props.closePanel();
  };

  return (
    <div>
      <i
        className="fa fa-close close-icon"
        id="moveProjectCloseIcon"
        style={{ display: "none" }}
        onClick={closeAndReset}
        aria-hidden="true"
      ></i>
      <ul className="render-task">
        <div
          className="task-accordion"
          id="moveProjectRightSlidePanel"
          style={{ display: "none" }}
        >
          <h4>Move Project</h4>

          <div className="form-group">
            <p>Select MUPP cateogry</p>
            <select
              name="task-type"
              onChange={(e) => {
                // console.log("Onchange called", e.target.value);
                setMuppCategoryId(e.target.value);
                muppDropdownData(e.target.value);
              }}
              className="form-control"
              defaultValue={muppCategoryId}
            >
              {categoryArr.map((item) => {
                if (muppCategoryId === item.value) {
                  return (
                    <option selected key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  );
                }

                return <option value={item.value}>{item.name}</option>;
              })}
            </select>
          </div>
          <div className="form-group">
            <p>Select Mupp</p>
            <select
              onChange={(e) => {
                // console.log("e.target on mupp select", e.target.value);
                setMuppItem(e.target.value);
              }}
              name="task-type"
              className="form-control"
              defaultValue={muppItem}
            >
              {muppList.map((item) => {
                if (item.value === muppItem) {
                  return (
                    <option selected key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                }
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-12 add-mupps-button">
            <button onClick={moveProjectApiCall}>Save</button>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default MoveProjects;
