import React, { Component } from 'react';
import './landing.css';
import Itemdata from '../item-data/itemdata';
import { getDiscoverySession, allSessionCompleted } from '../../../constants/api-config';
import { withRouter } from 'react-router-dom';


class Selflanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: '',
            continue: '',
            counter: 0,
            completed: false
        }
        //this.name = this.name.bind(this)
    }

    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let service_id = localStorage.getItem('id')
        let value = localStorage.getItem('token')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${value}` },
            body: JSON.stringify({
                service_id: service_id,
            })
        }
        fetch(getDiscoverySession, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res", result)
                    this.setState({ sessions: result })
                    console.log("sess", this.state.sessions.length)
                    this.state.sessions.map(item => {
                       return console.log(item.name)
                    })
                },
                (error) => {
                    console.log("error", error)
                }
            )

        const requestOptionsForAllSession = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${value}` },

        }

        let allSessionCompletedApi = allSessionCompleted + "?service_id=" + service_id;

        fetch(allSessionCompletedApi, requestOptionsForAllSession)
            .then(res => res.json())
            .then(
                (result) => {
                    let resData = result.completed
                    this.setState({ completed: resData })
                    localStorage.setItem('completed', this.state.completed)
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    increment = () => {
        this.setState({ counter: this.state.counter + 1 })
    }

    name = () => {
        if (this.state.sessions && this.state.sessions.length !== 0) {
            return this.state.sessions.map(item =>
                <Itemdata name={item.name} onsubmit={this.go.bind(this, item)} />
            )
        }

    }

    go(project) {

        let discovery_session_id = project.discovery_session_id;
        localStorage.setItem('sessionName', project.name)
        localStorage.setItem('discoverySessionId', discovery_session_id)
        console.log("DISCOVERY SESSION ID",discovery_session_id)
        console.log("SESSION NAME",project.name)


        this.props.history.push({
            pathname: '/science',
            state: {
                completed: false,
            }
        })
    }

    render() {
        return (
            <div className="selfContainer">
                <div className="selfchildContainer">
                    {this.name()}
                    <button className="contineBtn" hidden={!this.state.completed} onClick={() => {
                        this.props.history.push({
                            pathname: '/missionStatement',
                        })
                    }}>Continue</button>
                </div>
            </div>
        )
    }
}

export default withRouter(Selflanding);