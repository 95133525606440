import React, { Component } from "react";
import Card from "./CardUi";
import "../Home/Card-style.css";
import { getActiveServices, CDN } from "../../constants/api-config";
import DirectiveLoader from "../Functional-component/Loader/loader.component";

export default class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isLoading: false,
      payServiceData: "",
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let value = localStorage.getItem("token");
    if (localStorage.getItem("id") || localStorage.getItem("picosData")) {
      localStorage.removeItem("id");
      localStorage.removeItem("picosData");
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${value}`,
      },
    };
    fetch(getActiveServices, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log("res", result);
        let resultArray = result.services;
        let data = resultArray.filter((item) => !item.hidden);
        this.setState({ value: data, isLoading: true });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  projectCards = () => {
    if (this.state.value && this.state.value.length !== 0) {
      return this.state.value.map((project, i) => (
        <div className="col-sm-4 card-style" key={i}>
          <Card
            imgsrc={`${CDN}${project.image_url}`}
            title={project.name}
            description={project.description}
            price={project.price}
            serviceId={project.service_id}
            priceId={project.price_id}
            subscribed={project.subscribed}
          />
        </div>
      ));
    } else {
      return (
        <div className="col-sm-12 no-cards-available">
          <p>No Cards Available</p>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="col-sm-12 cards-container">
        <div className="col-sm-12 right-cards">
          {this.state.isLoading ? (
            this.projectCards()
          ) : (
            <div className="CardsLoader">
              <DirectiveLoader />
            </div>
          )}
        </div>
      </div>
    );
  }
}
