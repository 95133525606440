/**
 * table of mupp picos project
 */
import React, { Component } from 'react'
import './mupp-picos-project.css';
import { Link } from 'react-router-dom'
import { getMuppPicosProjects } from '../../constants/api-config';
import Header from '../Functional-component/Header/Header'

export default class WorkMuppPicos extends Component {
    constructor(props) {
        super(props);
        this.value = localStorage.getItem('token');
        this.id = localStorage.getItem('id');
        this.state = {
            results: []
        }
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        fetch(getMuppPicosProjects + "/?service=" + this.id, {
            "method": "GET",
            "headers": {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.value}`,
            },
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                this.setState({
                    results: response
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="work-mupp-picos">
                <Header header="Work that Matters Most" />
                <div className="col-sm-12 table-responsive" style={{ marginLeft: "10px", paddingLeft: "3px",overflowX:'hidden'}}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Mupp</th>
                                <th>Picos</th>
                                <th>Projects</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                if (this.state.results && this.state.results.length > 0) {
                                    return (
                                        this.state.results.map((data, i) => (
                                            <tr key={i}>
                                                <td>
                                                <div style={{ minHeight: '15vh',overflowX:'hidden',width:"400px",maxHeight: "25vh" ,overflowY:'auto'}}>
                                                    Path - {data.mupp_path}
                                                    <br />
                                                    Description - {data.mupp_description}
                                                </div>
                                                </td>

                                                {data.picos_heading && data.picos_description ? <td>
                                                    <div style={{ minHeight: '15vh', maxHeight: "25vh" ,width:"400px",overflowX:'hidden',overflowY:'auto'}}>
                                                    Path - {data.picos_heading}
                                                    <br />
                                                    Description - {data.picos_description}
                                                    </div>
                                                </td> :
                                                    <td>
                                                        <h6>No PICOS found</h6>
                                                    </td>}
                                                <td style={{ paddingTop: "2em" }}>
                                                    <Link to={{
                                                        pathname: "mupp_picos_project",
                                                        search: 'muppId=' + data.mupp_id,
                                                        state: {
                                                            data: data
                                                        }
                                                    }} className="picos-button">Project</Link>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                } else {
                                    return (
                                        <tr>
                                            <td colSpan="3" className="no-result-found">No Results Found</td>
                                        </tr>
                                    )
                                }
                            })()}
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-12" style={{ textAlign: "right" }}>
                    <button  onClick={() => {this.props.history.push('/page4')}}>Back</button>
                </div>
            </div>
        )
    }
}
