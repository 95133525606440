import React, { Component } from "react";
import "./homemupps.css";

import {
  selectTaskForToday,
  unselectTaskTodayAPI,
  getTasksByProject,
  completeTask,
  getAllMupps,
  project,
  mupp,
  postTask,
  deleteTask,
} from "../../constants/api-config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from "../../assests/delete.jpg";
import CompleteTask from "../shared/completeTask";
import Movefolder from "../../assests/moveFolder.png";
import Taskmodal from "../Functional-component/task-modal/Taskmodal";
import MoveTask from "../shared/moveTask";
import MoveProject from "../shared/moveProjects";
import ReactTooltip from "react-tooltip";
import { THIS_WEEK, AWAITING, LATER } from "../../constants/app-defaults";
import DeleteTask from "../shared/deleteTask";
import ModalComponent from "./homeMuppsProjectModal";

export default class HomeMupps extends Component {
  constructor(props) {
    super(props);
    this.value = localStorage.getItem("token");
    this.state = {
      categories: [],
      muppsResult: [],
      allTasks: [],
      completedTask: [],
      muppPath: [],
      allProject: [],
      isClicked: false,
      isEdit: false,
      isAddMupps: false,
      isDelete: false,
      isDeleteProject: false,
      isProjectClicked: false,
      categoryId: "",
      path: "",
      description: "",
      rough_text: "",
      mupp_serviceId: "",
      mupp_category_id: "",
      rank_for_mupps: "",
      select_for_ratings: "",
      muppID: "",
      projectName: "",
      muppId: "",
      taskName: "",
      taskType: "THIS_WEEK",
      taskDate: "",
      editTaskName: "",
      editTaskType: [],
      isAddTaskClicked: true,
      projectID: "",
      taskDueDate: null,
      visibleDate: null,
      projectTitle: "",
      isProjectAvailable: false,
      isDataAvailable: false,
      muppsProjectIdForDelete: "",
      projectIdForDelete: "",
      muppPathId: "",
      muppDescription: "",
      editTaskDate: "",
      editTaskVisibleDate: null,
      taskID: "",
      currentMupp: "",
      isAddProjectClicked: true,
      projectId: "",
      isEditProjectClicked: true,
      chosenMuppCategory: { id: 0, name: "" },

      selectedprojectName: "",
      muppPathName: "",
      color: "",
      ischeck: false,
      isModalVisibile: false,
      isPicosModalVisible: false,
      isLoading: false,
      //moveStates
      moveTaskName: "",
      moveTaskDate: "",
      moveTaskId: "",
      moveTaskType: "",
      moveMuppCategoryId: "",

      //moveProjects
      moveProjectMuppCategory: "",
      moveProjectId: "",
      moveProjectMuppId: "",

      //createTAsk
      createTaskName: "",
      createTaskType: "",
      createVisibleDate: "",
      createTaskDueDate: "",
      //createTAsk
      completeCreateTaskName: "",
      completeCreateTaskType: "",
      completeCreateVisibleDate: "",
      completeCreateTaskDueDate: "",
      completeCreateProjectId: "",
      completeCreateMuppId: "",
      completeCreateTaskId: "",

      // this.setState({ isModalVisibile: true, projectId: item.proj_id, taskName: item.task_name, taskID: item.task_id })
    };
  }

  notifyWarning = (text) =>
    toast.warning(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  componentDidMount = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    await fetch(getAllMupps, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categories: response,
          isDelete: false,
          isDeleteProject: false,
          isAddMupps: false,
          isEdit: false,
          isProjectClicked: false,
        });
      })
      .catch((err) => {
        console.log(err);
        //this.notifyWarning("Something Went Wrong");
      });
  };
  toggleAllModals = () => {
    this.setState({
      isAddTaskClicked: false,
      isProjectClicked: false,
      isAddMupps: false,
      isEdit: false,
      isDelete: false,
      isDeleteProject: false,
      isAddProjectClicked: false,
      isEditProjectClicked: false,
    });
  };

  _onPicosModalClose = () => {
    this.setState({
      isPicosModalVisible: false,
    });
  };
  _onPicosModalOpen = () => {
    this.setState({ isPicosModalVisible: true });
  };

  deleteTaskApicall = (id) => {
    let deleteTaskURL = deleteTask + "?" + "task_id=" + id;

    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(deleteTaskURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("res3", result);
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.showTasksApiCall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  getProjectData = async (mupp) => {
    this.toggleAllModals();
    await this.setState({
      muppId: mupp.id,
      currentMupp: mupp,
      muppPathName: mupp.path,
    });
    this.getProjectDataApiCall();
  };

  getProjectDataApiCall = () => {
    fetch(project + "?mupp=" + this.state.muppId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let isData = Object.keys(response).length > 0;
        if (isData) {
          this.setState({
            isClicked: true,
            allProject: response,
            isDataAvailable: true,
          });
          console.log("hit", mupp);
          console.log("projects are", response);
        } else {
          this.setState({
            isClicked: true,
            allProject: [],
            isDataAvailable: false,
          });
        }
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  showTasksApiCall = async () => {
    let id = this.state.projectID;
    fetch(getTasksByProject + "?project_id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.tasks !== undefined) {
          let compeletedTaskArray = response.tasks.filter(function (item) {
            return item.completed === true;
          });
          let taskArray = response.tasks.filter(function (item) {
            return item.completed === false;
          });

          this.closePanelForEdit();

          this.setState({
            allTasks: taskArray,
            completedTask: compeletedTaskArray,
            isDeleteProject: false,
            isAddTaskClicked: false,
            isAddProjectClicked: false,
            isEditProjectClicked: false,
            isDelete: false,
            isAddMupps: false,
            isEdit: false,
            isProjectClicked: true,
          });
          console.log("tasks are ", taskArray);
        } else {
          this.setState({
            allTasks: [],
            completedTask: [],
            isDelete: false,
            isDeleteProject: false,
            isAddMupps: false,
            isEdit: false,
            isProjectClicked: true,
            isAddTaskClicked: false,
            isAddProjectClicked: false,
            isEditProjectClicked: false,
          });
        }

        document.getElementById("projectCompletedTaskTab").style.display =
          "none";
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  // Edit Mupps
  showEditMuppsModal = (item, muppCategoryId) => {
    console.log(item);
    this.setState({
      muppPath: item.path,
      isEdit: true,
      isAddMupps: false,
      isDelete: false,
      isDeleteProject: false,
      isProjectClicked: false,
      muppPathId: item.id,
      muppDescription: item.description,
      rough_text: item.rough_text,
      mupp_serviceId: item.service,
      mupp_category_id: muppCategoryId,
      rank_for_mupps: item.rank_for_mupps,
      select_for_ratings: item.select_for_ratings,
    });
  };

  updateMupps = (e) => {
    console.log("mupp", this.state.muppPath);
    this.updateMuppsAPICall(e);
  };

  // description: "desc"
  // id: 2
  // path: "Path to official test"
  // rank_for_mupps: 1
  // rough_text: "rough text"
  // select_for_pos: true
  // select_for_ratings: true
  // service: 1

  updateMuppsAPICall = (e) => {
    e.preventDefault();
    const payload = {
      mupp_id: this.state.muppPathId,
      path: this.state.muppPath,
      description: this.state.muppDescription,
      rough_text: this.state.rough_text,
      service: this.state.mupp_serviceId,
      category_id: this.state.mupp_category_id,
      rank_for_mupps: this.state.rank_for_mupps,
      select_for_ratings: this.state.select_for_ratings,
    };

    fetch(mupp, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        this.componentDidMount();
        this.notifySuccess("Update Mupps Successfully");
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };
  // End

  // Add Mupps
  showAddMuppsModal = async (item) => {
    console.log("enter in show", item);

    await this.setState({
      categoryId: item.category_id,
      isAddMupps: true,
      isEdit: false,
      isDelete: false,
      isDeleteProject: false,
      isProjectClicked: false,
    });
  };

  addMupps = (e) => {
    e.preventDefault();
    var data = {
      path: this.state.path,
      description: this.state.description,
      rough_text: this.state.rough_text,
      service: "1",
      category: this.state.categoryId,
    };

    fetch(mupp, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ rough_text: "", path: "", description: "" });
        document.getElementById("textAreaMuppPath").value = "";
        document.getElementById("textAreaMuppDesc").value = "";
        document.getElementById("textAreaRoughText").value = "";
        this.componentDidMount();
        this.notifySuccess("Added Mupps Successfully");
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };
  // End

  // Delete Mupps
  showDeleteMuppsModal = (e, id) => {
    this.setState({
      isDelete: true,
      isDeleteProject: false,
      isAddMupps: false,
      isEdit: false,
      isProjectClicked: false,
      muppsProjectIdForDelete: id,
    });
  };

  showDeleteProjectModal = (id) => {
    console.log("SHOW DELETE PROJECT MODAL");
    this.setState({
      isDelete: false,
      isDeleteProject: true,
      isAddMupps: false,
      isEdit: false,
      isProjectClicked: false,
      projectIdForDelete: id,
    });
  };

  deleteMupps = (e) => {
    e.preventDefault();
    fetch(mupp + "/?mupp_id=" + this.state.muppsProjectIdForDelete, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        this.componentDidMount();
        this.notifySuccess("Delete Mupps Successfully");
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };
  // End

  addFormRender = () => {
    return (
      <div className="col-sm-12 form-div">
        <form className="col-sm-12" onSubmit={this.addMupps}>
          <i
            className="fa fa-close close-icon"
            style={{ color: "white", left: "100%" }}
            onClick={() =>
              this.setState({
                isProjectClicked: false,
                isAddMupps: false,
                isEdit: false,
                isDelete: false,
                isDeleteProject: false,
              })
            }
          ></i>
          <div className="col-sm-12 form-group">
            <div className="col-sm-4 inline-text">
              <h4>Path</h4>
            </div>
            <div className="col-sm-8 inline-text">
              <textarea
                id="textAreaMuppPath"
                onChange={(data) => {
                  this.setState({ path: data.target.value });
                }}
                type="text"
                value={this.state.path}
                name="path"
                className="form-control"
                required
                style={{ height: "15vh", width: "60vh" }}
              />
            </div>
          </div>
          <div className="col-sm-12 form-group">
            <div className="col-sm-4 inline-text">
              <h4>Rough Text</h4>
            </div>
            <div className="col-sm-8 inline-text">
              <textarea
                id="textAreaRoughText"
                onChange={(data) => {
                  this.setState({ rough_text: data.target.value });
                }}
                type="text"
                value={this.state.rough_text}
                name="rough_text"
                className="form-control"
                required
                style={{ height: "15vh", width: "60vh" }}
              />
            </div>
          </div>
          <div className="col-sm-12 form-group">
            <div className="col-sm-4 inline-text">
              <h4>Description</h4>
            </div>
            <div className="col-sm-8 inline-text">
              <textarea
                id="textAreaMuppDesc"
                onChange={(data) => {
                  this.setState({ description: data.target.value });
                }}
                type="text"
                value={this.state.description}
                name="description"
                className="form-control"
                required
                style={{ height: "15vh", width: "60vh" }}
              />
            </div>
          </div>
          <div className="col-sm-12 add-mupps-button">
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    );
  };

  editFormRender = () => {
    return (
      <div className="col-sm-12 home-mupps-form-div">
        <div className="col-sm-8 form-group">
          <label>Edit Mupp</label>
          <br />
          <label style={{ fontSize: "15px", margin: 0 }}>Path:</label>
          <textarea
            value={this.state.muppPath}
            onChange={(e) => this.setState({ muppPath: e.target.value })}
            type="text"
            name="description"
            className="form-control"
            required
            cols="15"
            rows="4"
            style={{
              margin: "auto",
              display: "block",
              width: "100%",
              maxWidth: "100%",
            }}
          />
          <label style={{ fontSize: "15px", margin: 0 }}>Rough text:</label>
          <textarea
            value={this.state.muppDescription}
            onChange={(e) => this.setState({ muppDescription: e.target.value })}
            type="text"
            name="description"
            className="form-control"
            required
            cols="15"
            rows="4"
            style={{
              margin: "auto",
              display: "block",
              width: "100%",
              maxWidth: "100%",
            }}
          />
          <label style={{ fontSize: "15px", margin: 0 }}>Description:</label>
          <textarea
            value={this.state.rough_text}
            onChange={(e) => this.setState({ rough_text: e.target.value })}
            type="text"
            name="description"
            className="form-control"
            required
            cols="15"
            rows="4"
            style={{
              margin: "auto",
              display: "block",
              width: "100%",
              maxWidth: "100%",
            }}
          />

          {/* <input type="text" className="form-control" value={this.state.muppPath} onChange={(e) => this.setState({ muppPath: e.target.value })}></input> */}
          <button type="submit" onClick={this.updateMupps}>
            Update
          </button>
        </div>
      </div>
    );
  };

  deleteFormRender = () => {
    return (
      <div className="col-sm-12 home-mupps-form-div">
        <div className="col-sm-6 form-group">
          <label>Are You Sure you want Delete</label>
          <button type="submit" onClick={this.deleteMupps}>
            Yes
          </button>
        </div>
      </div>
    );
  };

  deleteProjectFormRender = () => {
    return (
      <div className="col-sm-12 home-mupps-form-div" id="deleteProjectModal">
        <div className="col-sm-6 form-group">
          <label>Are you sure you want to delete this Project?</label>
          <button type="submit" onClick={this.deleteProjectApiCall}>
            Yes
          </button>
          <button type="submit" onClick={this.closeDeleteProjectModal}>
            No
          </button>
        </div>
      </div>
    );
  };

  openNavR = async (task) => {
    await this.setState({
      editTaskName: task.task_name,
      editTaskType: task.task_type,
      editTaskDate: task.date,
      editTaskVisibleDate: task.date,
      taskID: task.task_id,
    });
    console.log(task);
    document.getElementById("rightSlidePanel").style.display = "block";
    document.getElementById("editTaskNameTextArea").value = task.task_name;
    document.getElementById("editTaskDateTextArea").value = task.date;
  };

  closePanelForEdit = async () => {
    await this.setState(
      { editTaskName: "", editTaskDate: "", editTaskVisibleDate: "" },
      () => {
        if (document.getElementById("editTaskNameTextArea")) {
          document.getElementById("editTaskNameTextArea").value = "";
          console.log("value is ");
          document.getElementById("rightSlidePanel").style.display = "none";
        }
      }
    );
  };

  closePanel = () => {
    this.closePanelForTask();
  };

  closePanelForTask = () => {
    document.getElementById("rightSlidePanel1").style.display = "none";
  };

  updateTask = (e) => {
    e.preventDefault();
    var data = {
      task_id: this.state.taskID,
      task_name: this.state.editTaskName,
      date: this.state.editTaskVisibleDate ? this.state.editTaskDate : null,
      task_type: this.state.editTaskType,
      completed: false,
    };
    fetch(getTasksByProject, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (response) => {
        // document.getElementById('rightSlidePanel').style.display = 'none';
        console.log("response after updating ", response.data);
        // this.closePanel();
        await this.closePanelForEdit();
        if (response.success === "true") {
          this.notifySuccess(response.message);
          this.showTasksApiCall();
        }

        // this.notifySuccess("Update Task Successfully")
        // document.getElementById('rightSlidePanel').style.display = 'none';
        document.getElementById("editTaskNameTextArea").value = "";
      })
      .catch((err) => {
        this.notifyWarning(err);
      });
  };

  closeAddTask = () => {
    document.getElementById("rightSlidePanel1").style.display = "none";
    this.setState({
      createTaskName: "",
      createTaskType: "",
      createTaskDueDate: "",
      createVisibleDate: "",
    });
  };

  clearDate = () => {
    this.setState({ createTaskDueDate: null, createVisibleDate: "" });
  };

  renderAddTask = () => {
    return (
      <div>
        <div
          className="task-accordion"
          id="rightSlidePanel1"
          style={{ display: "none" }}
        >
          <i
            className="fa fa-close close-icon"
            style={{ color: "black" }}
            onClick={() => this.closeAddTask()}
          ></i>

          <h5>{this.state.selectedprojectName}</h5>
          <h6>ADD Task</h6>
          <div className="form-group">
            <textarea
              type="text"
              placeholder="Add Task"
              id="addTaskTextArea"
              name="Title"
              className="form-control"
              onChange={(e) => {
                this.setState({ createTaskName: e.target.value });
              }}
              required
              style={{ height: "30vh" }}
            />
          </div>
          <div className="form-group">
            <select
              onChange={(e) => {
                this.setState({ createTaskType: e.target.value });
              }}
              name="task-type"
              className="form-control"
              value={this.state.createTaskType}
            >
              <option value="">Select Task type</option>
              <option value="THIS_WEEK">THIS_WEEK</option>
              <option value="LATER">LATER</option>
              <option value="AWAITING">AWAITING</option>
            </select>
          </div>

          <div className="form-group">
            <input
              min={new Date().toISOString().split("T")[0]}
              type="date"
              name="date"
              className="form-control"
              onChange={(e) => {
                this.setState({
                  createTaskDueDate: e.target.value,
                  createVisibleDate: e.target.value,
                });
              }}
              required
              value={this.state.createVisibleDate}
            />
          </div>
          <div className="col-sm-12 add-mupps-button">
            <button type="button" onClick={this.clearDate}>
              Reset Date
            </button>
            <button type="submit" onClick={this.postTaskAPICall}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  postTaskAPICall = () => {
    let token = localStorage.getItem("token");
    console.log("this.state.createTaskDueDate", this.state.createTaskDueDate);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },

      body: JSON.stringify({
        name: this.state.createTaskName,
        project: this.state.projectID,
        task_type: this.state.createTaskType,
        completed: false,
        mupp: this.state.muppId,
        due_date:
          this.state.createTaskDueDate === ""
            ? null
            : this.state.createTaskDueDate,
      }),
    };
    fetch(postTask, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("Adding Task result", result);
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.showTasksApiCall();
            this.closePanel();
            document.getElementById("addTaskTextArea").value = "";
            this.setState({ createVisibleDate: "" });
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
  };

  showTasks = async (item, index, length) => {
    console.log("length is ", item);
    for (let i = 0; i < length; i++) {
      document.getElementById(`${i}`).classList.remove("active-background");
      if (this.state.allProject.projects[i].important) {
        document.getElementById(`${i}`).classList.add("project-imp");
      } else {
        document.getElementById(`${i}`).classList.add("project-header");
      }
      if (i == index) {
        document.getElementById(`${i}`).classList.remove("project-imp");
        document.getElementById(`${i}`).classList.add("active-background");
      }
    }

    await this.setState({
      projectID: item.id,
      selectedprojectName: item.title,
    });
    this.showTasksApiCall();
  };

  addTAsk = async () => {
    await this.setState({
      isAddTaskClicked: true,
      isProjectClicked: true,
      isAddMupps: false,
      isEdit: false,
      isDelete: false,
      isDeleteProject: false,
      isAddProjectClicked: false,
      isEditProjectClicked: false,
    });
    document.getElementById("rightSlidePanel1").style.display = "block";
  };

  deleteTask = (id) => {
    this.deleteTaskApicall(id);
  };

  handleEditTaskName = (e) => {
    this.setState({ editTaskName: e.target.value });
  };

  handleEditDate = (e) => {
    this.setState({
      editTaskDate: e.target.value,
      editTaskVisibleDate: e.target.value,
    });
  };

  handleEditTaskType = (e) => {
    this.setState({ editTaskType: e.target.value });
  };

  handleTaskType = (e) => {
    this.setState({ completeCreateTaskType: e.target.value });
  };
  clearEditDate = () => {
    this.setState({ editTaskDate: "", editTaskVisibleDate: null });
  };
  handleTabClicked = (type) => {
    if (type === "completed") {
      document.getElementById("projectCompletedTaskTab").style.display =
        "block";
      document.getElementById("projectTaskTab").style.display = "none";
      // active-button
      //compeletedTaskBtn

      document
        .getElementById("compeletedTaskBtn")
        .classList.add("active-button");

      document.getElementById("taskBtn").classList.remove("active-button");
    } else {
      document.getElementById("projectCompletedTaskTab").style.display = "none";
      document.getElementById("projectTaskTab").style.display = "block";
      // active-button
      //taskBtn
      document
        .getElementById("compeletedTaskBtn")
        .classList.remove("active-button");

      document.getElementById("taskBtn").classList.add("active-button");
    }
  };

  getTaskColor = (item) => {
    let color = "";
    if (item.task_type === "THIS_WEEK") {
      color = THIS_WEEK;
      return color;
    } else if (item.task_type === "AWAITING") {
      color = AWAITING;
      return color;
    } else if (item.task_type === "LATER") {
      color = LATER;
      return color;
    } else return color;
  };

  doneComplete = (item, i) => {
    this.state.allTasks.forEach((e) => {
      if (e.task_id === item.task_id) {
        console.log("id same h");
        this.state.allTasks[i].completed = !e.completed;
      }
    });
    console.log(this.state.allTasks[i]);
  };

  selectTask = (item) => {
    if (item.selected) {
      this.unselectTaskTodayAPI(item.task_id);
      console.log("unselect");
    } else {
      this.selectTaskTodayAPI(item.task_id);
    }
  };

  selectTaskTodayAPI = (id) => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(selectTaskForToday, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.showTasksApiCall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  unselectTaskTodayAPI = (id) => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(unselectTaskTodayAPI, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.showTasksApiCall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };
  //TODO:MoveTask
  moveTask = async (item) => {
    console.log("item before move is ", item);

    await this.setState({
      muppPath: item.mupp_path,
      moveMuppCategoryId: item.mupp_category_id,
      moveTaskId: item.task_id,
      moveTaskName: item.task_name,
      moveTaskType: item.task_type,
      moveTaskDate: item.date,
      projectId: item.project_id,
      projectName: item.project_name,
      muppID: item.mupp_id,
    });
    document.getElementById("moveTaskRightSlidePanel").style.display = "block";
    document.getElementById("moveTaskCloseIcon").style.display = "block";
  };

  closePanel = () => {
    console.log("closed");
    document.getElementById("moveTaskRightSlidePanel").style.display = "none";
    document.getElementById("moveTaskCloseIcon").style.display = "none";
    this.setState({ moveTaskName: "", moveTaskType: "", moveTaskDate: "" });
  };

  moveProject = async (muppId, muppCategoryId, res) => {
    console.log("moveProject after click ", muppId, muppCategoryId, res);
    await this.setState({
      moveProjectMuppCategory: muppCategoryId,
      moveProjectId: res.id,
      moveProjectMuppId: muppId,
    });
    document.getElementById("moveProjectRightSlidePanel").style.display =
      "block";
    document.getElementById("moveProjectCloseIcon").style.display = "block";
  };
  closeMoveProject = () => {
    document.getElementById("moveProjectRightSlidePanel").style.display =
      "none";
    document.getElementById("moveProjectCloseIcon").style.display = "none";
  };

  taskSelect = (item) => {
    console.log(item);

    this.setState({
      isModalVisibile: true,
      completeCreateTaskName: item.task_name,
      completeCreateTaskType: item.task_type,
      completeCreateVisibleDate: "",
      completeCreateTaskDueDate: item.date,
      completeCreateProjectId: item.project_id,
      completeCreateMuppId: item.mupp_id,
      completeCreateTaskId: item.task_id,
    });
  };

  onCloseCompleteModal = () => {
    this.setState({ isModalVisibile: false });
  };

  handleNow = () => {
    this.openCompleteNavR();
    this.onCloseCompleteModal();
  };

  openCompleteNavR = () => {
    document.getElementById("completeRightSlidePanel").style.display = "block";
    document.getElementById("completeCloseIcon").style.display = "block";
  };

  onCloseCompleteNavR = () => {
    console.log("hitt");
    document.getElementById("completeRightSlidePanel").style.display = "none";
    document.getElementById("completeCloseIcon").style.display = "none";
  };

  handleLater = () => {
    let id = this.state.completeCreateTaskId;
    console.log("completeCreateTaskId");
    this.completeTaskAPICall(id);
    this.onCloseCompleteModal();
  };

  handleTaskName = (e) => {
    this.setState({ completeCreateTaskName: e.target.value });
  };

  handleSubmitTask = () => {
    this.addTaskAPIcall();
    this.completeTaskAPICall(this.state.completeCreateTaskId);
    this.onCloseCompleteNavR();
  };

  addTaskAPIcall = () => {
    this.value = localStorage.getItem("token");

    console.log(
      "this.state.completeCreateTaskDueDate",
      this.state.completeCreateTaskDueDate
    );

    var data = {
      project: this.state.completeCreateProjectId,
      name: this.state.completeCreateTaskName,
      due_date:
        this.state.completeCreateTaskDueDate === ""
          ? null
          : this.state.completeCreateTaskDueDate,
      task_type: this.state.completeCreateTaskType,
      completed: false,
      mupp: this.state.completeCreateMuppId,
    };
    fetch(postTask, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.notifySuccess("Successfully");
      })
      .catch((err) => {
        this.notifyWarning("Something Went Wrong");
      });
  };
  completeTaskAPICall = (id) => {
    console.log("task id in complete task ", id);
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(completeTask, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result", result);

          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.showTasksApiCall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  handleDate = (e) => {
    this.setState({ completeCreateTaskDueDate: e.target.value });
  };

  renderTask = () => {
    this.deleteTask = this.deleteTask.bind(this);
    return (
      <div>
        <button
          id="taskBtn"
          onClick={() => this.handleTabClicked("Task")}
          style={{ marginLeft: "2%", marginTop: "2%" }}
        >
          Tasks
        </button>
        <button
          id="compeletedTaskBtn"
          onClick={() => this.handleTabClicked("completed")}
          style={{ marginLeft: "2%", marginTop: "2%" }}
        >
          Completed Tasks
        </button>
        <div id="projectTaskTab">
          <button
            onClick={this.addTAsk}
            style={{ marginLeft: "2%", marginTop: "2%" }}
          >
            Add
          </button>
          <ul className="render-task">
            {this.state.allTasks.length > 0 ? (
              this.state.allTasks.map((tasks, index) => (
                <li
                  style={{
                    background: "white",
                    borderStyle: "solid",
                    borderLeftColor: this.getTaskColor(tasks),
                    borderWidth: "8px",
                  }}
                  className="all-categories-li this-week-li"
                  key={index}
                >
                  <div className="col-sm-8 padding-0">
                    <h4
                      style={styles.textMultiLineLimit}
                      onClick={this.openNavR.bind(this, tasks)}
                      data-tip={tasks.task_name}
                      data-for="registerTipforTask"
                    >
                      {tasks.task_name}
                    </h4>
                    <ReactTooltip
                      id="registerTipforTask"
                      type="info"
                      place="top"
                      effect="solid"
                    ></ReactTooltip>
                    <div>
                      <label style={styles.elipseTextMupps}>
                        {this.state.selectedprojectName}
                      </label>
                      <label style={styles.elipseTextMupps}>
                        {this.state.muppPathName}
                      </label>
                      <label style={styles.elipseTextMupps}>
                        {tasks.task_type}
                      </label>
                      <label style={styles.elipseTextMupps}>
                        <i
                          className="fa fa-calendar calendar-icon"
                          aria-hidden="true"
                        ></i>
                        {tasks.date ? tasks.date : "No Date"}
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-4 padding-0">
                    {tasks.selected ? (
                      <button
                        style={{
                          backgroundColor: "green",
                          padding: "12px 35px",
                        }}
                        onClick={this.selectTask.bind(this, tasks)}
                      >
                        selected
                      </button>
                    ) : (
                      <button onClick={this.selectTask.bind(this, tasks)}>
                        select
                      </button>
                    )}

                    <DeleteTask
                      deleteTask={this.deleteTask.bind(this, tasks.task_id)}
                    />
                    {/* TODO:MoveTaskMethodcall */}
                    <img
                      onClick={this.moveTask.bind(this, tasks)}
                      src={Movefolder}
                      height="25px"
                      width="25px"
                      style={{
                        background: "white",
                        cursor: "pointer",
                        marginLeft: "20px",
                        marginTop: "-10px",
                      }}
                    />

                    <i
                      class="fa fa-check fa-2x"
                      onClick={this.taskSelect.bind(this, tasks)}
                      style={{
                        background: "white",
                        cursor: "pointer",
                        marginLeft: "20px",
                      }}
                    ></i>
                  </div>
                  {this.state.isModalVisibile && (
                    <Taskmodal
                      open={this.state.isModalVisibile}
                      onCloseModal={this.onCloseCompleteModal}
                      nowText={this.handleNow}
                      laterText={this.handleLater}
                      isvisibile={true}
                    />
                  )}
                </li>
              ))
            ) : (
              <li>No Result Found</li>
            )}
            <div
              className="task-accordion"
              id="rightSlidePanel"
              style={{ display: "none" }}
            >
              <i
                className="fa fa-close close-icon"
                style={{ color: "black" }}
                onClick={() => this.closePanelForEdit()}
              ></i>

              <h4>Edit Task</h4>
              <div className="form-group">
                <textarea
                  onChange={this.handleEditTaskName}
                  type="text"
                  defaultValue={this.state.editTaskName}
                  name="Title"
                  className="form-control"
                  required
                  id="editTaskNameTextArea"
                  style={{ height: "30vh" }}
                />
              </div>
              <div className="form-group">
                <select
                  value={this.state.editTaskType}
                  onChange={this.handleEditTaskType}
                  name="task-type"
                  className="form-control"
                >
                  <option value="THIS_WEEK">THIS_WEEK</option>
                  <option value="LATER">LATER</option>
                  <option value="AWAITING">AWAITING</option>
                </select>
              </div>

              <div className="form-group">
                <input
                  onChange={this.handleEditDate}
                  defaultValue={this.state.editTaskDate}
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  name="date"
                  className="form-control"
                  required
                  value={this.state.editTaskDate}
                  id="editTaskDateTextArea"
                />
              </div>
              <div className="col-sm-12 add-mupps-button">
                <button type="button" onClick={this.clearEditDate}>
                  Reset Date
                </button>
                <button type="submit" onClick={this.updateTask}>
                  Save
                </button>
              </div>
            </div>
          </ul>
        </div>
        <div id="projectCompletedTaskTab">
          <ul className="render-task">
            {this.state.completedTask.length > 0 ? (
              this.state.completedTask.map((tasks, index) => (
                <li
                  style={{
                    borderStyle: "solid",
                    borderLeftColor: this.getTaskColor(tasks),
                    borderWidth: "8px",
                  }}
                  key={index}
                >
                  <h4
                    onClick={this.openNavR.bind(this, tasks)}
                    data-tip={tasks.task_name}
                    data-for="registerTipforTask"
                    className="elipse-text-for-title"
                  >
                    {tasks.task_name}
                  </h4>
                  <ReactTooltip
                    id="registerTipforTask"
                    type="info"
                    place="right"
                    effect="solid"
                  ></ReactTooltip>
                  <div style={{ position: "relative" }}>
                    <label className="elipse-text-mupps">
                      {this.state.selectedprojectName}
                    </label>
                    <label>{tasks.task_type}</label>
                    <label>
                      <i
                        className="fa fa-calendar calendar-icon"
                        aria-hidden="true"
                      ></i>
                      {tasks.date}
                    </label>
                    <img
                      onClick={this.deleteTask.bind(this, tasks)}
                      style={{ position: "absolute", left: "90%", top: "-50%" }}
                      src={Delete}
                      height="25px"
                      width="25px"
                    />
                  </div>
                </li>
              ))
            ) : (
              <li>No Result Found</li>
            )}
            <div
              className="task-accordion"
              id="rightSlidePanel"
              style={{ display: "none" }}
            >
              <i
                className="fa fa-close close-icon"
                style={{ color: "black" }}
                onClick={() => this.closePanel()}
              ></i>

              <h4>Edit Task</h4>
              <div className="form-group">
                <textarea
                  onChange={this.handleEditTaskName}
                  type="text"
                  defaultValue={this.state.editTaskName}
                  name="Title"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <select
                  value={this.state.editTaskType}
                  onChange={this.handleTaskType}
                  name="task-type"
                  className="form-control"
                >
                  <option value="THIS_WEEK">THIS_WEEK</option>
                  <option value="LATER">LATER</option>
                  <option value="AWAITING">AWAITING</option>
                </select>
              </div>

              <div className="form-group">
                <input
                  onChange={this.handleEditDate}
                  defaultValue={this.state.editTaskDate}
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  name="date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-12 add-mupps-button">
                <button type="submit" onClick={this.updateTask}>
                  Save
                </button>
              </div>
            </div>
          </ul>
        </div>
      </div>
    );
  };

  handleAddPicos = (mupps) => {
    this.props.history.push({
      pathname: "/picos",
      state: {
        muppId: mupps.id,
      },
    });
  };

  closee = () => {
    console.log("hit");
    document.getElementById("addProjectModal").style.display = "block";
  };

  renderAddProject = () => {
    return (
      <div>
        <div
          className="task-accordion"
          id="addProjectModal"
          style={{ display: "none" }}
        >
          <i
            className="fa fa-close close-icon"
            id="closeIconn"
            onClick={() => this.closeAddProjectModal()}
            aria-hidden="true"
          ></i>

          <h4>ADD Project</h4>
          <div className="form-group">
            <textarea
              id="textAreaAddProject"
              type="text"
              style={{ height: "30vh" }}
              placeholder="Project Title"
              name="Title"
              className="form-control"
              onChange={(e) => {
                this.setState({ projectTitle: e.target.value });
              }}
              required
            />
          </div>
          <div className="col-sm-12 add-mupps-button">
            <button type="submit" onClick={() => this.postProjectApiCall()}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  addProject = async (id) => {
    await this.setState({
      isAddProjectClicked: true,
      isEditProjectClicked: false,
      projectId: id,
      isDeleteProject: false,
    });
    console.log("addProjectModal", document.getElementById("addProjectModal"));
    document.getElementById("addProjectModal").style.display = "block";
  };

  closeAddProjectModal = () => {
    document.getElementById("addProjectModal").style.display = "none";
  };

  postProjectApiCall = () => {
    fetch(project, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify({
        mupp: this.state.projectId,
        title: this.state.projectTitle,
        important: false,
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response.success === "true") {
          document.getElementById("textAreaAddProject").value = "";
          this.closeAddProjectModal();
          console.log("response", response);

          this.notifySuccess("Project Added Successfully");
          await this.componentDidMount();
          this.getProjectDataApiCall();
        } else {
          this.notifyWarning(response.title[0]);
        }
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  deleteProjectApiCall = (e) => {
    e.preventDefault();
    fetch(project + "/?project_id=" + this.state.projectIdForDelete, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === "true") {
          this.notifySuccess("Project Deleted Successfully");
          this.closeDeleteProjectModal();
          this.getProjectDataApiCall();
          this.componentDidMount();
        }
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  closeDeleteProjectModal = () => {
    document.getElementById("deleteProjectModal").style.display = "none";
    this.componentDidMount();
  };

  // notifyDelete = (id) => toast.error(<div>
  //     Are you sure you want to delete this item

  //     <button Click={() => this.deleteProjectApiCall(id)}>Yes</button>
  // </div>, {
  //     hideProgressBar: false,
  //     autoClose: false
  // });

  // notifyDeleteTask = (id) => toast.error(<div>
  //     Are you sure you want to delete this item

  //     <button
  //         onClick={() => this.deleteTaskApicall(id)
  //         }>Yes</button>
  // </div>, {
  //     hideProgressBar: false,
  //     autoClose: false
  // });

  renderEditProject = () => {
    return (
      <div>
        <div
          className="task-accordion"
          id="editProjectModal"
          style={{ display: "none" }}
        >
          <i
            className="fa fa-close close-icon"
            id="EditCloseIcon"
            onClick={() => this.closeEditProjectModal()}
            aria-hidden="true"
          ></i>
          <h4>Edit Project</h4>

          <div className="form-group">
            <textarea
              id="textAreaAddProject"
              type="text"
              style={{ height: "30vh" }}
              placeholder="Project Title"
              name="Title"
              className="form-control"
              value={this.state.projectName}
              onChange={(e) => {
                this.setState({ projectName: e.target.value });
              }}
              required
            />
          </div>
          <div className="col-sm-12 add-mupps-button">
            <button
              type="submit"
              onClick={() => this.updateProjectApiCall(this.state.projectId)}
            >
              Update
            </button>
          </div>

          {/* <div className="form-group">
                        <textarea type="text"
                            placeholder="Project Title"
                            name="Title"
                            className="form-control"
                            style={{height:"20vh"}}
                            value={this.state.projectName}
                            onChange={(e) => { this.setState({ projectName: e.target.value }) }}
                            required />
                    </div>
                    <div className="col-sm-12 add-mupps-button">
                        <button type="submit" onClick={()=>this.updateProjectApiCall(this.state.projectId)} >Update</button>
                    </div> */}
        </div>
      </div>
    );
  };

  editProject = async (project) => {
    console.log("editProject", project);

    await this.setState({
      isEditProjectClicked: true,
      isAddProjectClicked: false,
      projectId: project.id,
      projectName: project.title,
    });
    document.getElementById("editProjectModal").style.display = "block";
  };
  closeEditProjectModal = () => {
    document.getElementById("editProjectModal").style.display = "none";
    document.getElementById("EditCloseIcon").style.display = "none";
    console.log("editclose");
    this.setState({ isEditProjectClicked: false });
  };
  updateProjectApiCall = (id) => {
    fetch(project, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify({
        project_id: id,
        title: this.state.projectName,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.closeEditProjectModal();
        console.log("response", response);
        this.notifySuccess("Project Updated Successfully");
        this.componentDidMount();
        this.getProjectDataApiCall();
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  render() {
    return (
      <div className="col-sm-12 inline-css padding-0">
        {/* Categories */}
        <div className="col-sm-4 mupps-accordion padding-0">
          <div id="accordion" className="accordion">
            {this.state.isClicked && (
              <div className="back-button">
                <i
                  className="fa fa-arrow-circle-left"
                  onClick={() => {
                    this.toggleAllModals();
                    this.setState({ isClicked: false });
                  }}
                  aria-hidden="true"
                ></i>
              </div>
            )}
            {(() => {
              if (
                this.state.categories &&
                this.state.categories.length > 0 &&
                !this.state.isClicked
              ) {
                return this.state.categories.map((data, i) => (
                  <div className="card mb-0 project-renderer" key={i}>
                    <div className="card-header">
                      <a
                        className="card-title collapsed"
                        onClick={() => {
                          this.toggleAllModals();
                          this.setState({
                            chosenMuppCategory: {
                              id: data.category_id,
                              name: data.category,
                            },
                          });
                        }}
                        data-toggle="collapse"
                        href={"#collapseOne" + i}
                      >
                        {data.category}
                        {console.log("☺️", data, this.state.chosenMuppCategory)}
                      </a>
                      <i
                        className="fa fa-plus plus-icon"
                        onClick={this.showAddMuppsModal.bind(this, data)}
                      ></i>
                    </div>

                    <div
                      style={{
                        background: "rgb(25,25,36)",
                        maxHeight: "90vh",
                        overflow: "auto",
                      }}
                      id={"collapseOne" + i}
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <ul style={{ listStyleType: "none", paddingLeft: "1px" }}>
                        {data.mupps.map((mupps, index) => (
                          <li
                            style={{ borderBottom: "1px solid white" }}
                            key={index}
                          >
                            <div
                              style={{ display: "flex" }}
                              className="form-group edit-input-field"
                            >
                              <a
                                className="aPath"
                                onClick={(e) => this.getProjectData(mupps)}
                              >
                                {console.log("😊", mupps)}
                                {mupps.path}
                              </a>
                              <div
                                style={{
                                  padding: "25px",
                                  cursor: "pointer",
                                  float: "right",
                                }}
                                data-toggle="collapse"
                                href={"#subMenu" + index}
                              >
                                <i className="fa fa-ellipsis-v vertical-ellipses"></i>
                              </div>
                            </div>
                            <div
                              id={"subMenu" + index}
                              className="collapse nested-collabpse-div"
                            >
                              <i
                                aria-hidden="true"
                                style={{
                                  marginRight: "10%",
                                  cursor: "pointer",
                                }}
                                onClick={this.handleAddPicos.bind(this, mupps)}
                              >
                                Picos
                              </i>
                              <i
                                className="fa fa-pencil-square-o edit-icon"
                                aria-hidden="true"
                                onClick={this.showEditMuppsModal.bind(
                                  this,
                                  mupps,
                                  data.category_id
                                )}
                              ></i>
                              <i
                                className="fa fa-trash trash-icon"
                                aria-hidden="true"
                                onClick={(e) =>
                                  this.showDeleteMuppsModal(e, mupps.id)
                                }
                              ></i>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ));
              } else if (
                this.state.isClicked &&
                this.state.isDataAvailable &&
                this.state.allProject.projects &&
                this.state.allProject.projects.length > 0
              ) {
                return (
                  <div className="card mb-0 ">
                    <div
                      className="card-header"
                      style={{ background: "black", display: "flex" }}
                    >
                      <a
                        className="card-title collapsed aPath"
                        data-toggle="collapse"
                      >
                        {this.state.currentMupp.path}
                      </a>
                      <i
                        style={{ marginTop: "20px", marginLeft: "10px" }}
                        className="fa fa-plus plus-icon"
                        onClick={() => {
                          this.addProject(this.state.currentMupp.id);
                        }}
                      ></i>
                    </div>
                    <div className="project-renderer">
                      {this.state.allProject.projects.map((res, i) => (
                        <div>
                          <div className="card mb-0" key={i}>
                            <div
                              style={{ display: "flex" }}
                              id={i}
                              className={
                                res.important ? "project-imp" : "project-header"
                              }
                            >
                              {/* TODO:showtask call */}
                              <a
                                className="card-title"
                                style={{ width: "90%" }}
                                onClick={this.showTasks.bind(
                                  this,
                                  res,
                                  i,
                                  this.state.allProject.projects.length
                                )}
                              >
                                {res.title}
                                {console.log("res 😇", res)}
                              </a>
                              <div
                                style={{
                                  padding: "25px",
                                  cursor: "pointer",
                                  float: "right",
                                }}
                                data-toggle="collapse"
                                href={"#projectSubMenu" + i}
                              >
                                <i className="fa fa-ellipsis-v vertical-ellipses"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            id={"projectSubMenu" + i}
                            className="collapse nested-collabpse-div"
                          >
                            <i
                              aria-hidden="true"
                              style={{
                                marginRight: "10%",
                                cursor: "pointer",
                              }}
                              // onClick={() => {
                              //   // console.log("this.state.currentMupp.id", this.state.currentMupp.id)
                              //   // console.log("res on move project click ", this.state.chosenMuppCategory)
                              //   // console.log("res on move project click ", res)
                              //   this.moveProject.bind(this, this.state.currentMupp.id, this.state.chosenMuppCategory.id, res)
                              // }}
                              onClick={this.moveProject.bind(
                                this,
                                this.state.currentMupp.id,
                                this.state.chosenMuppCategory.id,
                                res
                              )}
                            >
                              Move Project
                            </i>
                            <i
                              className="fa fa-pencil-square-o edit-icon"
                              aria-hidden="true"
                              onClick={() => this.editProject(res)}
                            ></i>
                            <i
                              className="fa fa-trash trash-icon"
                              aria-hidden="true"
                              onClick={this.showDeleteProjectModal.bind(
                                this,
                                res.id
                              )}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="card mb-0">
                    <div className="card-header">
                      <a
                        className="card-title collapsed"
                        data-toggle="collapse"
                      >
                        {this.state.currentMupp.path}
                      </a>
                      <i
                        className="fa fa-plus plus-icon"
                        onClick={() => {
                          this.addProject(this.state.currentMupp.id);
                        }}
                      ></i>
                    </div>
                    <div className="card-header">
                      <a
                        className="card-title collapsed"
                        data-toggle="collapse"
                      >
                        No Data
                      </a>
                    </div>
                  </div>
                );
              }
            })()}
          </div>
        </div>

        <ModalComponent
          open={this.state.isPicosModalVisible}
          onCloseModal={this._onPicosModalClose}
          onOpen={this._onPicosModalOpen}
          categories={this.state.categories}
        />

        {
          <MoveTask
            taskType={this.state.moveTaskType}
            taskName={this.state.moveTaskName}
            taskDate={this.state.moveTaskDate}
            isLoading={this.state.isLoading}
            taskID={this.state.moveTaskId}
            notifySuccess={this.notifySuccess}
            notifyWarning={this.notifyWarning}
            closePanel={this.closePanel}
            fetchTaskData={this.showTasksApiCall}
            projectID={this.state.projectId}
            projectName={this.state.projectName}
            muppID={this.state.muppID}
            muppPath={this.state.muppPath}
            moveMuppCategoryId={this.state.moveMuppCategoryId}
            chosenMuppCategory={this.state.chosenMuppCategory}
          />
        }

        {
          <MoveProject
            moveProjectMuppCategory={this.state.moveProjectMuppCategory}
            moveProjectId={this.state.moveProjectId}
            moveProjectMuppId={this.state.moveProjectMuppId}
            closePanel={this.closeMoveProject}
            fetchTaskData={this.getProjectDataApiCall}
            notifySuccess={this.notifySuccess}
            notifyWarning={this.notifyWarning}
          />
        }

        {
          <CompleteTask
            onClose={this.onCloseCompleteNavR}
            handleTaskType={this.handleTaskType}
            handleDate={this.handleDate}
            handleTaskName={this.handleTaskName}
            handleSubmitTask={this.handleSubmitTask}
            taskName={this.state.completeCreateTaskName}
            taskType={this.state.completeCreateTaskType}
            taskDate={this.state.completeCreateTaskDueDate}
          />
        }

        <div className="col-sm-8 last-div padding-0">
          {this.state.isAddTaskClicked && this.renderAddTask()}
          {this.state.isProjectClicked && this.renderTask()}
          {this.state.isAddMupps && this.addFormRender()}
          {this.state.isEdit && this.editFormRender()}
          {this.state.isDelete && this.deleteFormRender()}
          {this.state.isDeleteProject && this.deleteProjectFormRender()}
          {this.state.isAddProjectClicked && this.renderAddProject()}
          {this.state.isEditProjectClicked && this.renderEditProject()}
        </div>
        <ToastContainer hideProgressBar={false}></ToastContainer>
      </div>
    );
  }
}
//styles.taskWrapper
let styles = {
  elipseTextMupps: {
    width: "20%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  elipseTextForTitleMupp: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  taskWrapper: {
    display: "flex",
    display: "block",
    flexDirection: "row",
  },
  textMultiLineLimit: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
