import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./homeMuppsProjectModal.css";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: "",
      type: props.type,
    };
  }

  render() {
    return (
      <div style={{ backgroundColor: "green" }}>
        <Modal
          open={this.props.open}
          onClose={this.props.onCloseModal}
          onOpen={this.props.onOpen}
        >
          <select
            className="form-control"
            onChange={(e) => {
              this.props.onChangeCategory(e);
            }}
            defaultValue=""
          >
            {this.props.categories.map((item) => (
              <option value={item.category_id}>{item.category}</option>
            ))}
          </select>
          <button onClick={this.props.saveText} className="monthlymodalButton">
            Save
          </button>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
