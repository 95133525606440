import React, { Component } from 'react'
import './muppratingStyle.css'
import { mupp, getDiscoverySession, muppRating } from "../../../constants/api-config";
import { ToastContainer, toast } from 'react-toastify';
import NumericInput from 'react-numeric-input';
import { withRouter } from 'react-router-dom';
import Header from '../../Functional-component/Header/Header'

class Mupprating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            path: '',
            roughText: '',
            token: "",
            serviceId: "",
            catagoryId: 1,
            muppList: [],
            tabelRowData: {},
            category: "",
            tableData: {},
            data: "",
            ratingsFeilds: ["Overall Mission", "Average", "Gut Instinct", "Aggregate Total"],
            tableHeading: ["Mupp", "Overall Mission", "Average", "Gut Instinct", "Aggregate Total"],
            muppRating: {}
        }
    }

    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let tkn = localStorage.getItem('token')
        let service_id = localStorage.getItem('id')
        this.setState({ token: tkn, serviceId: service_id })
        this.getMuppRatingWithServiceId(tkn, service_id);
    }

    notifySuccess = (item) => toast.success(item, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/MuppOrder') }
    });

    notifyWarning = (item) => toast.warning(item, {
        hideProgressBar: false,
        autoClose: 1000,

    });

    
    getMuppRatingWithServiceId = (token, service_id) => {
         const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },

        }
        fetch(`${muppRating}?service_id=${service_id}`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("getMuppRatingWithServiceId",result);
                    if (result.ratings && result.ratings.length > 0) {
                        this.getDiscoverySessionsApiCall(token, service_id);
                        this.setState({ muppRating: result.ratings });
                    }
                    else {
                        this.getDiscoverySessionsApiCall(token, service_id);
                        this.getMuppListApiCall(token, service_id);
                    }
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

 
    getMuppListApiCall = (token, service_id) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },
        }
        fetch(`${mupp}?service=${service_id}`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    let tableDa = [];
                    let muppRating = [];
                    let ratingArr = this.state.ratingsFeilds;
                    let resData = result.response.filter(item => item.select_for_ratings)
                   
                    if (resData) {
                        resData.map(item => {
                            const obj = ratingArr.reduce((o, key) => ({ ...o, [key]: 0 }), {})
                            tableDa.push({
                                ...this.state.tabelRowData,
                                mupp: { ...item }
                            })
                            muppRating.push({
                                mupp_id: item.id,
                                path: item.path,
                                description: item.description,
                                ratings: obj
                            })
                        })
                     }
                    this.setState({ tableData: tableDa, muppRating: muppRating })
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    getDiscoverySessionsApiCall = (token, serviceId) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },
            body: JSON.stringify({
                service_id: serviceId,
            })
        }
        fetch(getDiscoverySession, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                  
                    let arr = [...this.state.tableHeading];
                    let ratingArr = [...this.state.ratingsFeilds];
                    if (result) {
                        result.map(item => {
                            arr.splice(1, 0, item.name);
                            ratingArr.splice(0, 0, item.name);
                        })
                    }
                    this.setState({
                        tableHeading: arr,
                        ratingsFeilds: ratingArr
                    });
                    let tableHeadingData = {};
                    arr.map((item, index) => {
                        tableHeadingData[item] = 0;
                    })
                    this.setState({ tabelRowData: tableHeadingData });
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    postMuppRatingAPICall = (token, ratingData) => {
        console.log("postMuppRatingAPICall")
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },
            body: JSON.stringify({
                ratings: ratingData,
            })
        }
        fetch(muppRating, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("result postMuppRatingAPICall ",result)
      
                    this.notifySuccess("Rating added successfully")
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    MuppList() {
        let listItems = this.state.muppList.length > 0 ?
            this.state.muppList.map(item =>
                <div style={{ marginBottom: "10px", textAlign: "left", borderWidth: "1px", borderColor: "#000", lineHeight: "26pt" }}>
                    <p style={{ fontSize: "18px" }}>{item.path}</p>
                    <p style={{ fontSize: "12px" }}>{this.state.category}</p>
                </div>
            ) : <span></span>;
        return (
            <ul style={{ paddingInlineStart: "0px" }}> {listItems}</ul>
        );
    }

    createTableData = () => {
        let listItems;
        if (this.state.muppRating.length > 0) {
            listItems = this.state.muppRating.map((row, rowIndex) => {
            
                let cell = this.state.tableHeading.length > 0 ?
                    this.state.tableHeading.map((item, index) => {
                        if (index === 0) {
                            return <td style={{ textAlign: "left" }}>
                                <div style={{minHeight:"10vh",maxHeight:"10vh",overflowY:"auto"}}>
                                    Path{rowIndex + 1}-{row.path}
                                </div>
                            </td>
                        } else if (index === this.state.tableHeading.length - 1) {
                            return <td>{this.getAggregateTotal(rowIndex)}</td>
                        } else if (index === this.state.tableHeading.length - 2) {
                            return <td>
                                <NumericInput min={1} max={10} value={this.state.muppRating[rowIndex].ratings[item]} onChange={(e) => {
                                    this.changeValue(rowIndex, item, e)
                                }}
                                    style={{
                                        fontSize: "large"
                                    }}
                                />
                            </td>
                        }
                        else if (index === this.state.tableHeading.length - 3) {
                            return <td>{this.getAverage(rowIndex)}</td>
                        }
                        else {
                            return <td>
                                <NumericInput min={1} max={10} value={this.state.muppRating[rowIndex].ratings[item]} onChange={(e) => {
                                    this.changeValue(rowIndex, item, e)
                                }}
                                    style={{
                                        fontSize: "large"
                                    }}
                                />
                            </td>
                        }
                    }
                    ) : <td></td>;
                return (
                    <tr> {cell}</tr>
                );
            })
        }
        console.log("listItems",listItems);
        return (
            <tbody > {listItems}</tbody>
        );
    }

    getAverage = (rowIndex) => {
        let cell;
        cell = this.state.tableHeading.length > 0 ?
            this.state.tableHeading.map((item, index) => {
                if (index !== 0 && index !== this.state.tableHeading.length - 3 && index !== this.state.tableHeading.length - 2 && index !== this.state.tableHeading.length - 1) {
                    let finalData = this.state.muppRating[rowIndex].ratings[item];
                    return parseInt(finalData);
                }
                else {
                    return 0;
                }
            }
            ) : 0;
        let sum = cell.reduce((a, b) => a + b, 0);
        let avg = (sum / (cell.length - 4)).toFixed(1);
        return isNaN(sum) ? 0 : avg;
    }

    // getAggregateTotal = (rowIndex) => {
    //     let cell;
    //     cell = this.state.tableHeading && this.state.tableHeading.length > 0 ?
    //         this.state.tableHeading.map((item, index) => {
    //             if (index !== 0 && index !== this.state.tableHeading.length - 1) {
    //                 let finalData
    //                 if (item === "Average") {
    //                     finalData = this.getAverage(rowIndex)
    //                 } else {
    //                     finalData = this.state.muppRating[rowIndex].ratings[item];
    //                 }
    //                 return parseFloat(finalData);
    //             }
    //             else {
    //                 return 0;
    //             }
    //         }
    //         ) : 0;
    //     let sum = cell.reduce((a, b) => a + b, 0);
    //     return isNaN(sum) ? 0 : sum;
    // }

    getAggregateTotal = (rowIndex) => {
        let cell;
        cell = this.state.tableHeading && this.state.tableHeading.length > 0 ?
            this.state.tableHeading.map((item, index) => {
                if (index !== 0 && index !== this.state.tableHeading.length - 1) {
                    let finalData = 0,avg = 0
                    if (item === "Average") {
                        avg = this.getAverage(rowIndex)
                    } else if(item === "Gut Instinct" ) {
                        finalData = this.state.muppRating[rowIndex].ratings[item];
                    }
                    else{

                    }
                    finalData = (finalData + avg)/2;
                    return parseFloat(finalData);
                }
                else {
                    return 0;
                }
            }
            ) : 0;
        let sum = cell.reduce((a, b) => a + b, 0);
        return isNaN(sum) ? 0 : sum;
    }

    changeValue = (rowInd, item, newValue) => {
        let listItems = this.state.muppRating;
        listItems[rowInd].ratings[item] = newValue;
        this.setState({ muppRating: listItems });
    }

    onSubmitHandler = () => {
        let ratingData = this.state.muppRating;
        ratingData.map((row, rowIndex) => {
            row.ratings["Average"] = this.getAverage(rowIndex);
            row.ratings["Aggregate Total"] = this.getAggregateTotal(rowIndex)
            return row;
        })
        this.postMuppRatingAPICall(this.state.token, ratingData);
        // this.props.history.push('/MuppOrder');
    }

    tableHeadad = () => {
        let listItems = this.state.tableHeading.length > 0 ?
            this.state.tableHeading.map((item, index) =>
                index === 0 ? <th>{item}</th> : <th>{item}</th>) : <th></th>;
        return (
            <tr> {listItems}</tr>
        );
    }

    render() {
        return (
            <div className="ratingContainer">
                <Header header="Mupp Rating" />
                <div className="col-sm-12 tableFixHead padding-0" style={{ margin: "1em 0" }}>
                    <table id='mupps' style={{ backgroundColor: "white", color: 'black' }}>
                        <thead >
                            {this.tableHeadad()}
                        </thead>
                        {this.createTableData()}
                    </table>
                </div>

                <div style={{ textAlign: "end" }}>
                    <button onClick={() => {this.onSubmitHandler(); }}>Submit</button>
                </div>
                
                <ToastContainer hideProgressBar={false}> </ToastContainer>
            </div>
           
        )
    }
}

export default withRouter(Mupprating)