import React, { Component } from 'react'
import {
    postInbox, getInbox, updateInbox, deleteInbox,
    getAllMupps, getMuppCategories, moveToProject,
    getProject
} from '../../constants/api-config'
import Delete from '../../assests/delete.jpg'
import Edit from '../../assests/Edit.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './inboxStyle.css';
import Header from '../Functional-component/Header/Header'
import ModalComponent from '../Modal/Modal'
import Addtask from '../Functional-component/Add-task/Addtask'
import Movefolder from '../../assests/moveFolder.png'
import './inboxStyle.css'
import DirectiveLoader from '../Functional-component/Loader/loader.component';
import DeleteTask from "../shared/deleteTask";
export default class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            text: '',
            inboxData: '',
            habitId: '',
            value: '',
            isUpdate: false,
            openPanel: false,
            isMoveClicked: true,
            muppCategoryData: [],
            personalMuppPathData: [],
            officalMuppPathData: [],
            selectedCategory: '',
            selectedPathID: '',
            isOffical: false,
            projectData: [],
            projectId: '',
            inboxId: '',
            inboxName: '',
            taskType: 'THIS_WEEK',
            taskDate: null,
            projectId: '',
            isLoading: false,
            muppData: [],
            muppPathData: []
        }
        this.handleCategoryChange = this.handleCategoryChange.bind(this)

    }
    async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        this.getInboxAPI()
        this.getMuppCategoriesApiCall()
    }

    getMuppCategoriesApiCall = () => {
        console.log('waiting mount')
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            }
        }

        fetch(getMuppCategories, requestOptions)
            .then(res => res.json())
            .then(
                async (result) => {
                    console.log(result)
                    await this.setState({ muppCategoryData: result.category })
                    console.log("mupp", this.state.muppCategoryData)
                },
                (error) => {
                    console.log("error in getMuppCategories api, file name - thisWeek.js", error);
                }

            )
    }

    postInboxAPI = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "name": this.state.text
            })
        }
        fetch(postInbox, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.onClose();
                        this.getInboxAPI()
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    getInboxAPI = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(getInbox, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ inboxData: result.inbox_items, isLoading: true })
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    updateInboxAPI = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "inbox_id": this.state.habitId,
                "name": this.state.text
            })
        }
        fetch(updateInbox, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.onClose();
                        this.getInboxAPI()
                        this.setState({ value: '' })
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    deleteInboxAPI = (id) => {
        let deleteDailyHabitURL = deleteInbox + "?" + "inbox_id=" + id;

        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(deleteDailyHabitURL, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.onClose();
                        this.getInboxAPI()
                    }
                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })
    }

    getAllMuppsAPICall = () => {
        let token = localStorage.getItem('token');

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },

        }
        fetch(getAllMupps, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res3", result)
                    this.setState({ muppData: result })

                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })

    }

    getProjectApiCall = (id) => {

        let getProjectURL = getProject + "?" + "mupp=" + id;

        let token = localStorage.getItem('token');

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },

        }
        fetch(getProjectURL, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res3", result)
                    if (result.projects === undefined) {
                        console.log("kuch mat save kro")
                    }
                    else {
                        this.setState({ projectData: result.projects })
                        console.log("project", this.state.projectData)
                    }


                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })

    }

    postMoveToProjectApiCall = () => {
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "inbox_id": this.state.inboxId,
                "project": this.state.projectId,
                "task_type": this.state.taskType,
                "name": this.state.inboxName,
                "date": this.state.taskDate,
                "mupp":this.state.selectedPathID

            })
        }
        fetch(moveToProject, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res3", result)
                    if (result.success === "true") {
                        this.notifySuccess(result.message)
                        this.closePanel();
                        this.getInboxAPI()
                    }
                    else {
                        this.notifyWarning(result.message)
                    }

                },
                (error) => {
                    console.log("error in getTasksByType api, file name - thisWeek.js", error);
                })

    }

    Addtask = () => {
        this.onOpen()
        this.setState({ isUpdate: false })
    }

    saveText = () => {
        if (this.state.isUpdate) {
            this.updateInboxAPI()
        } else {
            console.log(this.state.text)
            if (this.state.text === "") {
                this.notifyWarning("Field can not be blank")
            }
            else {
                console.log("value hai")
                this.postInboxAPI()
            }
        }
    }

    onChangeText = (e) => {
        this.setState({ text: e.target.value })
    }

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    editInbox = (item) => {
        this.setState({ value: item.name, habitId: item.inbox_id, isUpdate: true })
        this.onOpen()
    }

    deleteTask = (id) => {
        console.log("DELETE TASK INNER",id)
        if(window.confirm("Are you sure to delete this task")){
            console.log("DELETE TASK INNER")
            this.deleteInboxAPI(id)
        }
    }

    inboxList = () => {
        this.deleteTask = this.deleteTask.bind(this);
        if (this.state.inboxData && this.state.inboxData.length > 0) {
            return this.state.inboxData.map(item =>
                <ul className="inbox-tabs">
                    <li className="col-sm-12 inbox-tab-li">
                        <div className="col-sm-10"><h4 className="text-ellipses-h4">{item.name}</h4></div>
                        <div className="col-sm-2 test-align-right">
                            {/* <img onClick={this.deleteInbox.bind(this, item)} src={Delete} height="25px" width="25px" style={{ background: "white", cursor: "pointer", marginRight: "10px" }} /> */}
                            {/* <DeleteTask deleteTask={this.deleteTask.bind(this, item.inbox_id)} /> */}
                            {/* {if(window.confirm("Are you sure to delete this task")){this.bind.deleteTask(this,item.inbox_id)};} */}
                            <img 
                            onClick= {this.deleteTask.bind(this,item.inbox_id)} 
                            src={Delete} style={{cursor:"pointer",marginRight:"10px"}} height="25px" width="25px" />
                            <img onClick={this.editInbox.bind(this, item)} src={Edit} height="25px" width="25px" style={{ background: "white", cursor: "pointer" }} />
                            <img onClick={this.openNavR.bind(this, item)} src={Movefolder} height="25px" width="25px" style={{ background: "white", cursor: "pointer", marginLeft: "10px" }} />
                        </div>
                    </li>
                </ul>
            )
        }
        else {
            return <div style={{ display: 'flex', justifyContent: 'center' }}><p style={{ fontSize: '24px', fontWeight: '400' }}>No Data</p></div>
        }
    }

    setOpenPanel = () => {
        this.setState({ openPanel: false })
    }

    openSidePanel = () => {
        this.setState({ openPanel: true })
    }

    openNavR = (item) => {
        console.log(item)
        this.setState({ inboxId: item.inbox_id, inboxName: item.name })
        //localStorage.setItem("task", JSON.stringify(task));
        document.getElementById('rightSlidePanel').style.display = 'block';
        document.getElementById('closeIcon').style.display = 'block';
        this.getAllMuppsAPICall()
    }

    closePanel = () => {
        console.log("closed")
        document.getElementById('rightSlidePanel').style.display = 'none';
        document.getElementById('closeIcon').style.display = 'none';
    }

    async handleCategoryChange(e) {
        console.log("Fruit Selected!!");
        await this.setState({ selectedCategory: e.target.value });
        console.log(this.state.selectedCategory);
        console.log(this.state.muppData);

        this.state.muppData.forEach(async item => {
            console.log(item.category)
            if (this.state.selectedCategory === item.category) {
                console.log("mupp", item.mupps)
                if (item.mupps.length > 0) {
                    console.log("value hai")
                    await this.setState({ muppPathData: item.mupps })

                }
                else {
                    console.log("nhi hai")
                    await this.setState({ muppPathData: [] })
                }
            }
        })


    }

    handlePathChange = async (e) => {
        console.log(e.target.value)
        await this.setState({ selectedPathID: e.target.value, projectData: [] })
        this.getProjectApiCall(this.state.selectedPathID)

    }

    handleDate = (e) => {
        this.setState({ taskDate: e.target.value })
    }

    handleTaskType = (e) => {
        this.setState({ taskType: e.target.value })
    }

    handleProjectChange = (e) => {
        this.setState({ projectId: e.target.value })
    }

    handleMoveToInbox = () => {
        this.postMoveToProjectApiCall()
    }

    renderTask = () => {
        return (
            <div>
                <i className="fa fa-close close-icon" id="closeIcon" style={{ display: 'none' }} onClick={this.closePanel} aria-hidden="true"></i>
                <ul className="render-task">
                    <div className="task-accordion" id="rightSlidePanel" style={{ display: 'none' }}>
                        <h4>Move task from Inbox</h4>
                        <div className="form-group">
                            <p>Select MUPP cateogry</p>
                            <select value={this.state.selectedCategory} onChange={this.handleCategoryChange} name="task-type" className="form-control">
                                <option value="DEFAULT">Select Categories</option>
                                {this.state.muppCategoryData.map(item =>
                                    <option value={item.name}>{item.name}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <p>Select Mupp</p>
                            <select value={this.state.selectedPathID} onChange={this.handlePathChange} name="task-type" className="form-control" >
                                <option value="DEFAULT">Select Mupp</option>
                                {this.state.muppPathData.map(item =>
                                    <option key={item.id} value={item.id}>{item.path}</option>)
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <p>Select Project</p>
                            <select value={this.state.projectId} onChange={this.handleProjectChange} name="task-type" className="form-control" >
                                <option value="DEFAULT">Select project</option>
                                {this.state.projectData.map(item =>
                                    <option key={item.id} value={item.id}>{item.title}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <p>Select Type</p>
                            <select onChange={this.handleTaskType} name="task-type" className="form-control" >
                                <option value="DEFAULT">Select Task type</option>
                                <option value="THIS_WEEK">THIS_WEEK</option>
                                <option value="LATER">LATER</option>
                                <option value="AWAITING">AWAITING</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <p>Select Date</p>
                            <input type="date"
                                min={new Date().toISOString().split('T')[0]}
                                onChange={this.handleDate}
                            />
                        </div>
                        <div className="col-sm-12 add-mupps-button">
                            <button onClick={this.handleMoveToInbox}>Save</button>

                        </div>
                    </div>
                </ul>
            </div>
        )
    }

    onOpen = () => {
        this.setState({ show: true })
    }

    onClose = () => {
        this.setState({ show: false })
    }


    render() {
        return (
            <div>
                <Header header="Inbox" />
                <Addtask
                    buttonname="Add"
                    Addtask="Add Task"
                    onClick={this.Addtask}
                />
                {this.state.isLoading ? <div>
                    {this.inboxList()}
                </div> :
                    <div style={{ marginTop: "25%", marginLeft: "48%" }}><DirectiveLoader /></div>}

                <ModalComponent open={this.state.show}
                    onCloseModal={this.onClose}
                    onOpen={this.onOpen}
                    saveText={this.saveText}
                    onChange={this.onChangeText}
                    value={this.state.value} />
                {this.renderTask()}
                <ToastContainer hideProgressBar={false}
                >
                </ToastContainer>
            </div>
        )
    }
}