import React, { Component } from 'react'


class CompleteTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    render(){
        return (
            <div>
            <i className="fa fa-close close-icon" id="completeCloseIcon" style={{ display: 'none' }} onClick={() => this.props.onClose()} aria-hidden="true"></i>
            <ul className="render-task">
                <div className="task-accordion" id="completeRightSlidePanel" style={{ display: 'none' }}>
                    <div className="form-group">
                        <textarea
                            type="text"
                            defaultValue={this.props.taskName}
                            name="Title"
                            style={{ height: "20vh", marginTop: "20px" }}
                            className="form-control"
                            required
                            // onChange={e => this.setState({ taskName: e.target.value })}
                            onChange={(e) => { this.props.handleTaskName(e) }}
                        />
                    </div>
                    <div className="form-group">
                        <select 
                            name="task-type" 
                            onChange={this.props.handleTaskType}
                            defaultValue={this.props.taskType} 
                            value={this.props.taskType}
                            className="form-control" 
                        >
                            <option value="DEFAULT">Select Task type</option>
                            <option value="THIS_WEEK">THIS_WEEK</option>
                            <option value="LATER">LATER</option>
                            <option value="AWAITING">AWAITING</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <input
                            onChange={this.props.handleDate}
                            min={new Date().toISOString().split('T')[0]}
                            type="date"
                            name="date"
                            className="form-control"
                            required
                            defaultValue={this.props.taskDate}
                            value={this.props.taskDate}
                        />
                    </div>
                    <div className="col-sm-12 add-mupps-button">
                        <button type="submit" onClick={this.props.handleSubmitTask}>Save</button>
                    </div>
                </div>
            </ul>
        </div>
        )
    }

}

export default CompleteTask;