import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import "./success.css"

export default class Success extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: ''
        }
    }


    render() {
        return (
            <div className="successContainer">
                <h4 style={{ color: 'white' }}>Your Email is verfied </h4>
                <Link to="/sign">
                    <button id="successBtn">Login</button>
                </Link>
            </div>
        )
    }
}
