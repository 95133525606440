import axios from "axios";
// import React from "react";
import { dailyJournal } from "../../constants/api-config";
import { toast } from "react-toastify";

const token = localStorage.getItem("token");

export const notifyWarning = (text) =>
  toast.warning(text, {
    hideProgressBar: false,
    autoClose: 3000,
  });
export const notifySuccess = (text) =>
  toast.success(text, {
    hideProgressBar: false,
    autoClose: 2000,
  });

export const dailyJournalGet = async (setdailyJournalData) => {
  try {
    const { data } = await axios.get(dailyJournal, {
      headers: { Authorization: `JWT ${token}` },
    });
    console.log(data);
    setdailyJournalData(data);
  } catch (error) {
    console.error("🔴 dailyJournalGet:", error);
  }
};

export const dailyJournalUpdate = async (
  id,
  title,
  journal_text,
  onCloseAddModal,
  setdailyJournalData
) => {
  try {
    const body = {
      id,
      title,
      journal_text,
    };
    console.log(body);
    const { data } = await axios.put(dailyJournal, body, {
      headers: { Authorization: `JWT ${token}` },
    });
    console.log({ data });
    notifySuccess(data.message);
    onCloseAddModal();
    dailyJournalGet(setdailyJournalData);
  } catch (error) {
    console.error("🔴 dailyJournalUpdate:", error);
  }
};
export const dailyJournalPost = async (
  title,
  journal_text,
  onCloseAddModal,
  setdailyJournalData
) => {
  try {
    const body = {
      title,
      journal_text,
    };
    const { data } = await axios.post(dailyJournal, body, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    console.log(data);
    notifySuccess(data.message);
    onCloseAddModal();
    dailyJournalGet(setdailyJournalData);
  } catch (error) {
    console.error("🔴 dailyJournalPost:", error);
  }
};
export const dailyJournalDelete = async (item, journal_text, setdailyJournalData) => {
  if (!window.confirm("Are you sure to delete this task")) {
    return;
  }
  try {
    console.log({ delete: item });
    const body = {
      id: item.daily_journal_id,
      title: item.daily_journal_title,
      journal_text,
    };
    const { data } = await axios.delete(`${dailyJournal}?id=${body.id}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
    console.log(data);
    notifySuccess(data.message);
    if (data.success) dailyJournalGet(setdailyJournalData);
  } catch (error) {
    console.error("🔴 dailyJournalDelete:", error);
  }
};
