import React, { useEffect, useState } from "react";
import Header from "../Functional-component/Header/Header";
import Addtask, { EditPasscodeModal } from "./Addtask";
import ModalComponent from "./dailyJournalModal";
import axios from "axios";
import "./journal.css";
import Delete from "../../assests/delete.jpg";
import Edit from "../../assests/Edit.png";
import { dailyJournalExp, dailyJournalPass } from "../../constants/api-config";
import DirectiveLoader from "../Functional-component/Loader/loader.component";
import { Prompt } from "react-router-dom";
import {
  dailyJournalDelete,
  dailyJournalGet,
  dailyJournalPost,
  dailyJournalUpdate,
  notifySuccess,
  notifyWarning,
} from "./ApiHandler";

export default function Log() {
  const [showAddModal, setshowAddModal] = useState(false);
  const [editTask, seteditTask] = useState(false);
  const [success, setsuccess] = useState({ response: false, data: "" });
  const [dailyJournalData, setdailyJournalData] = useState({});
  const [journalID, setjournalID] = useState(0);
  const [titleValue, settitleValue] = useState("");
  const [journalText, setjournalText] = useState("");
  const [defaultjournalText, setdefaultjournalText] = useState("");
  const [passAuth, setpassAuth] = useState(-1);
  const [title, settitle] = useState("");
  const [editPasscodeModal, seteditPasscodeModal] = useState(false);
  const token = localStorage.getItem("token");
  const onCloseAddModal = () => {
    setshowAddModal(false);
    settitleValue("");

    setdefaultjournalText("");
    settitle("");
    setjournalText("");
  };

  const onOpenAddModal = () => setshowAddModal(true);

  useEffect(() => {
    dailyJournalGet(setdailyJournalData);
  }, []);

  const saveTextHandler = () => {
    console.log("😇");
    if (editTask) {
      // if (text === "") return dailyJournalUpdate(titleValue);
      dailyJournalUpdate(
        journalID,
        title === "" ? titleValue : title,
        journalText === "" ? defaultjournalText : journalText,
        onCloseAddModal,
        setdailyJournalData
      );
    } else {
      console.log(title);
      if (title === "") {
        notifyWarning("Field can not be blank");
      } else {
        dailyJournalPost(
          title,
          journalText,
          onCloseAddModal,
          setdailyJournalData
        );
      }
    }
  };
  const addTaskHandler = (journalPlaceholder) => {
    setdefaultjournalText(journalPlaceholder);
    onOpenAddModal();
    seteditTask(false);
  };

  const LogsEdit = async (item) => {
    console.log({ item });
    try {
      const {
        data: { response },
      } = await axios.post(
        `${dailyJournalExp}?id=${item.daily_journal_id}`,
        { id: item.daily_journal_id, passcode: dailyJournalData.passcode },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      console.log({ logsEdit: response });
      settitleValue(response[0].title);
      setdefaultjournalText(response[0].journal_text);
    } catch (error) {
      console.error("🔴 dailyJournalListExp:", error);
    }
    setjournalID(item.daily_journal_id);
    seteditTask(true);
    onOpenAddModal();
  };

  const getDateIllistratin = () => {
    var today;
    var objToday = new Date(),
      domEnder = (function () {
        var a = objToday;
        if (/1/.test(parseInt((a + "").charAt(0)))) return "th";
        a = parseInt((a + "").charAt(1));
        return 1 == a ? "st" : 2 == a ? "nd" : 3 == a ? "rd" : "th";
      })(),
      dayOfMonth =
        today + (objToday.getDate() < 10)
          ? "0" + objToday.getDate() + domEnder
          : objToday.getDate() + domEnder,
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      curMonth = months[objToday.getMonth()];
    return dayOfMonth + " " + curMonth;
  };

  const JournalDetailCard = ({ data }) => {
    const journalPlaceholder = ` Today is... ${getDateIllistratin()} 
  <br/> <br/>
  Feelings: <br/>
  These are my deepest feelings<br/>
  <br/>
  Insights<br/>
  Insights and connections I got in the last 24 hrs ...<br/>
  <br/>
  Acknowledgements / Gratitude<br/>
  A big thanks to cuz for providing me support on ...<br/>
  `;
    return (
      <>
        <Addtask
          buttonname="Add"
          Addtask="Add Journal"
          onClick={() => addTaskHandler(journalPlaceholder)}
        />
        <Prompt
          when={localStorage.getItem("passAuth")}
          message={(location, action) => {
            // if (action === "PUSH") {
            console.log("Leaving Page...");
            localStorage.setItem("passAuth", false);
            // }

            return `Are you sure you want to go to ${location.pathname}?`;
          }}
        />
        {data.map((item, i) => {
          console.log(item);
          return (
            <ul className="inbox-tabs" key={i}>
              <li className="col-sm-12 inbox-tab-li">
                <div className="col-sm-10">
                  <h4 className="text-ellipses-h4">
                    {item.daily_journal_title}
                  </h4>
                </div>
                <div className="col-sm-2 test-align-right">
                  <img
                    // onClick={this.deleteTask.bind(this, item.inbox_id)}
                    onClick={() =>
                      dailyJournalDelete(item, journalText, setdailyJournalData)
                    }
                    src={Delete}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                    height="25px"
                    width="25px"
                    alt=""
                  />
                  <img
                    // onClick={this.editInbox.bind(this, item)}
                    onClick={() => LogsEdit(item)}
                    src={Edit}
                    height="25px"
                    width="25px"
                    style={{ background: "white", cursor: "pointer" }}
                    alt=""
                  />
                </div>
              </li>
            </ul>
          );
        })}
      </>
    );
  };
  const passcodegenerateHandler = async () => {
    try {
      let randomPass = 0;
      let token = localStorage.getItem("token");
      let email = localStorage.getItem("email");
      while (randomPass < 4) randomPass = Math.floor(Math.random() * 9999);
      const { data } = await axios.post(
        dailyJournalPass,
        {
          email,
          passcode: randomPass,
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );

      // console.log({ auth: data });
      setsuccess({ response: true, data: randomPass });
    } catch ({ response }) {
      console.log({ response });
      notifyWarning(response.data.message);
    }
  };
  const passcodeAuthenticator = () => {
    let handleAuth = (inputVal) => {
      // setpassAuth(inputVal);
      console.log({ inputVal });
      if (dailyJournalData.passcode === Number(inputVal)) {
        console.log(dailyJournalData.passcode, inputVal);
        notifySuccess("Authenticated");
        localStorage.setItem("passAuth", true);
        window.location.reload();
      } else {
        notifyWarning("Try Again");
        localStorage.setItem("passAuth", false);
      }
    };
    console.log(localStorage.getItem("passAuth"));
    if (localStorage.getItem("passAuth") === "true")
      return <JournalDetailCard data={dailyJournalData.response} />;
    else if (localStorage.getItem("passAuth") === "false") {
      return (
        <>
          <div className="col-sm-12 add-task-header ">
            <div style={{ width: "100%" }}>
              <button
                style={{ float: "right" }}
                onClick={() => seteditPasscodeModal(true)}
              >
                Forgot Passcode
              </button>
            </div>

            <EditPasscodeModal
              seteditPasscodeModal={seteditPasscodeModal}
              editPasscodeModal={editPasscodeModal}
            />
          </div>
          <Passcode
            heading="Confirm Passcode"
            input={true}
            // onInputChange={(e) => setpassAuth(e.target.value)}
            button="Confirm"
            btnhandler={handleAuth}
          />
        </>
      );
    }
  };

  const Passcode = ({ heading, input, btnhandler, button, content }) => {
    const [inputVal, setinputVal] = useState("");
    console.log({ input });
    return (
      <div className="journal_parent">
        <div className="journal_child">
          <h3 id="h6">{heading}</h3>
          <p>{content}</p>

          {input && (
            <input
              name="Email"
              type="password"
              maxLength="4"
              placeholder="Passcode should be 4 digit"
              onChange={(e) => setinputVal(e.target.value)}
            />
          )}

          <button id="btn1" type="button" onClick={() => btnhandler(inputVal)}>
            {button}
          </button>
        </div>
      </div>
    );
  };

  if (Object.entries(dailyJournalData).length === 0)
    return (
      <div style={{ marginTop: "25%", marginLeft: "47%" }}>
        <DirectiveLoader />
      </div>
    );

  return (
    <div>
      <Header header="Daily Journal" />
      {success.response ? (
        <Passcode
          heading="Passcode Generated!"
          content={`Your generated Passcode is ${success.data}`}
          button="Confirm Passcode"
          btnhandler={() => setsuccess({ response: false, data: "" })}
        />
      ) : !dailyJournalData.isPasscode ? (
        <Passcode
          heading="Generate passcode"
          input={false}
          button="Generate"
          btnhandler={passcodegenerateHandler}
        />
      ) : dailyJournalData.success && dailyJournalData.isPasscode ? (
        passcodeAuthenticator()
      ) : (
        <div className="text-center">No Data</div>
      )}
      <ModalComponent
        open={showAddModal}
        onCloseModal={onCloseAddModal}
        onOpen={onOpenAddModal}
        onChange={(e) => settitle(e.target.value)}
        value={titleValue}
        saveText={saveTextHandler}
        onjournalTextChange={(e) => setjournalText(e)}
        defaultjournalText={defaultjournalText}
      />
    </div>
  );
}
