/**
 * @author kajal singh
 * @description this component use to add and update picos data
 */

import React, { Component } from 'react';
import './picture-success-picos.css';
import { addPicos,CDN} from '../../constants/api-config';
import 'react-toastify/dist/ReactToastify.css';
import ContentEditable from 'react-contenteditable';
import { ToastContainer, toast } from 'react-toastify';


export default class PictureSuccessPicos extends Component {
    constructor(props) {
        super(props);
        this.value = localStorage.getItem('token');
        let data = localStorage.getItem('picosData');
        this.picosData = JSON.parse(data)
        this.state = {
            rough_text: "",
            heading: "",
            description: "",
            image1: "",
            image2: "",
            mupp: "",
            isClicked: false,
            isDataExist: false,
            imagePreviewOne: null,
            imagePreviewTwo: null,
            photoOne:null,
             photoTwo:null,
            id: '',
            edittextbox : false
        }
    }

    /**
     * @author kaja singh
     * @description handleImageOneChange this method used to get image1 file and show on page
     */
    handleImageOneChange(e) {
        if (e.target.files && e.target.files[0]) {
            this.setState({ image1: e.target.files[0] });
            let reader = new FileReader();
            reader.onload = function (ev) {
                this.setState({ imagePreviewOne: ev.target.result.split(',')[1] });
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    /**
     * @author kaja singh
     * @description handleSecondImageChange this method used to get image2 file and show on page
     */
    handleSecondImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            this.setState({ image2: e.target.files[0] });
            let reader = new FileReader();
            reader.onload = function (ev) {
                this.setState({ imagePreviewTwo: ev.target.result.split(',')[1] });
            }.bind(this);
            reader.readAsDataURL(e.target.files[0]);
        }
    }
     
   
    /**
     * @author kaja singh
     * @description onSubmit method hit api for post save and update picos form 
     */
    onSubmit = (event) => {
        event.preventDefault();
        this.notifySuccessPicos("Response is updating please wait")
        var form = new FormData();
        form.append("rough_text", this.state.rough_text);
        form.append("heading", this.state.heading);
        form.append("description", this.state.description);
        form.append("image1", this.state.image1);
        form.append("image2", this.state.image2);
        var requestOptions = {
            method: 'POST',
            headers: { "Authorization": `JWT ${this.value}` },
            body: form
        }
        if (this.state.isDataExist) {
            form.append("picos_id", this.state.id);
            requestOptions.method = 'PUT';
        } else form.append("mupp", this.picosData.id);
        fetch(addPicos, requestOptions)
            .then(res => res.json())
            .then((response) => {
                console.log(response)
                if(response.success === "true"){
                console.log("RESULT",response.success);
                this.notifySuccess(response.message);
                // this.props.history.push('/picture_success')
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    notifySuccessPicos = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });


    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/picture_success') }
        
      });
    /**
     * @author kaja singh
     * @description componentDidMount this method get picos data on the basis of mupp id 
     */
    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        console.log("picos", this.picosData)
        fetch(addPicos + "?mupp=" + this.picosData.id, {
            "method": "GET",
            "headers": {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.value}`,
            },
        })
            .then(res => res.json())
            .then((res) => {
                if (res) {
                    console.log(res)
                    this.setState({
                        rough_text: res.rough_text == 'undefined' ? '' : res.rough_text,
                        heading: res.heading == 'undefined' ? '' : res.heading,
                        description: res.description == 'undefined' ? '' : res.description,
                        mup: res.id,
                        photoOne:res.image1_url,
                        photoTwo:res.image2_url,
                        id: res.id
                    });
                }

                if (res.id) this.setState({ isDataExist: true });
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    /**
     * @author kajal singh
     * @description onContinue this method helps to move image page
     */
    onContinue = () => {
        this.setState({ isClicked: true })
    }

    removeFirstImage = () => {
        this.setState({
            image1: null,
            imagePreviewOne: null
        })
    }

    removeSecondImage = () => {
        this.setState({
            image2: null,
            imagePreviewTwo: null
        })
    }

    pasteAsPlainTextforRough = (event) => {
        event.preventDefault()
        const text = event.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
        console.log("text", text)
        this.setState({ rough_text: text })
    }

    pasteAsPlainTextforShortSummary = (event) => {
        event.preventDefault()

        const text = event.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
        // console.log("text", text)
        this.setState({ heading: text })
    }

    pasteAsPlainTextforDescription = (event) => {
        event.preventDefault()

        const text = event.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
        // console.log("text", text)
        this.setState({ description: text })
    }

    render() {
        return (
            <div className="col-sm-12 success-picture-picos-page">
                <div className="col-sm-4 left-block">
                    <div className="col-sm-12 path-div">
                        <h4>Path</h4>
                        <label className="path-div-label">{this.picosData.path}</label>
                    </div>
                    <div className="col-sm-12 desc-div">
                        <h4>Description</h4>
                        <label className="path-div-label">{this.picosData.description}</label>
                    </div>
                </div>
                <form className="col-sm-8" onSubmit={this.onSubmit}>
                    {!this.state.isClicked && <div className="col-sm-12 rough-div form-group">
                        <h4>Rough Text</h4>
                        {/* <ContentEditable 
                            
                            className="form-control"
                            onPaste={this.pasteAsPlainTextforRough}
                            innerRef={this.contentEditable}
                            style={{  minHeight:"20vh", height:this.state.edittextbox && "auto",resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                            // style={{ minHeight: "20vh", height:this.state.edittextbox && "auto", resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                            // style={{  minHeight:"20vh", height:"auto",resize:"vertical",overflow: 'hidden', textAlign: 'initial' }}
                            html={this.state.rough_text}
                            disabled={false}
                            onChange={(data) => { this.setState({ rough_text: data.target.value }) }}
                            onFocus = {() => {this.setState({edittextbox : true})}}
                            onBlur ={ () => {this.setState({edittextbox:false})}}
                        /> */}

                        <textarea
                                className="textEditor"
                                innerRef={this.pasteAsPlainTextforRough}
                                disabled={false}
                                style={{  minHeight:"20vh", height:this.state.edittextbox && "auto",resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                                html = {this.state.rough_text}
                                // style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                value={this.state.rough_text}
                                onChange={(e) => {
                                    this.setState({ rough_text: e.target.value })
                                }}
                                onFocus = {() => {this.setState({edittextbox :true})}}
                                onBlur ={ () => {this.setState({edittextbox:false})}}
                            />

                    </div>}
                    {!this.state.isClicked && <div className="col-sm-12 heading-div form-group">
                        <h4>Short Summary</h4>
                        {/* <ContentEditable
                            className="textEditor"
                            innerRef={this.contentEditable}
                            onPaste={this.pasteAsPlainTextforShortSummary}
                            style={{  minHeight:"20vh", height:this.state.edittextbox && "auto",resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                            // style={{  minHeight:"20vh", height:"auto",resize:"vertical",overflow: 'hidden', textAlign: 'initial' }}
                            // style={{ minHeight: "20vh", height: "20vh", resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                            html={this.state.heading}
                            disabled={false}
                            name="heading"
                            onChange={(data) => { this.setState({ heading: data.target.value }) }}
                            onFocus = {() => {this.setState({edittextbox : true})}}
                            onBlur ={ () => {this.setState({edittextbox:false})}}

                        /> */}
                        

                        <textarea
                                className="textEditor"
                                innerRef={this.pasteAsPlainTextforShortSummary}
                                disabled={false}
                                style={{  minHeight:"20vh", height:this.state.edittextbox && "auto",resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                                html = {this.state.heading}
                                // style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                value={this.state.heading}
                                onChange={(e) => {
                                    this.setState({ heading: e.target.value })
                                }}
                                onFocus = {() => {this.setState({edittextbox :true})}}
                                onBlur ={ () => {this.setState({edittextbox:false})}}
                        />

                        

                    </div>}
                    {!this.state.isClicked &&
                        <div className="col-sm-12 description-div form-group">
                            <h4>Actual Situation Description</h4>
                            {/* <ContentEditable
                                
                                className="textEditor"
                                innerRef={this.contentEditable}
                                onPaste={this.pasteAsPlainTextforDescription}
                                style={{  minHeight:"20vh", height:this.state.edittextbox && "auto",resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                                // style={{  minHeight:"20vh", height:"auto",resize:"vertical",overflow: 'hidden', textAlign: 'initial' }}
                                // style={{ maxHeight: "100vh", minHeight:"20vh",height:this.state.edittextbox && "auto", overflowY: 'scroll', textAlign: 'initial' }}
                                html={this.state.description}
                                disabled={false}
                                onChange={(data) => { this.setState({ description: data.target.value }) }}
                                onFocus = {() => {this.setState({edittextbox : true})}}
                                onBlur ={ () => {this.setState({edittextbox:false})}}
    
                            /> */}

                            <textarea
                                className="textEditor"
                                innerRef={this.pasteAsPlainTextforDescription}
                                disabled={false}
                                style={{  minHeight:"20vh", height:this.state.edittextbox && "auto",resize:"vertical",overflowY: 'scroll', textAlign: 'initial' }}
                                html = {this.state.description}
                                // style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                value={this.state.description}
                                onChange={(e) => {
                                    this.setState({ description: e.target.value })
                                }}
                                onFocus = {() => {this.setState({edittextbox :true})}}
                                onBlur ={ () => {this.setState({edittextbox:false})}}
                             />


                        </div>}
                    {!this.state.isClicked &&
                        <div className="col-sm-12 continue">
                            <button type="button" className="continue-button" onClick={() => this.onContinue()}>Save & Continue</button>
                        </div>
                    }
                    {this.state.isClicked &&
                        <div className="col-sm-12 image1-div">
                            <h4>image 1</h4>
                            <div className="image1">
                                <input
                                    id="files"
                                    type="file"
                                    name="image1"
                                    onChange={this.handleImageOneChange.bind(this)}
                                    className={this.state.imagePreviewOne || this.state.photoOne ? 'hidden':'image-input'}
                                    accept="image/x-png,image/jpeg,image/jpg" />
                                {this.state.imagePreviewOne && <i className="fa fa-close fa-2x" onClick={this.removeFirstImage} style={{textAlign:"end"}}></i>}
                                {this.state.imagePreviewOne &&
                                    <label htmlFor="files">
                                        <img src={"data:image/png;base64," + this.state.imagePreviewOne} className="thumbnail" alt="image1" />
                                    </label>
                                }
                                {this.state.imagePreviewOne && <p style={{color:"white",fontWeight: "bold"}}>Click on cross icon to change the image</p>}

                                {this.state.photoOne && <i className="fa fa-close fa-2x" style={{textAlign:"end"}} onClick={()=>{this.setState({photoOne:null})}}></i>}
                                 {this.state.photoOne &&
                                     <label htmlFor="files">
                                         <img src={`${CDN}${this.state.photoOne}`}  className="thumbnail" alt="image1" />
                                     </label>
                                 }
                                {this.state.photoOne && <p style={{color:"white",fontWeight: "bold"}}>Click on cross icon to change the image</p>}

                            </div>
                        </div>
                    }
                    {this.state.isClicked &&
                        <div className="col-sm-12 image2-div">
                            <h4>image 2</h4>
                            <div className="image2">
                                <input
                                    id="secondImage"
                                    type="file"
                                    name="image2"
                                    className={this.state.imagePreviewTwo || this.state.photoTwo? 'hidden':'image-input'}
                                    accept="image/x-png,image/jpeg,image/jpg"
                                    onChange={this.handleSecondImageChange.bind(this)} />
                                {this.state.imagePreviewTwo && <i className="fa fa-close fa-2x" style={{textAlign:"end"}} onClick={this.removeSecondImage}></i>}
                                {this.state.imagePreviewTwo && <label htmlFor="secondImage">
                                    <img src={"data:image/png;base64," + this.state.imagePreviewTwo} className="thumbnail" alt="image2" />
                                </label>}
                                {this.state.imagePreviewTwo && <p style={{color:"white",fontWeight: "bold"}}>Click on cross icon to change the image</p>}

                                {this.state.photoTwo && <i className="fa fa-close fa-2x" style={{textAlign:"end"}} onClick={()=>{this.setState({photoTwo:null})}}></i>}
                                 {this.state.photoTwo && <label htmlFor="secondImage">
                                     <img src={`${CDN}${this.state.photoTwo}`} className="thumbnail" alt="image2" />
                                 </label>}
                                 {this.state.photoTwo && <p style={{color:"white",fontWeight: "bold"}}>Click on cross icon to change the image</p>}
                            </div>
                            
                        </div>
                    }
                    {this.state.isClicked && !this.state.isDataExist &&
                        <div className="col-sm-12 continue">
                            <button type="submit" className="continue-button">Save</button>
                        </div>
                    }
                    {this.state.isClicked && this.state.isDataExist &&
                        <div className="col-sm-12 continue">
                            <button type="submit" className="continue-button">Update</button>
                        </div>
                    }
                </form>
                <ToastContainer hideProgressBar={false}></ToastContainer>
            </div>
        )
    }
}