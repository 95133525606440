import React, { Component } from 'react'
import './selectMuppStyle.css'
import Header from '../../Functional-component/Header/Header'
import PathData from '../data-components/PathData'
import { postSelectMUPP, getMupp } from '../../../constants/api-config'
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

class SelectMupp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MUPPdata: '',
            pathdata: '',
            check: false
        }
        this.next = this.next.bind(this)
    }

    async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        // let prevData = localStorage.getItem("muppdata")
        let serive_id = localStorage.getItem('id')
        let token = localStorage.getItem('token')

        let getMuppURL = getMupp + "?service=" + serive_id;


        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(getMuppURL, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.setState({ MUPPdata: result.response })
            })
    }

    pathValues = () => {
        if (this.state.MUPPdata.length !== 0) {
            return this.state.MUPPdata.map(item =>
                <PathData path={item.path} checked={item.select_for_ratings} click={this.go.bind(this, item)} />
            )
        }
    }

    go = (item) => {
        this.state.MUPPdata.forEach((element, index) => {
            if (element.id === item.id) {
                if (this.state.MUPPdata[index].select_for_ratings) {
                    this.state.MUPPdata[index].select_for_ratings = !this.state.MUPPdata[index].select_for_ratings
                } else {
                    this.state.MUPPdata[index].select_for_ratings = true
                }
            }
        });
    }

    next = () => {
        this.postSelectedMupp()
    }

    postSelectedMupp = () => {
        let token = localStorage.getItem('token')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "mupps": this.state.MUPPdata
            })
        }
        fetch(postSelectMUPP, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.success === 'true') {
                    this.notifySuccess("MUPP selection saved")
                    localStorage.setItem("muppdata", result)
                }
            })
    }

    notifySuccess = (item) => toast.success(item, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/mupprating') }
    });

    notifyWarning = (item) => toast.warning(item, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    render() {
        return (
            <div className="col-sm-12 padding-0">
                <Header header="Select MUPP" />
                {this.pathValues()}
                <div className="col-sm-12" style={{ textAlign: "right" }}>
                    <button onClick={this.next}>Save</button>
                </div>
                <ToastContainer hideProgressBar={false}></ToastContainer>
            </div>
        )
    }
}

export default withRouter(SelectMupp)
