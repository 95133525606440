/*** Add Projects table */
import React, { Component } from 'react'
import './mupp-picos-project.css';
import { project, updateProjects, EditProjecttitle, DeleteProjecttitle } from '../../constants/api-config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Functional-component/Header/Header'
import { push } from 'draft-js/lib/EditorState';
import Delete from "../../assests/delete.jpg";
import { useState } from 'react';
import { onDelete } from 'draft-js/lib/RichTextEditorUtil';

export default class MuppPicosProject extends Component {
    constructor(props) {
        super(props);
        this.row = "";
        this.value = localStorage.getItem('token');
        let search = props.location.search;
        let params = new URLSearchParams(search);
        this.projectData = props.location.state ? props.location.state.data : 'No Data Found';
        this.muppId = params.get('muppId');
        if (this.muppId === null) {
            this.props.history.push('/')
        }
        this.state = {
            results: [],
            title: "",
            stateProjects: [],
            rowtobedelted: [],
            editTitle: { status: false, title: "", rank: 0, project_id: 0 },

        }
        // let myModalEl = null;


    }



    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/work_mupp_picos') }
    });

    notifyRankupdate = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,

    });

    notifySuccessAdd = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
        // onClose : () => {this.props.history.push('/mupp_picos_project')}
    });

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        fetch(project + "?mupp=" + this.muppId, {
            "method": "GET",
            "headers": { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                let isData = Object.keys(response).length === 0
                console.log(isData)
                if (isData) {

                }
                else {
                    this.setState({ results: response.projects });
                    this.state.results.map((value, i) => {
                        if (value.important) return document.getElementById('isProjectImportant' + i).checked = true;
                        else return document.getElementById('isProjectImportant' + i).checked = false;
                    })

                }

            })

            .catch(err => {
                this.notifyWarning("Something Went Wrong");
            });

        // this.myModalEl = document.getElementById('myModal')
        // this.myModalEl.addEventListener('hidden.bs.modal', function (event) {
        //     // do something...
        //     console.log("OOdles mahaan")
        // })
    }


    // componentDidUpdate = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth"
    //     });

    //     fetch(project + "?mupp=" + this.muppId, {
    //         "method": "GET",
    //         "headers": { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             console.log(response)
    //             let isData = Object.keys(response).length === 0
    //             console.log(isData)
    //             if (isData) {

    //             }

    //         })

    //         .catch(err => {
    //             this.notifyWarning("Something Went Wrong");
    //         });

    //     // let x = setInterval(() => {
    //     //     const classes = document.getElementById("myModal")?.className;
    //     //     if(!classes.includes(" show")){
    //     //        this.resetEditProjectState();
    //     //     }
    //     // }, 1000)

    // }



    onSubmit = (event) => {
        event.preventDefault();
        var data = {
            mupp: parseInt(this.muppId),
            title: this.state.title,
            important: false
        }
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
            body: JSON.stringify(data)
        }
        fetch(project, requestOptions)
            .then(res => res.json())
            .then((response) => {
                this.componentDidMount();
                // this.notifySuccess("Updated Successfully")
                // this.onfinalUpdate(event)
                this.notifySuccessAdd("Submitted Successfully");
            })
            .catch((error) => {
                this.notifyWarning("Something Went Wrong");
            });
    }

    onEdit = (event) => {
        var data = {
            projects: this.state.stateProjects
        }
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
            body: JSON.stringify(data)
        }
        fetch(EditProjecttitle, requestOptions)
            .then(res => res.json())
            .then((response) => {
                // this.notifySuccess("Updated Successfully")
            })
            .catch((error) => {
                this.notifyWarning("Something Went Wrong");
            });
        // localStorage.setItem('defaultPosition', { valueX: e.target.parentNode.after(this.row) , valueY : e.target.parentNode.before(this.row) });
    }

    resetEditProjectState = () => {
        const temp = document.getElementById(
            "isdeleteImport" + this.state.editTitle.rank
        )
        temp && (temp.checked = false);
        this.setState({ editTitle: { status: false, title: "", rank: 0, project_id: 0 } })
        this.setState({ title: "" });
    }


    onDeleteProjectId = (event) => {

        event.preventDefault();
        var data = {
            project_id: this.state.editTitle.project_id,
        }
        var requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
            body: JSON.stringify(data)
        }
        fetch(DeleteProjecttitle, requestOptions)
            .then(res => res.json())
            .then((response) => {
                let delarr = []
                let flag = false

                for (let item of this.state.results) {
                    if (item.id !== data.project_id) {
                        if (flag) {
                            item.rank = item.rank - 1
                        }
                        delarr.push(item)
                    }
                    else {
                        flag = true
                    }
                }
                this.setState({ results: delarr })
                var data2 = {
                    "projects": delarr
                }
                var requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
                    body: JSON.stringify(data2)
                }
                fetch(updateProjects, requestOptions)
                    .then(res => res.json())
                    .then((response) => {
                        //  this.componentDidMount()
                        // this.notifySuccess("Updated Successfully")
                    })
                    .catch((error) => {
                        console.log(error)
                        this.notifyWarning("Something Went Wrong");
                    });

                // this.componentDidMount();
                // this.notifySuccessAdd("Submitted Successfully");
            })
            .catch((error) => {
                this.notifyWarning("Something Went Wrong");
            });
    }


    onrankUpdate = (event) => {
        event.preventDefault();
        if (this.state.stateProjects && this.state.stateProjects.length > 0) {
            var data = {
                "projects": this.state.stateProjects
            }
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
                body: JSON.stringify(data)
            }
            fetch(updateProjects, requestOptions)
                .then(res => res.json())
                .then((response) => {
                    this.componentDidMount()
                    this.notifySuccess("Updated Successfully")
                })
                .catch((error) => {
                    this.notifyWarning("Something Went Wrong");
                });
        } else {
            this.notifySuccess("Updated Successfully")
            // this.props.history.push('/work_mupp_picos')
        }
    }


    onUpdate = (event) => {
        event.preventDefault();
        if (this.state.stateProjects && this.state.stateProjects.length > 0) {
            var data = {
                "projects": this.state.stateProjects
            }
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
                body: JSON.stringify(data)
            }
            fetch(updateProjects, requestOptions)
                .then(res => res.json())
                .then((response) => {
                    this.componentDidMount()
                    this.notifySuccess("Updated Successfully")
                })
                .catch((error) => {
                    this.notifyWarning("Something Went Wrong");
                });
        } else {
            this.notifySuccess("Updated Successfully")
            this.props.history.push('/work_mupp_picos')
        }
    }

    onUpdateservice = (event) => {

        if (this.state.stateProjects && this.state.stateProjects.length > 0) {
            var data = {
                "projects": this.state.stateProjects
            }
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
                body: JSON.stringify(data)
            }
            fetch(updateProjects, requestOptions)
                .then(res => res.json())
                .then((response) => {
                    this.componentDidMount()
                    // this.notifySuccess("Updated Successfully")
                })
                .catch((error) => {
                    this.notifyWarning("Something Went Wrong");
                });
        } else {
            this.notifySuccess("Updated Successfully")
            this.props.history.push('/work_mupp_picos')
        }
    }

    start = (event) => {
        this.row = event.target;
    }



    // dragover = (e, data) => {
    //     e.preventDefault();
    //     console.log("DATA",data);
    //     let children = Array.from(e.target.parentNode.parentNode.children);
    //     if (children.indexOf(e.target.parentNode) > children.indexOf(this.row)) {
    //         e.target.parentNode.after(this.row);
    //     } else {
    //         e.target.parentNode.before(this.row);
    //     }
    //     this.state.stateProjects = this.state.results.map((value, i) => {
    //         if (value.id === data.id && value.rank !== children.indexOf(this.row) + 1) {
    //             value.rank = children.indexOf(this.row) + 1;
    //         }
    //         return value;
    //     })
    // }

    // eledelet = (e,data) ={

    //     Object.keys(indexOf())

    // }


    dragover = (e, data) => {
        e.preventDefault();
        // console.log("DATA",data);
        let children = Array.from(e.target.parentNode.parentNode.children);
        if (children.indexOf(e.target.parentNode) > children.indexOf(this.row)) {
            e.target.parentNode.after(this.row);
        } else {
            e.target.parentNode.before(this.row);
        }
        let finalData = this.state.results.map((value, item) => {
            if (value.id === data.id && value.rank !== children.indexOf(this.row) + 1) {
                value.rank = children.indexOf(this.row) + 1;
            }
            return value;

        })
        console.log("FINAL DATA", finalData)
        this.setState({
            stateProjects: finalData,
        })
        // this.state.stateProjects = this.state.results.map((value, i) => {
        //     if (value.id === data.id && value.rank !== children.indexOf(this.row) + 1) {
        //         value.rank = children.indexOf(this.row) + 1;
        //     }
        //     return value;
        // })
    }


    // dragover = (e, data) => {
    //     e.preventDefault();
    //     // console.log("DATA",data);
    //     let children = Array.from(e.target.parentNode.parentNode.children);
    //     const dragingItem = children.indexOf(this.row);
    //     const dragOverItem = children.indexOf(e.target.parentNode)
    //     if (dragOverItem > dragingItem) {
    //         e.target.parentNode.after(this.row);
    //         // this.row.parentNode.before(e.target)
    //     } else {
    //         e.target.parentNode.before(this.row);
    //         // this.row.parentNode.after(e.target)
    //     }
    //     let finalData = this.state.results.map((value, item) => {
    //         if (value.id === data.id && value.rank !== dragingItem + 1) {
    //             value.rank = dragingItem + 1;
    //         }

    //         return value;

    //     })
    //     console.log("FINAL DATA", finalData)
    //     this.setState({
    //         stateProjects: finalData,
    //     })

    // }

    dragStop = (e) => {
        e.preventDefault()
        if (this.state.stateProjects && this.state.stateProjects.length > 0) {
            var data = {
                "projects": this.state.stateProjects
            }
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
                body: JSON.stringify(data)
            }
            fetch(updateProjects, requestOptions)
                .then(res => res.json())
                .then((response) => {
                    // this.componentDidMount();
                    // this.notifyRankupdate("Updated Rank Successfully")
                })
                .catch((error) => {
                    this.notifyWarning("Something Went Wrong");
                });
            // localStorage.setItem('defaultPosition', { valueX: e.target.parentNode.after(this.row) , valueY : e.target.parentNode.before(this.row) });
        }
    }




    // dragover = (e, data) => {
    //     e.preventDefault();
    //     console.log("DATA", data);

    //     let children = Array.from(e.target.parentNode.parentNode.children);
    //     if (children.indexOf(e.target.parentNode) > children.indexOf(this.row)) {
    //         e.target.parentNode.after(this.row);
    //     } else {
    //         e.target.parentNode.before(this.row);
    //     }
    //     console.log("CHILDREN", children.indexOf(this.row) + 1);

    //     let finalData = this.state.categories.map((item, index) => {
    //         let categoryMupp = item.mupps.map((mupp, muppIndex) => {
    //             if (mupp.id === data.id && mupp.rank_for_mupps !== children.indexOf(this.row) + 1) {
    //                 mupp.rank_for_mupps = children.indexOf(this.row) + 1;
    //             }
    //             return mupp;
    //         })

    //         return { ...item, mupps: categoryMupp }
    //     })
    //     this.setState({
    //         categories: finalData,
    //     })
    // }


    checkcur = (e) => {
        if (e.i === "Enter" || e.i === "Escape") {
            e.target.blur();
        }
    }


    handelCheckBox = (e, data) => {
        this.setState({
            stateProjects: this.state.results.map((value, i) => {
                if (value.id === data.id) {
                    value.important = e.target.checked;
                    if (e.target.checked) {
                        document.getElementById(
                            "isProjectImportant" + i
                        ).checked = true;
                    } else {
                        document.getElementById(
                            "isProjectImportant" + i
                        ).checked = false;
                    }
                }
                return value;
            }),
        });
        // this.state.stateProjects = this.state.results.map((value, i) => {
        //     if (value.id === data.id) {
        //         value.important = e.target.checked;
        //         if (e.target.checked) {
        //             document.getElementById('isProjectImportant' + i).checked = true;
        //         } else {
        //             document.getElementById('isProjectImportant' + i).checked = false;
        //         }
        //     }
        //     return value;
        // })
    }

    openAddProjectModal = () => {
        return (
            <div className="add-project-btn-div">
                <button type="button" className="picos-button" data-toggle="modal" data-target="#myModal" style={{ marginRight: "1em" }} >Add Project</button>
                <button type="button" className="picos-button" onClick={this.onUpdate}>Update Projects</button>
                <div className="modal fade" id="myModal" role="dialog" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.state.editTitle.status ? "Edit" : "Add"} Project</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => {
                                    this.resetEditProjectState();
                                }}>&times;</button>
                            </div>
                            <div className="modal-body modal-div">
                                <label className="col-sm-2 form-group">Title</label>
                                <textarea
                                    type="text"
                                    name="title"
                                    value={this.state.title}
                                    className="form-control col-sm-10"
                                    onChange={(data) => {
                                        this.setState({ title: data.target.value })
                                        if (this.state.editTitle.status) {

                                            let finalData = this.state.results.map((value, item) => {
                                                if (value.rank === this.state.editTitle.rank) {
                                                    value.title = data.target.value;
                                                }
                                                return value;
                                            })
                                            this.setState({
                                                stateProjects: finalData,
                                            })
                                        }
                                    }}
                                    placeholder="Enter Project Title"
                                    required
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="continue-button" data-dismiss="modal" onClick={(e) => {
                                    this.state.editTitle.status ? this.onEdit(e) : this.onSubmit(e);
                                    this.resetEditProjectState();
                                }}>Save</button>
                                <button type="submit" className="continue-button" data-dismiss="modal" onClick={(e) => {
                                    this.resetEditProjectState();
                                }}>Cancel</button>
                                {this.state.editTitle.status && <img
                                    onClick={(e) => {
                                        this.componentDidMount();
                                        this.onDeleteProjectId(e);
                                        this.resetEditProjectState();
                                    }}
                                    data-dismiss="modal"
                                    src={Delete}
                                    style={{ cursor: "pointer", marginLeft: "15px", marginTop: "-10px" }}
                                    height="25px"
                                    width="25px"
                                    alt=""
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        // console.log("results...", this.state.results)
        // let updatedOrder = [...this.state.results].sort((a,b) => a.rank - b.rank)
        // console.log("updatedOrder", updatedOrder)
        return (
            <div className="mupp-picos-project">
                <Header header="Projects" />
                <div className="col-sm-12 table-responsive">
                    <table className="table table-bordered outer-table">
                        <thead>
                            <tr>
                                <th style={{ width: "40%" }}>Mupp</th>
                                <th style={{ width: "20%" }}>Picos</th>
                                <th style={{ width: "40%" }}>Projects(Move Projects up and down depending on priority)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={{ maxHeight: "70vh", overflowY: 'auto' }}>
                                        Path - {this.projectData.mupp_path}
                                    </div>
                                </td>
                                <td>
                                    <div style={{ maxHeight: "70vh", overflowY: 'auto' }}>
                                        Path - {this.projectData.picos_heading}
                                        <br />
                                        Discription - {this.projectData.picos_description}
                                    </div>
                                </td>
                                <td className="padd-0 project-td">
                                    <table className="table table-bordered title-table">
                                        <div style={{ maxHeight: "70vh", overflowY: 'auto' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5%" }}> </th>
                                                    <th style={{ width: "80%" }}>Title</th>
                                                    <th style={{ width: "10%" }}>Rank</th>
                                                    <th style={{ width: "10%" }}>Priority</th>
                                                </tr>
                                            </thead>
                                            <tbody onDrop={(e) => this.dragStop(e)}>
                                                {this.state.results.map((data, i) => (
                                                    <tr key={data.id} draggable='true' onDragStart={this.start} onDragOver={(e) => this.dragover(e, data)} >
                                                        <td>
                                                            <input type="checkbox" name='isdeleteImportant' data-toggle="modal" data-target="#myModal" onClick={(e) => {
                                                                this.state.editTitle.status = true;
                                                                this.setState({ title: data.title });
                                                                this.state.editTitle.rank = data.rank
                                                                this.state.editTitle.project_id = data.id
                                                            }} id={'isdeleteImport' + data.rank} className="is-delete-checkbox" />
                                                        </td>
                                                        <td >{data.title}</td>
                                                        <td >{data.rank}</td>
                                                        <td>
                                                            <input type="checkbox" onChange={(e) => this.handelCheckBox(e, data)} name="isProjectImportant" id={'isProjectImportant' + i} className="is-project-checkbox" />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </div>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {this.openAddProjectModal()}
                </div>
                <ToastContainer hideProgressBar={false}></ToastContainer>
            </div>
        )
    }
}