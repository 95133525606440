import React, { Component } from "react";
import AppRoute from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

class App extends Component {
  // componentDidMount() {
  //   if (window.location.href !== "/daily_journal") {
  //     localStorage.setItem("passAuth", false);
  //   }
  // }
  render() {
    return (
      <div className="container-fluid">
        <Router>
          <AppRoute></AppRoute>
        </Router>
      </div>
    );
  }
}

export default App;
