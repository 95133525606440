import React from 'react'
import './Itemdata.css'

export default function Itemdata(props) {
    return (
        <div className="ItemContainer" >
            <p onClick={props.onsubmit}>{props.name}</p>
        </div>

    )
}
