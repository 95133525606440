import React, { useState, useEffect } from "react";
import { getAllMupps, moveTask, getProject, getMuppCategories } from "../../constants/api-config";

const MoveTask = (props) => {
  const [taskId, setTaskId] = useState(props.taskID);
  const [taskType, setTaskType] = useState(props.taskType);
  const [taskDate, setTaskDate] = useState(props.taskDate);
  const [taskName, setTaskName] = useState(props.taskName);
  const [muppCategoryId, setMuppCategoryId] = useState(props.moveMuppCategoryId)
  const [categoryArr, setCategoryArr] = useState([]);
  const [allMupp, setAllMupp] = useState();
  const [muppList, setMuppList] = useState([]);
  const [muppItem, setMuppItem] = useState(props.muppID);
  const [projectList, setProjectList] = useState([]);
  const [project_Id, setProject_Id] = useState(props.projectID);
  const [minDate, setMinDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const getAllMuppApiCall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getAllMupps, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {

          if (Object.keys(result).length > 0 && props.moveMuppCategoryId) {

            setAllMupp(result);
            const muppData = result.filter(
              (item) => item.category_id === props.moveMuppCategoryId
            );
            console.log("muppData", muppData, props.moveMuppCategoryId);
            if (muppData[0].mupps === undefined || muppData[0].mupps.length === 0) {
              setMuppList([]);
              setProjectList([]);
              setProject_Id(0);
            } else {
              let muppArray = muppData[0].mupps.map((item) => {
                return { value: item.id, label: item.path };
              });
              setMuppList(muppArray);
              getProjectApiCallOnLoad(props.muppID)

            }
          }

        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };
  const getMuppCategoryApiCall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      }
    };
    fetch(getMuppCategories, requestOptions)
      .then((res) => res.json()).then(
        (result) => {
          console.log("getMuppCategories result ", result);
          if (result) {
            if (Object.keys(result.category).length === 0 && result.category.constructor === Object) {
              setCategoryArr([]);
            } else {
              let categoryArray = result.category.map(item => {
                return { value: item.category_id, name: item.name };
              });
              setCategoryArr(categoryArray);
            }
          }


        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );


  };

  useEffect(() => {
    const setPropsToState = async () => {
      setTaskType(props.taskType);
      setTaskDate(props.taskDate);
      setTaskName(props.taskName);
      setTaskId(props.taskID);
      setProject_Id(props.projectID)
      setMuppCategoryId(props.moveMuppCategoryId);
      setMuppItem(props.muppID)
      console.log("props.taskDate", props.taskDate)
    }
    console.log("props changed props.moveMuppCategoryId", props.moveMuppCategoryId)
    setPropsToState().then(() => {
      getMuppCategoryApiCall();
      getAllMuppApiCall();
    });

  }, [props]);

  // useEffect(() => {


  //   getAllMuppApiCall();
  // }, []);

  // useEffect(() => {
  //   setTaskType(props.taskType);
  //   setTaskDate(props.taskDate);
  //   setTaskName(props.taskName);
  //   setTaskId(props.taskID);
  //   setProject_Id(props.projectID)
  //   setMuppCategoryId(props.moveMuppCategoryId);
  //   getAllMuppApiCall();

  // }, []);

  // useEffect(() => {
  //   console.log("taskType", props.taskType);
  //   console.log("taskName", props.taskName);
  //   console.log("taskDate", props.taskDate);
  //   console.log("taskID", props.taskID);
  //   console.log("moveMuppCategoryId", props.moveMuppCategoryId);


  //   setTaskType(props.taskType);
  //   setTaskDate(props.taskDate);
  //   setTaskName(props.taskName);
  //   setTaskId(props.taskID);
  //   setProject_Id(props.projectID)
  //   setMuppCategoryId(props.moveMuppCategoryId);

  //   if (allMupp) muppDropdownData(props.moveMuppCategoryId);
  //   // if (muppList) {
  //   //   setMuppItem(props.muppID);
  //   //   getProjectApiCall(props.muppID);
  //   // }

  // }, [props]);

  const muppDropdownData = (categoryId) => {
    if (categoryId && categoryId > 0) {


      const muppData = allMupp.filter(
        (item) => item.category_id === parseInt(categoryId)
      );
      if (muppData[0].mupps === undefined || muppData[0].mupps.length === 0) {
        setMuppList([]);
        setProjectList([]);
        setProject_Id(0);
      } else {
        let muppArray = muppData[0].mupps.map((item) => {
          return { value: item.id, label: item.path };
        });
        setMuppList(muppArray);
        setMuppItem(muppArray[0].value);
        // console.log("muppArray ", muppArray);
        if (muppArray[0].value) {
          // console.log("calling mupp project with id  ", muppArray[0].value);
          getProjectApiCall(muppArray[0].value);
        }
      }
    }
  };

  const getProjectApiCall = (muppId) => {
    let token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(`${getProject}?mupp=${muppId}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (
            result.projects === undefined ||
            Object.keys(result.projects).length === 0
          ) {
            setProjectList([]);

            setProject_Id(0);
          } else {
            let projArray = result.projects.map((item) => {
              return { value: item.id, label: item.title };
            });

            setProjectList(projArray);
            setProject_Id(projArray[0].value);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };
  const getProjectApiCallOnLoad = (muppId) => {
    let token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(`${getProject}?mupp=${muppId}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (
            result.projects === undefined ||
            Object.keys(result.projects).length === 0
          ) {
            setProjectList([]);
            setProject_Id(0);
          } else {
            let projArray = result.projects.map((item) => {
              return { value: item.id, label: item.title };
            });
            setProjectList(projArray);
            console.log("projArray", projArray);
            console.log("project call on load", props.projectID);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  const postMoveToProjectApiCall = () => {
    let token = localStorage.getItem("token");
    const payload = {
      task_type: taskType,
      task_id: taskId,
      project: project_Id,
      name: taskName,
      mupp: parseInt(muppItem),
      date: taskDate === "" ? "" : taskDate,


    };

    // console.log("payload", payload);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },

      body: JSON.stringify(payload),
    };
    fetch(moveTask, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("res3", result);
          if (result.success === "true") {
            props.notifySuccess(result.message);
            props.closePanel();
            props.fetchTaskData();
          } else {
            props.notifyWarning(result.message);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };


  const closeAndReset = () => {

    props.closePanel();
  }


  return (
    <div>
      <i
        className="fa fa-close close-icon"
        id="moveTaskCloseIcon"
        style={{ display: "none" }}
        onClick={closeAndReset}
        aria-hidden="true"
      ></i>
      <ul className="render-task">
        <div
          className="task-accordion"
          id="moveTaskRightSlidePanel"
          style={{ display: "none" }}
        >
          <h4>Move task from Inbox</h4>
          <div className="form-group">
            <textarea
              type="text"
              placeholder="Add Task"
              id="addTaskTextArea"
              name="Title"
              className="form-control"
              onChange={(e) => setTaskName(e.target.value)}
              required
              value={taskName}
              style={{ height: "8vh" }}
            />
          </div>

          <div className="form-group">
            <p>Select MUPP cateogry</p>
            <select
              name="task-type"
              onChange={(e) => {
                // console.log("Onchange called", e.target.value);
                setMuppCategoryId(e.target.value)
                muppDropdownData(e.target.value);
              }}
              className="form-control"
              defaultValue={muppCategoryId}
            >

              {categoryArr
                .map((item) => {
                  if (muppCategoryId === item.value) {
                    return (
                      <option selected key={item.value} value={item.value}>{item.name}</option>
                    );
                  }

                  return <option value={item.value}>{item.name}</option>;
                })}
            </select>
          </div>
          <div className="form-group">
            <p>Select Mupp</p>
            <select
              onChange={(e) => {
                // console.log("e.target on mupp select", e.target.value);
                setMuppItem(e.target.value);
                getProjectApiCall(e.target.value);
              }}
              name="task-type"
              className="form-control"
              defaultValue={muppItem}
            >

              {muppList

                .map((item) => {

                  if (item.value === muppItem) {
                    return <option selected key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  }
                  return <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                }

                )}
            </select>
          </div>
          <div className="form-group">
            <p>Select Project</p>
            <select
              onChange={(e) => {

                setProject_Id(e.target.value);
              }}
              name="task-type"
              className="form-control"
              defaultValue={project_Id}
            >

              {projectList
                .map((item) => {
                  if (project_Id === item.value) {
                    return <option selected key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  }
                  return <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                }

                )}
            </select>
          </div>
          <div className="form-group">
            <p>Select Type</p>
            <select
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
              name="task-type"
              className="form-control"
            >
              <option value="THIS_WEEK">THIS_WEEK</option>
              <option value="LATER">LATER</option>
              <option value="AWAITING">AWAITING</option>
            </select>
          </div>

          <div className="form-group">
            <p>Select Date</p>
            <input
              type="date"
              defaultValue={taskDate}
              min={minDate}
              value={taskDate}
              onChange={(e) => setTaskDate(e.target.value)}
              id="editTaskDateTextArea"
            />
          </div>
          <div className="col-sm-12 add-mupps-button">
            <button onClick={() => postMoveToProjectApiCall()}>Save</button>
            <button
              onClick={() => {
                setTaskDate("");
                setMinDate(null);
              }}
            >
              Remove date
            </button>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default MoveTask;
