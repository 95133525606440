import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <div
      className={`paradigm__cardContainer ${props.className}`}
      onClick={props.onClick}
    >
      <img src={props.src} alt={props.alt} className="paradigm__card_img" />
      <h3>
        {props.children.length > 26
          ? `${props.children.slice(0, 26)} . .`
          : props.children}
      </h3>
      {props.content && <p>{props.content.slice(0, 100)+'. .'}</p>}
      {/* {props.downloadLink && (
        <a href={props.downloadLink} download>
          <button className="paradigm__card_button" type="button">
            Download
          </button>
        </a>
      )} */}
    </div>
  );
};

export default Card;
