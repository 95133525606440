import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import Forget from "./components/Forget/Forget";
import Signup from "./components/signup/Signup";
import Sign from "./components/SignIn/Sign";
import Verifyemail from "./components/verifyEmail/Verifyemail";
import Success from "./components/verifyEmail/Success";
import Landing from "./components/verifyEmail/Landing";
import Reset from "./components/Reset/Reset";
import "./App.css";
import ThisWeek from "./components/this-week/thisWeek";
import Home from "./components/Home/Home";
import SideBarComponent from "./components/Sidebar/sidebar.component";
import Dailyhabbit from "./components/daily-habbits/Dailyhabbit";
import Cards from "./components/Home/Cards";
import Inbox from "./components/Inbox/Inbox";
import Missionstatement from "./components/MissionStatement/Missionstatement";
import Paradigm from "./components/Page/paradigm1";
import Lists from "./components/Page/lists";
import PictureSuccess from "./components/picturesuccess/picture.success";
import PictureSuccessPicos from "./components/picturesuccess/picture.success.picos";
import Addmupp from "./components/self-discovery/add-mupp/Addmupp";
import Art from "./components/self-discovery/Art/Art";
import Continue from "./components/self-discovery/Continue/Continue";
import Mupprating from "./components/self-discovery/mupp-rating/Mupprating";
import SelectMupp from "./components/self-discovery/select-mupp/SelectMupp";
import Selflanding from "./components/self-discovery/self-landing/Selflanding";
import Selfpage1 from "./components/self-discovery/self-page/Selfpage1";
import Selfpage2 from "./components/self-discovery/self-page/Selfpage2";
import Testlink from "./components/self-discovery/TestLink/Testlink";
import Todo from "./components/Todos/Todo";
import Waiting from "./components/Waiting/Waiting";
import ProjectReview from "./components/workthatmattersmostflow/project.review.component";
import WorkMuppPicos from "./components/workthatmattersmostflow/work.mupp.picos.component";
import HomeMupps from "./components/homemupps/home-mupps.component";
import Later from "./components/Later/Later";
import Monthlyreminder from "./components/monthly-reminder/Monthlyreminder";
import Page4 from "./components/Page/Page4";
import Science from "./components/self-discovery/science/Science";
import MuppPicosProject from "./components/workthatmattersmostflow/mupp.picos.projects.component";
import Calendar from "./components/Calendar/Calendar";
import Picos from "./components/Add-picos/picos";
import Privacy from "./components/privacy/Privacy";
import MuppFinalization from "./components/MuppFinalization/MuppFinalization";
import MuppOrder from "./components/self-discovery/MuppOrder/MuppOrder";
import TermsConditions from "./components/Terms-Conditions/TermsConditions";
import Search from "./components/Search/Search";
import LearningLog from "./components/LearningLog/Log";
import DailyJournal from "./components/DailyJournals/Journal";
import PageNotFound from "./components/PageNotFound";

class AppRoute extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
  }

  renderTemplates = () => {
    return (
      <div className="row cards-container ">
        <div
          className="col-lg-2 col-md-2 col-sm-2 col-xs-2"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <SideBarComponent />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="rightContainer">
            <Switch>
              <Route exact path="/">
                <Redirect to="/cards" />
              </Route>
              <Route path="/this-week" component={ThisWeek} />
              <Route path="/Home" component={Home} />
              <Route path="/selfpage1" component={Selfpage1} />
              <Route path="/selfpage2" component={Selfpage2} />
              <Route path="/TestLink" component={Testlink} />
              <Route path="/Art" component={Art} />
              <Route path="/continue" component={Continue} />
              <Route path="/missionStatement" component={Missionstatement} />
              <Route path="/picture_success" component={PictureSuccess} />
              <Route
                path="/picture_success_picos"
                component={PictureSuccessPicos}
              />
              <Route path="/work_mupp_picos" component={WorkMuppPicos} />
              <Route path="/mupp_picos_project" component={MuppPicosProject} />
              <Route path="/project_review" component={ProjectReview} />
              <Route path="/Home" component={Home} />
              <Route path="/addmupp" component={Addmupp} />
              <Route path="/selectmupp" component={SelectMupp} />
              <Route path="/mupprating" component={Mupprating} />
              <Route path="/mupps" component={HomeMupps} />
              <Route path="/cards" component={Cards} />
              <Route path="/video/:screen" children={<Lists />} />
              <Route path="/video" component={Paradigm} />
              <Route path="/daily-habbit" component={Dailyhabbit} />
              <Route path="/inbox" component={Inbox} />
              <Route path="/selfLanding" component={Selflanding} />
              <Route path="/todos" component={Todo} />
              <Route path="/Waiting" component={Waiting} />
              <Route path="/Later" component={Later} />
              <Route path="/monthly-reminder" component={Monthlyreminder} />
              <Route path="/page4" component={Page4} />
              <Route path="/science" component={Science} />
              <Route path="/calendar" component={Calendar} />
              <Route path="/picos" component={Picos} />
              <Route path="/MuppFinalization" component={MuppFinalization} />
              <Route path="/MuppOrder" component={MuppOrder} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/termsConditions" component={TermsConditions} />
              <Route path="/search" component={Search} />
              <Route path="/learning_log" component={LearningLog} />
              <Route path="/daily_journal" component={DailyJournal} />
              {/* <Route path="*" component={PageNotFound} /> */}
            </Switch>
          </div>
        </div>
      </div>
    );
  };

  renderAuthorisationTemplate = () => {
    return (
      <div>
        <div className="col-sm-12 cards-container">
          <Router>
            <Route path="/" exact component={Signup} />
            <Route path="/sign" component={Sign} />
            <Route path="/resetPassword" component={Forget} />
            <Route path="/verifymail" component={Verifyemail} />
            <Route path="/success" component={Success} />
            <Route path="/reset" component={Reset} />
            <Route path="/landing" component={Landing} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/termsConditions" component={TermsConditions} />
          </Router>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="col-sm-12 app-route-module">
        {this.token && this.renderTemplates()}
        {!this.token && this.renderAuthorisationTemplate()}
      </div>
    );
  }
}

export default AppRoute;
