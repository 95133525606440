import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assests/logo.png'
import './signup.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerUser } from "../../constants/api-config";
// import * as yup from 'yup';


// eslint-disable-next-line no-useless-escape
const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const nameValidator = /^([^0-9]*)$/


export default class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Fname: "",
            Lname: "",
            Email: "",
            Password: "",
            Repassword: "",
            Fnameerror: '',
            Lnameerror: '',
            emailerror: '',
            passworderror: '',
            repassworderror: '',
            isFormSubmitted: false


        }
        this.handleFname = this.handleFname.bind(this);
        this.handleLname = this.handleLname.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleRepassword = this.handleRepassword.bind(this);
        this.submit = this.submit.bind(this);
    }


    validateFirstName = () => {
        let Fnameerror = "";
        const value = this.state.Fname;
        if (value.trim() === "") Fnameerror = "First Name is required";
        else if (!nameValidator.test(value))
            Fnameerror = "Name is not valid"
        this.setState({
            Fnameerror
        });
        return Fnameerror === "";
    }

    validateLastName() {
        let Lnameerror = "";
        const value = this.state.Lname;
        if (value.trim() === "") Lnameerror = "Last Name is required";
        else if (!nameValidator.test(value))
            Lnameerror = "Name is not valid"

        this.setState({
            Lnameerror
        });
        return Lnameerror === "";
    }

    validateEmailAddress() {
        let emailerror = "";
        const value = this.state.Email;
        if (value.trim() === "") emailerror = "Email Address is required";
        else if (!emailValidator.test(value))
            emailerror = "Email is not valid";

        this.setState({
            emailerror
        });
        return emailerror === "";
    }

    validatePassword() {
        let passworderror = "";
        const value = this.state.Password;
        if (value.trim() === "") passworderror = "Password is required";


        this.setState({
            passworderror
        });
        return passworderror === "";
    }

    validatePasswordConfirmation() {
        let repassworderror = "";
        if (this.state.Password !== this.state.Repassword)
            repassworderror = "Password does not match Confirmation";

        this.setState({
            repassworderror
        });
        return repassworderror === "";
    }

    handleFname = async function (e) {
        console.log(e.target.value)
        await this.setState({ Fname: e.target.value })
    }

    handleLname = async function (e) {
        await this.setState({ Lname: e.target.value })

    }

    handleEmail = async function (e) {
        await this.setState({ Email: e.target.value })
    }

    handlePassword = async function (e) {
        await this.setState({ Password: e.target.value })
    }

    handleRepassword = async function (e) {
        await this.setState({ Repassword: e.target.value })
    }

    validateField = (name) => {
        console.log("validate field")
        let isValid = false;

        if (name === "firstName") isValid = this.validateFirstName();
        else if (name === "lastName") isValid = this.validateLastName();
        else if (name === "email") isValid = this.validateEmailAddress();
        else if (name === "password") isValid = this.validatePassword();
        else if (name === "repassword")
            isValid = this.validatePasswordConfirmation();
        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();
        console.log("onsubmit")
        let formFields = [
            "firstName",
            "lastName",
            "email",
            "password",
            "repassword"

        ];
        let isValid = true;
        formFields.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });

        if (isValid) this.setState({ isFormSubmitted: true });
        else this.setState({ isFormSubmitted: false });
        this.submit()

        return this.state.isFormSubmitted;


    }

    submit = async function () {
        if (this.state.isFormSubmitted) {
            console.log("inside submit")
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: this.state.Email,
                    first_name: this.state.Fname,
                    last_name: this.state.Lname,
                    password: this.state.Password
                })
            };
            fetch(registerUser, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("res", result)
                        if (result.message === 'verification email sent successfully') {
                            this.notifySuccess("Verification link is send to your e-mail")
                            this.reset()
                        } else {
                            let msg = JSON.stringify(result).toString();
                            msg = msg.split("[")[1].toString().split("]")[0];
                            msg = msg.trim().substring(1, msg.length - 1);
                            this.notifyFailed(msg)
                            this.reset()
                        }
                    },
                    (error) => {
                        this.notifyFailed("Registration Failed")
                    }
                )
        }
    }

    reset = () => {
        this.setState({ Fname: '' })
        this.setState({ Lname: '' })
        this.setState({ Email: '' })
        this.setState({ Password: '' })
        this.setState({ Repassword: '' })
    }

    notifySuccess = text => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifyFailed = text => toast.warning(text, {
        hideProgressBar: false,
    });

    render() {
        return (
            <div className="loginform">
                <form action="#">
                    <div className="signImgContainer">
                        <img src={logo} className="signImage" alt="" />
                    </div>
                    <input type="text" name="firstName" placeholder="First Name" value={this.state.Fname} onChange={this.handleFname} />
                    {this.state.Fnameerror && (
                        <div className="errorMsg">{this.state.Fnameerror}</div>
                    )}
                    <input type="text" name="lastName" placeholder="Last Name" value={this.state.Lname} onChange={this.handleLname} />
                    {this.state.Lnameerror && (
                        <div className="errorMsg">{this.state.Lnameerror}</div>
                    )}
                    <input type="email" name="email" placeholder="Email" value={this.state.Email} onChange={this.handleEmail} />
                    {this.state.emailerror && (
                        <div className="errorMsg">{this.state.emailerror}</div>
                    )}
                    <input type="password" name="password" placeholder="Password" value={this.state.Password} onChange={this.handlePassword} />
                    {this.state.passworderror && (
                        <div className="errorMsg">{this.state.passworderror}</div>
                    )}
                    <input type="password" name="repassword" placeholder="Confirm Password" value={this.state.Repassword} onChange={this.handleRepassword} />
                    {this.state.repassworderror && (
                        <div className="errorMsg">{this.state.repassworderror}</div>
                    )}
                    <button onClick={this.onSubmit} className="btn-signup">Sign Up</button>
                    <ToastContainer hideProgressBar={false}></ToastContainer>
                    <Link to="/sign">
                        <h6 className="user">Already a user ? Login</h6>
                    </Link>
                    <div>
                        <Link to="/Privacy">
                            <h6>Privacy and Policy</h6>
                        </Link>
                        <Link to="/TermsConditions">
                            <h6>Terms and Conditions</h6>
                    </Link>
                    </div>

                </form>
            </div>
        )
    }
}
