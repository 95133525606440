import axios from "axios";
import React, { useEffect, useState } from "react";
import "./paradigm.css";
import {
  articleList,
  pdfList,
  sessionList,
  videoList,
} from "../../constants/api-config";
import { SessionLists } from "./lists";
import DirectiveLoader from "../Functional-component/Loader/loader.component";
import { useHistory } from "react-router-dom";
import Card from "./Card";

import articleIMG from "../../assests/article.jpeg";
import pdfIMG from "../../assests/pdf_image.jpeg";
import videoIMG from "../../assests/video.jpeg";
import Header from "../Functional-component/Header/Header";
export default function Paradigm() {
  let serviceId = localStorage.getItem("id");
  let token = localStorage.getItem("token");
  const [sessionTitle, setSessionTitle] = useState("");
  const [sessions, setSessions] = useState({});
  const [showCards, setshowCards] = useState(false);
  const [sessionID, setsessionID] = useState(null);
  // const [articles, setarticles] = useState({});
  // const [videos, setvideos] = useState({});
  // const [pdfs, setpdfs] = useState({});
  const [loading, setloading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    paradigmShiftCardGet().catch((err) => console.log(err));
  }, []);
  const paradigmShiftCardGet = async () => {
    setloading(true);
    const { data } = await axios.get(`${sessionList}?service_id=${serviceId}`, {
      headers: { authorization: `JWT ${token}` },
    });
    console.log({ session: data });
    setSessions(data);
    setloading(false);
  };

  const articleHandler = async () => {
    try {
      setloading(true);
      const { data } = await axios.get(
        `${articleList}?session_id=${sessionID}`,
        {
          headers: { authorization: `JWT ${token}` },
        }
      );
      console.log({ articles: data });
      // setarticles(data);
      setloading(false);
      return history.push("/video/article", { articles: data, sessionTitle });
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };
  const pdfHandler = async () => {
    try {
      setloading(true);
      const { data } = await axios.get(`${pdfList}?session_id=${sessionID}`, {
        headers: { authorization: `JWT ${token}` },
      });
      console.log({ pdf: data });
      // setpdfs(data);
      setloading(false);
      history.push("/video/pdf", { pdfs: data, sessionTitle });
    } catch (err) {
      console.error(err.message);

      setloading(false);
    }
  };

  const videoHandler = async () => {
    try {
      setloading(true);
      const { data } = await axios.get(`${videoList}?session_id=${sessionID}`, {
        headers: { authorization: `JWT ${token}` },
      });
      console.log({ videos: data });

      // setvideos(data);
      setloading(false);
      history.push("/video/video-list", { videos: data, sessionTitle });
    } catch ({ response }) {
      console.log({ video: response.data });

      setloading(false);
    }
  };

  const SessionCards = () => {
    return (
      <>
        <Card src={articleIMG} onClick={articleHandler}>
          Articles
        </Card>
        <Card src={pdfIMG} onClick={pdfHandler}>
          PDFs
        </Card>
        <Card src={videoIMG} onClick={videoHandler}>
          Videos
        </Card>
      </>
    );
  };

  if (loading)
    return (
      <div style={{ marginTop: "25%", marginLeft: "47%" }}>
        <DirectiveLoader />
      </div>
    );

  return (
    <>
      <Header header={!showCards ? "Sessions" : sessionTitle} />
      <div className="paradigm_parentcontainer">
        <div className="paradigm_container">
          {/* {screen === "article" && (
          <div className="paradigm_video-container">
          {articles.response.map((item, i) => (
            <ArticleLists item={item} key={i} />
            ))}
            </div>
          )} */}
          {/* {screen === "session" && */}
          {!showCards ? (
            sessions.success &&
            sessions.response.map((item, i) => (
              <SessionLists
                item={item}
                key={i}
                videoHandler={videoHandler}
                articleHandler={articleHandler}
                pdfHandler={pdfHandler}
                showCardsHandler={setshowCards}
                sessionTitleHandler={setSessionTitle}
                sessionIDHandler={setsessionID}
              />
            ))
          ) : (
            // <div className="paradigm_container">
            <SessionCards />
            // </div>
          )}
          {/* {screen === "video" && (
          <div className="paradigm_video-container">
          {videos.response.map((item, i) => (
            <VideoLists item={item} key={i} />
            ))}
            </div>
            )}
            {screen === "pdf" && (
              <div className="paradigm_video-container">
              {pdfs.response.map((item, i) => (
                <PDFLists item={item} key={i} />
                ))}
                </div>
              )} */}
        </div>
      </div>
    </>
  );
}
