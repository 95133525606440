import React, { Component } from 'react'
import './picture-success.css';
import { Link } from 'react-router-dom'
import { mupp,getPos } from '../../constants/api-config';
import Header from '../Functional-component/Header/Header'

export default class PictureSuccess extends Component {
    constructor(props) {
        super(props);
        this.value = localStorage.getItem('token');
        this.id = localStorage.getItem('id');
        this.state = {
            results: []
        }
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        fetch(getPos, {
            "method": "GET",
            "headers": {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.value}`,
            },
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    results: response.response
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    addPicos(data) {
        localStorage.setItem("picosData", JSON.stringify(data))
    }

    render() {
        return (
            <div className="success-picture-page">
                <Header header="Picture Of Success" />
                <div className="col-sm-12 table-responsive" style={{ marginLeft: "10px", paddingLeft: "3px" }}>
                    <table className="table table table-bordered" >
                        <thead>
                            <tr>
                                <th style={{width:"5%"}}> S.No</th>
                                <th style={{width:"60%"}}>Mupp</th>
                                <th style={{width:"15%"}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                if (this.state.results && this.state.results.length > 0) {
                                    return (
                                        this.state.results.map((data, i) => (
                                            <tr key={i}>
                                                <td>{i == 0 ? '1' : i + 1}</td>
                                                <td>
                                                <div style={{ minHeight: '15vh', maxHeight: "15vh" ,overflowY:'auto'}}>
                                                    {data.path}
                                                </div>
                                                </td>
                                                <td>
                                                    <Link to="picture_success_picos" className="picos-button" onClick={() => this.addPicos(data)}>Picos</Link>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                } else {
                                    return (
                                        <tr>
                                            <td colSpan="3" className="no-result-found">No Results Found</td>
                                        </tr>
                                    )
                                }
                            })()}
                        </tbody>
                    </table>
                    <div style={{ textAlign: "end" }}>
                    <button onClick={() => {this.props.history.push('/page4')}}>Back</button>
                </div>
                </div>
            </div>
        )
    }
}