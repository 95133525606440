import React, { Component } from 'react'
import Success from './Success'
import Failed from './Failed'
import { verifyEmail } from '../../constants/api-config'

export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            loading:true
        }
    }

    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        console.log("render")
        console.log(window.location.href)
        let url = window.location.href
        let arr = url.toString()
        console.log("1", arr)
        let arr1 = arr.split("/")
        console.log("2", arr1[4])
        let value = arr1[4]
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: value,

            })
        }
        fetch(verifyEmail, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("res", result.success)
                    this.setState({ token: result.success })
                },

                (error) => {
                    console.log("error", error)
                })
    }

    render() {
        return (
            <div >
                {this.state.token && <Success />}
                {!this.state.token && <Failed />}

            </div>
        )
    }
}
