import React, { Component } from 'react'
import './addMuppStyle.css'
import ContentEditable from 'react-contenteditable'
import { postMupp, getMuppCategories, updateMupp, getMupp , mupp} from '../../../constants/api-config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
class Addmupp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textContent1: '',
            textContent2: '',
            textContent3: '',
            textContent4: '',
            textContent5: '',
            userToken: '',
            categoryId: '',
            MUPP: '',
            isHidden: false,
            counter: 0,
            savedClick: false,
            muppLength: '',
            muppId: '',
            prevPath: '',
            prevDescription: '',
            prevRoughText: '',
            isLast: false,
            isAddMupp: false,
            categoryType: '',
            categoryArray: [],
            muppCategory: '',
            rankForMupp: '',
            selectForRating: ''

        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onDelete = this.onDelete.bind(this)
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let right = localStorage.getItem('left')
        let left = localStorage.getItem('right')
        let token = localStorage.getItem('token')
        this.setState({ textContent1: left, textContent2: right, userToken: token })
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        fetch(getMuppCategories, requestOptions)
            .then(response => response.json())
            .then((result) => {
                let resData = result.category
                // let categoryId = resData.filter(item => item.name === "Personal")
                // let id = categoryId[0].category_id
                this.setState({ categoryArray: resData, muppCategory: resData[0].category_id })
            })
        this.getMuppApiCall()


    }

    deletemuppAPI() {
        fetch(mupp + "/?mupp_id=" + this.state.muppId, {
            method: "Delete",
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${this.state.userToken}`,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              this.componentDidMount();
              this.notifySuccess("Delete Mupps Successfully");
            })
            .catch((err) => {
              //this.notifyWarning("Something Went Wrong");
            });
        };
        // const requestOptions = {
        //     method: 'DELETE',
        //     headers: {
        //         'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}`,
        //     },
        //     body: JSON.stringify({
        //         "mupp_id": this.state.muppId,
        //     })
        // }
        // console.log(this.state.muppId)
        // fetch(updateMupp, requestOptions)
        //     .then(response => response.json())
        //     .then((result) => {
        //         console.log(result)
        //         if (result.success === 'true') {
        //             this.notifySuccess(result.message)
        //             this.getMuppApiCall()
        //         }

        //     })

          

    getMuppApiCall = () => {
        let token = localStorage.getItem('token')

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
        }
        let serive_id = localStorage.getItem('id')

        let getMuppURL = getMupp + "?" + "service=" + serive_id;

        fetch(getMuppURL, requestOptions)
            .then(response => response.json())
            .then((result) => {
                let resData = result.response
                console.log(result)
                if (resData.length) {
                    this.setState({
                        muppId: resData[0].id,
                        textContent4: resData[0].path,
                        textContent5: resData[0].description,
                        textContent3: resData[0].rough_text,
                        categoryType: resData.category,
                        categoryId: resData[0].category_id,
                        isHidden: true,
                        MUPP: resData,
                        muppLength: resData.length - 1,
                        prevDescription: resData[0].description,
                        prevPath: resData[0].path,
                        prevRoughText: resData[0].rough_text,
                        selectForRating: resData[0].select_for_ratings,
                        rankForMupp: resData[0].rank_for_mupps
                    })
                }
            })

    }

    // function that update mupp

    updateMuppApi = () => {
        if (this.state.muppCategory === "DEFAULT") {
            this.notifyWarning("Please select a valid category")
            return
        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}`,
            },
            body: JSON.stringify({
                "path": this.state.textContent4,
                "description": this.state.textContent5,
                "mupp_id": this.state.muppId,
                "rough_text": this.state.textContent3,
                "rank_for_mupps": this.state.rankForMupp,
                "select_for_ratings": this.state.selectForRating,
                "category_id": this.state.muppCategory
            })
        }
        fetch(updateMupp, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result)
                if (result.success === 'true') {
                    this.notifySuccess(result.message)
                    this.getMuppApiCall()
                }

            })

    }

    // function that post mupp 
    postMuppApi = () => {
        if (this.state.muppCategory === "DEFAULT") {
            this.notifyWarning("Please select a valid category")
            return
        }
        let serive_id = localStorage.getItem('id')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}`,
            },
            body: JSON.stringify({
                "category": this.state.muppCategory,
                "path": this.state.textContent4,
                "description": this.state.textContent5,
                "rough_text": this.state.textContent3,
                "service": serive_id
            })
        }
        fetch(postMupp, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result)
                if (result.success === "true") {
                    this.notifySuccess(result.message)
                    this.setState({ textContent3: "", textContent4: "", textContent5: "", isAddMupp: false })
                    if (this.state.savedClick) {
                        this.props.history.push({
                            pathname: '/mupprating',
                        })
                    }

                }
                else {
                    this.notifyWarning("The field may not be blank")
                }
            })
    }

    IncrementItem = async () => {
        const { prevDescription, prevPath, textContent4, textContent5, isLast, textContent3, prevRoughText } = this.state
        // else if ((prevPath !== textContent4 || prevDescription !== textContent5 || prevRoughText !== textContent3) && !isLast) {


        console.log("shouldUpdateApi ", (prevPath !== textContent4 || prevDescription !== textContent5 || prevRoughText !== textContent3) && !isLast)
        if (prevRoughText === textContent3 && prevDescription === textContent5 && prevPath === textContent4 && !isLast) {
            console.log("First IF")
            await this.setState(prevState => {
                if (prevState.counter < this.state.MUPP.length - 1) {
                    console.log("if", this.state.MUPP.length)
                    return {
                        counter: prevState.counter + 1
                    }
                } else {
                    return null;
                }
            });
            console.log(this.state.counter)
            if (this.state.MUPP.length - 1 === this.state.counter) {
                //this.props.history.push('/mupprating')
                console.log("counter", this.state.counter)
                console.log("last")
                this.setState({ isLast: true })

            }

            await this.setState({
                muppId: this.state.MUPP[this.state.counter].id,
                textContent3: this.state.MUPP[this.state.counter].rough_text,
                prevRoughText: this.state.MUPP[this.state.counter].rough_text,
                textContent4: this.state.MUPP[this.state.counter].path,
                prevPath: this.state.MUPP[this.state.counter].path,
                textContent5: this.state.MUPP[this.state.counter].description,
                prevDescription: this.state.MUPP[this.state.counter].description,

                muppCategory: this.state.MUPP[this.state.counter].category_id,
                selectForRating: this.state.MUPP[this.state.counter].select_for_ratings,
                rankForMupp: this.state.MUPP[this.state.counter].rank_for_mupps
            })
        }
        else if ((prevPath !== textContent4 || prevDescription !== textContent5 || prevRoughText !== textContent3) && !isLast) {
            console.log("Upadte Mupp API")
            this.updateMuppApi()
        }

        else if (isLast) {
            console.log("IS LAST")
            if (textContent4 === "" && textContent5 === "") {
                this.notifyWarning("Field can not be blank")
            }
            if (!this.state.isAddMupp) {
                this.props.history.push("/page4")
            }
            else {
                console.log("POST MUPP API")
                this.postMuppApi()
            }
        }

    }
    DecreaseItem = async () => {
        await this.setState(prevState => {
            if (prevState.counter > 0) {
                return {
                    counter: prevState.counter - 1
                }
            } else {
                return null;
            }
        });
        await this.setState({
            muppId: this.state.MUPP[this.state.counter].id,
            textContent3: this.state.MUPP[this.state.counter].rough_text,
            prevRoughText: this.state.MUPP[this.state.counter].rough_text,
            textContent4: this.state.MUPP[this.state.counter].path,
            prevPath: this.state.MUPP[this.state.counter].path,
            textContent5: this.state.MUPP[this.state.counter].description,
            prevDescription: this.state.MUPP[this.state.counter].description,

            muppCategory: this.state.MUPP[this.state.counter].category_id,
            selectForRating: this.state.MUPP[this.state.counter].select_for_ratings,
            rankForMupp: this.state.MUPP[this.state.counter].rank_for_mupps,
            isLast: false
        })
        console.log(this.state.MUPP[this.state.counter].id)

    }

    nextMupp = (item) => {
        if (item === 'back') {
            console.log('back call')
            if (this.state.counter >= 0) {
                this.setState({ counter: this.state.counter - 1 })
                console.log('backcounter', this.state.counter)
                this.setState({ textContent4: this.state.MUPP[this.state.counter].path, textContent5: this.state.MUPP[this.state.counter].description })

            }
        }
        else if (item === "next") {
            console.log('next call')
            if (this.state.MUPP.length > this.state.counter) {
                this.setState({ counter: this.state.counter + 1 })
                console.log('counter', this.state.counter)
                this.setState({ textContent4: this.state.MUPP[this.state.counter].path, textContent5: this.state.MUPP[this.state.counter].description })
            }
            else if (this.state.MUPP.length === this.state.counter) {
                //this.props.history.push('/mupprating')
                console.log("counter", this.state.counter)
                console.log("last")

            }
        }

    }

    onDelete = () => {
        this.setState({savedClick : true})
        this.deletemuppAPI()
    }

    onSubmit = () => {
        this.setState({ savedClick: true })
        this.postMuppApi()

    }

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    handleAddMupp = () => {
        const { prevPath, prevDescription, textContent4, textContent5 } = this.state
        if (this.state.isHidden) {
            console.log("prevpath", this.state.prevPath)
            console.log("prevdes", this.state.prevDescription)
            console.log("path", this.state.textContent4)
            console.log("des", this.state.textContent5)




            //this.updateMuppApi()
        }
        else {
            this.postMuppApi()
        }
    }

    addMupp = () => {
        this.setState({ textContent3: '', textContent4: '', textContent5: '', prevDescription: '', prevPath: '', isAddMupp: true })
    }

    exitbutton = () => {
        this.props.history.push('/page4')
    }

    
    pasteAsPlainTextforRough = (event) => {
        event.preventDefault()

        const text = event.clipboardData.getData('text/plain')
        document.execCommand('insertHTML', false, text)
        console.log("text", text)
        this.setState({ textContent3: text })
    }

    pasteAsPlainTextforPath = (event) => {
        event.preventDefault()
        const text = event.clipboardData.getData('text/html')
        document.execCommand('insertHTML', true, text)
        console.log("text", text)
        this.setState({ textContent4: text })
    }

    pasteAsPlainTextforDescription = (event) => {
        event.preventDefault()

        const text = event.clipboardData.getData('text/plain')
        // document.execCommand('insertHTML', false, text)
        console.log("text", text)
        this.setState({ textContent5: text })
    }



    render() {
        return (
            <div className="col-sm-12" style={{ marginTop: "20px" }}>
                <div className="col-sm-12">
                    <h2>Add MUPP</h2>
                </div>
                <div className="col-sm-12 padding-0 addMuppContainer">
                    <div className="col-sm-6">
                        <ContentEditable
                            className="textEditor"
                            innerRef={this.contentEditable}
                            style={{ maxHeight: "30vh", overflowY: 'auto' }}
                            html={this.state.textContent1}
                            disabled={false}
                            onChange={(e) => {
                                this.setState({ textContent1: e.target.value })
                            }}
                        />
                        <ContentEditable
                            className="textEditor"
                            innerRef={this.contentEditable}
                            style={{ maxHeight: "50vh", overflowY: 'auto' }}
                            html={this.state.textContent2}
                            disabled={false}
                            onChange={(e) => {
                                this.setState({ textContent2: e.target.value })
                            }}
                        />
                    </div>
                    <div className="col-sm-6 padding-0">
                        <div className="col-sm-12">
                            <select className="form-control"
                                onChange={(e) => this.setState({ muppCategory: e.target.value })}
                                value={this.state.muppCategory}
                            >
                                {this.state.categoryArray.length > 0 ?
                                    <>
                                        <option value="DEFAULT">Select MUPP category</option>
                                        {this.state.categoryArray.map((item, index) => <option value={item.category_id}>{item.name}</option>)}
                                    </>
                                    :
                                    <option value="DEFAULT">Select MUPP category</option>

                                }

                            </select>
                        </div>
                        <div className="col-sm-12">
                            <h5>Rough Text</h5>
                            {/* <ContentEditable
                                className="textEditor"
                                onPaste={this.pasteAsPlainTextforRough}
                                innerRef={this.contentEditable}
                                style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                html={this.state.textContent3}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textContent3: e.target.value })
                                }}
                            /> */}
                            <textarea
                                className="textEditor"
                                innerRef={this.contentEditable}
                                disabled={false}
                                style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                value={this.state.textContent3}
                                onChange={(e) => {
                                    this.setState({ textContent3: e.target.value })
                                }}
                            />
                        </div>
                        <div className="col-sm-12">
                            <h5>MUPP Path</h5>
                            {/* <ContentEditable
                                className="textEditor"
                                innerRef={this.contentEditable}
                                onPaste={this.pasteAsPlainTextforPath}
                                html={this.state.textContent4}
                                style={{ height:"20vh",maxHeight: "20vh",overflowY: 'auto' }}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textContent4: e.target.value })
                                }}
                            /> */}

                            <textarea
                                className="textEditor"
                                innerRef={this.contentEditable}
                                disabled={false}
                                style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                value={this.state.textContent4}
                                onChange={(e) => {
                                    this.setState({ textContent4: e.target.value })
                                }}
                            />
                        </div>
                        <div className="col-sm-12">
                            <h5>Description</h5>
                            {/* <ContentEditable
                                className="textEditor"
                                innerRef={this.contentEditable}
                                onPaste={this.pasteAsPlainTextforDescription}
                                html={this.state.textContent5}
                                style={{ height: "25vh", maxHeight: "25vh", overflowY: 'auto' }}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textContent5: e.target.value })
                                }}
                            /> */}
                            <textarea
                                className="textEditor"
                                innerRef={this.contentEditable}
                                disabled={false}
                                style={{ height: "20vh", maxHeight: "20vh", overflowY: 'auto' }}
                                value={this.state.textContent5}
                                onChange={(e) => {
                                    this.setState({ textContent5: e.target.value })
                                }}
                            />
                        </div>
                        <div className="col-sm-12">
                            {/* {!this.state.isHidden ? <div> <button id="muppBtn" onClick={this.onSubmit}>Save</button>
                            <button onClick={this.postMuppApi}>Add more MUPP</button></div> : <button id="muppBtn" onClick={this.nextMupp}>Next</button>} */}
                            <div className={!this.state.isHidden ? 'add-mupps-footer-btn' : 'add-mupps-footer-btn-isHidden'}>
                                {!this.state.isHidden && <button onClick={this.onSubmit}>Complete</button>}
                                {!this.state.isHidden && <button onClick={this.handleAddMupp}>Add more MUPP</button>}
                                {this.state.isHidden && <button onClick={this.onDelete} className="nextBtn" >Delete</button>}
                                {this.state.isHidden && <button onClick={this.exitbutton} className="nextBtn" >Exit</button>}
                                {this.state.isHidden && <button onClick={this.IncrementItem} className="nextBtn" >Next & Save</button>}
                                {this.state.isHidden && <button onClick={this.DecreaseItem} className="backBtn">Back</button>}
                                {this.state.isLast && <button onClick={this.addMupp} className="addBtn">Add MUPP</button>}
                            </div>
                        </div>
                        <ToastContainer hideProgressBar={false}></ToastContainer>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Addmupp)