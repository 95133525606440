import React, { useState, useEffect } from "react";
import logo from "../../assests/logo.png";
import "./signin.css";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userLogin } from "../../constants/api-config";
import Loader from "react-loader-spinner";
import DirectiveLoader from "../Functional-component/Loader/loader.component";

export default function Sign(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    let email = localStorage.getItem("email");
    let password = localStorage.getItem("password");
    let isRemember = localStorage.getItem("isRemembered");
    if (isRemember === "true") {
      setEmail(email);
      setPassword(password);
      setIsChecked(isRemember);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      notifyWarning("Please fill Email");
      return;
    }
    if (!password) {
      notifyWarning("Please fill Password");
      return;
    }
    if (validateEmail(email)) {
      if (isChecked) {
        localStorage.setItem("isRemembered", isChecked);
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("passAuth", false);
      } else {
        localStorage.setItem("isRemembered", false);
        localStorage.setItem("email", email);
        localStorage.setItem("password", "");
        localStorage.setItem("passAuth", false);
      }
      loginApiCall();
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const notifyWarning = (text) =>
    toast.warning(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  const notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 1000,
      onClose: () => {
        props.history.push("/cards");
        window.location.reload();
      },
    });

  const loginApiCall = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email.toLowerCase(),
        password: password,
      }),
    };
    setloading(true);
    fetch(userLogin, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setloading(false);
          if (result.verified) {
            notifySuccess("Login Success");
            localStorage.setItem("token", result.token);
            localStorage.setItem("lastTokenTime", new Date().getTime());
          } else {
            let message = result.non_field_errors[0];
            notifyWarning(message);
          }
        },
        (error) => {
          console.log("error", error);
        }
      )
      .catch((error) => {
        setloading(false);
        console.log("error", error);
      });
  };

  return (
    <div className="loginform">
      <form onSubmit={onSubmit}>
        <div className="signImgContainer">
          <img src={logo} className="signImage" alt="" />
        </div>
        <input
          type="email"
          placeholder="Email"
          value={email.toString()}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password.toString()}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div id="rememberContainer">
          <input
            id="ischecked"
            type="checkbox"
            checked={isChecked}
            name="lsRememberMe"
            onChange={() => setIsChecked(!isChecked)}
          />
          <label id="Remember">Remember me</label>
        </div>
        <Link to="/Reset">
          <h6>Forgot your password? click to reset</h6>
        </Link>
        <div></div>
        {loading ? (
          <DirectiveLoader />
        ) : (
          <button className="btn-signin">Sign In</button>
        )}
        <Link to="/Verifymail">Resend Verify Email</Link>
        <Link to="/">
          <h6 style={{ color: "white", fontSize: "18px" }}>Need an Account?</h6>
        </Link>
        <div>
          <Link to="/Privacy">
            <h6>Privacy and Policy</h6>
          </Link>
          <Link to="/TermsConditions">
            <h6>Terms and Conditions</h6>
          </Link>
          <p>Version - S1.2</p>
        </div>

        <ToastContainer hideProgressBar={false}></ToastContainer>
      </form>
    </div>
  );
}
