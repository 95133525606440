import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./dailyJournalmodal.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: "",
      type: props.type,
    };
  }

  render() {
    return (
      <div style={{ backgroundColor: "green" }}>
        <Modal
          open={this.props.open}
          onClose={this.props.onCloseModal}
          onOpen={this.props.onOpen}
        >
          <div style={{ width: "50vw" }}>
            <input
              type="text"
              // className="textAreamodal"
              className="form-control my-3"
              onChange={this.props.onChange}
              defaultValue={this.props.value}
              placeholder="Enter Title . . ."
            />
            <div
              style={{
                height: "100%",
                minHeight: "40vh",
                maxHeight: "30vh",
                overflow: "scroll",
                marginBottom: "2rem",
              }}
            >
              <ReactQuill
                // className="textAreamodal"
                // className="form-control my-3"
                theme="snow"
                onChange={this.props.onjournalTextChange}
                defaultValue={this.props.defaultjournalText}
                placeholder="Enter Journal . . ."
                className="react-quill"
              />
            </div>
            <button
              onClick={this.props.saveText}
              className="log__monthlymodalButton"
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
