


import React, { Component } from 'react';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import './modalStyle.css'

const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
};

class ModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <div style={styles}>
                <Modal open={this.props.open} onClose={this.props.onCloseModal} onOpen={this.props.onOpen}>
                    <textarea className="modalContainer"
                        onChange={this.props.onChange}
                        defaultValue={this.props.value}
                    />
                    <button onClick={this.props.saveText} className="modalButton" >Save</button>
                </Modal>
            </div>
        );
    }
}

export default ModalComponent;
