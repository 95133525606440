


import React from 'react'
import Collapsible from 'react-collapsible';


export default function Collapse(props) {
    return (
        <div style={{ backgroundColor: '#191922', border: 'solid', cursor: 'pointer' }}>
            <Collapsible open="true" triggerStyle={{ fontWeight: '600', fontSize: '20px', padding: '5px', color: 'white' }} trigger={props.title}>
                {props.children}
            </Collapsible>
        </div>
    )
}
