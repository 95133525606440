import SelectMupp from '../self-discovery/select-mupp/SelectMupp';

const Privacy = () => {
    return (
        <>
        <SelectMupp></SelectMupp>
        </>
    );
};
    
    
export default Privacy;