import React, { Component } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./Monthlymodal.css";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: "",
      type: props.type,
    };
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    await this.setState({ currentDate: date });
    console.log(this.state.currentDate);
    console.log(
      "DAtE",
      this.props.dateValue,
      new Date().toISOString().split("T")[0]
    );
  }

  render() {
    return (
      <div style={{ backgroundColor: "green" }}>
        <Modal
          open={this.props.open}
          onClose={this.props.onCloseModal}
          onOpen={this.props.onOpen}
        >
          <textarea
            className="textAreamodal"
            onChange={this.props.onChange}
            defaultValue={this.props.value}
          />

          <select
            className="form-control"
            onChange={(e) => {
              this.props.onChangeType(e);
            }}
            defaultValue={this.props.type}
          >
            <option value="">Select Reminder type</option>
            <option value="MONTHLY">Monthly</option>
            <option value="DATED">Dated</option>
            <option value="WEEKLY">Weekly</option>
            <option value="DAILY">Daily</option>
          </select>

          <input
            type="date"
            min={new Date().toISOString().split("T")[0]}
            defaultValue={this.props.dateValue} //className="form-control"
            onChange={this.props.handleInputChange}
          />
          <button onClick={this.props.saveText} className="monthlymodalButton">
            Save
          </button>
        </Modal>
      </div>
    );
  }
}

export default ModalComponent;
