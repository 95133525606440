import React, { Component } from "react";
import Delete from "../../assests/delete.jpg";

class DeleteTask extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <img
        onClick={() => {
          if (window.confirm("Are you sure you want to delete this task?")) {
            this.props.deleteTask();
          }
        }}
        src={Delete}
        style={{ cursor: "pointer", marginLeft: "15px", marginTop: "-10px" }}
        height="25px"
        width="25px"
        alt=""
      />
    );
  }
}

export default DeleteTask;
