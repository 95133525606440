import React, { Component } from 'react'

class EditTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    render(){
        return (
            <div className="task-accordion" id="rightSlidePanel" style={{ display: 'none' }}>
                <i className="fa fa-close close-icon" style={{ color: "black" }} onClick={() => {this.props.closePanelForEdit()}} ></i>
                <h4>Edit Task</h4>

            <div className="form-group">
                <textarea
                    // onChange={this.props.handleEditTaskName}
                    onChange={(e) => { this.props.handleEditTaskName(e) }}
                    type="text"
                    defaultValue={this.props.editTaskName}
                    name="Title"
                    className="form-control"
                    required
                    id="editTaskNameTextArea"
                    style={{height:"30vh"}}
                />
            </div>

            <div className="form-group">
                <select 
                    value={this.props.editTaskType} 
                    onChange={(e) => { this.props.handleTaskType(e) }} 
                    name="task-type" 
                    className="form-control" 
                >
                    <option value="THIS_WEEK">THIS_WEEK</option>
                    <option value="LATER">LATER</option>
                    <option value="AWAITING">AWAITING</option>
                </select>
            </div>

            <div className="form-group">
                <input
                    onChange={this.props.handleEditDate}
                    defaultValue={this.props.editTaskDate}
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    name="date"
                    className="form-control"
                    required
                    value={this.props.editTaskDate}
                    id="editTaskDateTextArea"
                />
            </div>
                        
            <div className="col-sm-12 add-mupps-button">
                <button type="button" onClick={this.props.clearEditDate} >Reset Date</button>
                <button type="submit" onClick={this.props.updateTask}>Save</button>
            </div>
        </div>
        )
    }

}

export default EditTask;