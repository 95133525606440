import React from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './calendarStyle.css';
import DirectiveLoader from '../Functional-component/Loader/loader.component';
import Collapsible from '../Functional-component/collapse/Collapse';
import { getTaskByWeek } from '../../constants/api-config'



const Tabs = ({ active, count, onClick }) => {
    return (
        <li onClick={onClick} className={active ? "active" : ""}>
            <p>task</p>
        </li>
    )
}

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {

    return {
        from: moment(date)
            .startOf('isoWeek')
            .toDate(),
        to: moment(date)
            .endOf('isoWeek')
            .toDate(),
    };
}

export default class Calendar extends React.Component {
    constructor(props) {
        super(props);

    }
    state = {
        hoverRange: undefined,
        selectedDays: [],
        dateWiseData: [],
        undateWiseData: [],
        isLoading: true,
        activeTab: "Task",
        isWeekSelected: true,

    };


    componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });


    }



    getTaskByWeekcallAPI = async (start, end) => {
        await this.setState({ isLoading: false });
        let URL = getTaskByWeek + "?" + "start_date=" + start + "&end_date=" + end;
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            }
        }

        fetch(URL, requestOptions)
            .then(res => res.json())
            .then(
                async (result) => {
                    console.log(result)
                    if (result.success === true) {
                        await this.setState({ dateWiseData: result.date_wise, undateWiseData: result.undated_tasks, isLoading: true, isWeekSelected: false })

                    }
                    console.log("data", this.state.dateWiseData)
                    console.log("undated", this.state.undateWiseData)

                    this.state.dateWiseData.map(item => {


                        let title = Object.keys(item)
                        console.log(title)
                    })


                },
                (error) => {
                    console.log("error in getMuppCategories api, file name - thisWeek.js", error);
                }

            )
    }

    firstAndLastDay = item => {
        let mon = moment(item).day("Monday");
        let satur = moment(item).add(1, "w").day("Sunday");

        let monDate = mon.toJSON().slice(0, 10) + "";
        let sunDate = satur.toJSON().slice(0, 10) + "";
        console.log("mon", monDate);
        console.log("satur", sunDate);
        this.getTaskByWeekcallAPI(monDate, sunDate)



    };

    handleDayChange = date => {

        this.setState({
            selectedDays: getWeekDays(getWeekRange(date).from),
        });
        let start = moment(this.state.selectedDays[0]).format('YYYY-MM-DD')
        let end = moment(this.state.selectedDays[6]).format('YYYY-MM-DD')
        console.log("start", start)
        console.log("end", end)
    };
    handleDayEnter = date => {
        this.setState({
            hoverRange: getWeekRange(date),
        });

    };

    handleDayLeave = () => {
        this.setState({
            hoverRange: undefined,
        });

    };

    handleWeekClick = (weekNumber, days, e) => {
        this.setState({
            selectedDays: days,
        });
        console.log("on week")
    };



    handleReminder = () => {
        console.log("reminder")
        this.setState({ activeTab: "Reminder" })
    }

    handleTask = () => {
        console.log("tasks")
        this.setState({ activeTab: "Task" })

    }

    render() {
        const { hoverRange, selectedDays } = this.state;

        const daysAreSelected = selectedDays.length > 0;

        const modifiers = {
            hoverRange,
            selectedRange: daysAreSelected && {
                from: selectedDays[0],
                to: selectedDays[6],
            },
            hoverRangeStart: hoverRange && hoverRange.from,
            hoverRangeEnd: hoverRange && hoverRange.to,
            selectedRangeStart: daysAreSelected && selectedDays[0],
            selectedRangeEnd: daysAreSelected && selectedDays[6],
        };

        return (
            <div style={{ display: 'flex' }}>
                <div style={{ marginTop: "15%", marginLeft: "20px" }}>
                    <div className="SelectedWeekExample">
                        <p style={{ marginLeft: "20px", fontWeight: "bold", fontSize: "18px" }}>Select a date please:</p>
                        <DayPicker
                            selectedDays={selectedDays}
                            showWeekNumbers={false}
                            showOutsideDays={false}
                            firstDayOfWeek={1}
                            modifiers={modifiers}
                            onDayClick={date => { this.firstAndLastDay(date) }}
                            onDayMouseEnter={this.handleDayEnter}
                            onDayMouseLeave={this.handleDayLeave}
                            onWeekClick={this.handleWeekClick}
                        />
                    </div>
                </div>
                <div style={{ marginTop: "3%" }}>
                    <button className="btnTask" style={{ marginRight: "20px" }} onClick={this.handleTask}>Tasks</button>
                    <button className="btnRem" onClick={this.handleReminder}>Reminder</button>
                    {this.state.isWeekSelected ? <div>
                    </div> : null}
                    {this.state.isLoading ?
                        <div style={{ height: "100vh",minHeight:"50vh",overflowY:"auto", width: "50vw", backgroundColor: '#191923', marginTop: "10px" }}>
                            {this.state.activeTab === 'Task' ? this.state.dateWiseData.map(item => {
                                let title = Object.keys(item);
                                let taskArray = item[title].tasks;
                                console.log("item.reminders", item);
                                return <Collapsible title={title}>
                                    <div style={{ margin: 30 }}>
                                        <ul style={{ listStyleType: 'none' }}>
                                            {taskArray.length > 0 ? <>
                                                {taskArray.map(task => <li className="calenderTaskli">
                                                    <h6>{task.name}</h6></li>)}
                                            </> : <div><p>No Data</p></div>}
                                        </ul>
                                    </div>
                                </Collapsible>



                            }) :
                                this.state.dateWiseData.map(item => {

                                    let title = Object.keys(item);
                                    let reminderArray = item[title].reminders;
                                    return <Collapsible title={title}>
                                        <div style={{ margin: 30 }}>
                                            <ul style={{ listStyleType: 'none' }}>
                                                {reminderArray.length > 0 ? <>
                                                    {reminderArray.map(reminder => <li className="calenderTaskli">{reminder.name}</li>)}
                                                </> : <div><p>No Data</p></div>}
                                            </ul>
                                        </div>
                                    </Collapsible>


                                })





                            }


                        </div> :
                        <div style={{ marginTop: "45%", marginLeft: "47%" }}><DirectiveLoader /></div>}
                </div>
            </div>
        );
    }
}