import React, { Component } from "react";
import Header from "../Functional-component/Header/Header";
import Addtask from "../Functional-component/Add-task/Addtask";
import ModalComponent from "../monthly-modal/Monthlymodal";
import {
  postReminder,
  getReminder,
  updateReminder,
  deleteReminder,
  getAllReminder,
} from "../../constants/api-config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from "../../assests/delete.jpg";
import Edit from "../../assests/Edit.png";
import Loader from "react-loader-spinner";
import Calendar from "../../assests/calendar.png";

export default class Monthlyreminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      date: "",
      reminderData: "",
      text: "",
      isUpdate: false,
      value: "",
      dateValue: "",
      reminderID: "",
      isloading: false,
      type: "",
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.getReminderAPICall();
  }

  postReminderAPICall = () => {
    if ((this.state.text && this.state.date) !== "") {
      let token = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          name: this.state.text,
          date: this.state.date,
          type: this.state.type,
        }),
      };
      fetch(postReminder, requestOptions)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.success === "True") {
              this.notifySuccess(result.message);
              this.onClose();
              this.getReminderAPICall();
            }
          },
          (error) => {
            console.log(
              "error in getTasksByType api, file name - monthlyReminder.js",
              error
            );
          }
        );
    } else if (this.state.text === "") {
      this.notifyWarning("Field can not be blank");
    } else if (this.state.type === "") {
      this.notifyWarning("Please select a type");
    } else if (this.state.date === "") {
      this.notifyWarning("Please select date");
    }
  };

  getReminderAPICall = async () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getAllReminder, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ reminderData: result.reminder, isloading: true });
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  // checkBeforeCallUpdateAPI = async () => {
  //     if (this.state.text === "" && this.state.date === "" && this.state.type=== "") {
  //         console.log("dont call api")
  //     }
  //     else if (this.state.text !== "" && this.state.date === "") {
  //         console.log("text change")
  //         await this.setState({ date: this.state.dateValue })
  //         this.updateReminderAPICall()
  //     }
  //     else if (this.state.date !== "" && this.state.text === "") {
  //         console.log("date chage", this.state.date)
  //         await this.setState({ text: this.state.value })
  //         this.updateReminderAPICall()
  //     }
  //     else if (this.state.type !== "" && this.state.text === "" && this.state.date === "") {
  //         console.log("type chage", this.state.type)
  //         console.log("change date",this.state.date)
  //         await this.setState({ text: this.state.value ,date:this.state.date})
  //         this.updateReminderAPICall()
  //     }
  //     else if (this.state.text !== "" && this.state.date !== "" && this.state.type !== "") {
  //         console.log("<--All change-->")
  //         this.updateReminderAPICall()
  //     }
  // }

  checkBeforeCallUpdateAPI = async () => {
    let tempText = "";
    let tempDate = "";
    let tempType = "";
    if (
      this.state.text === "" &&
      this.state.date === "" &&
      this.state.type === ""
    ) {
      console.log("dont call api");
    } else if (this.state.text !== "") {
      console.log(
        "Text changes",
        this.state.text,
        this.state.date,
        this.state.type
      );
      this.updateReminderAPICall(
        this.state.text,
        this.state.date,
        this.state.type
      );
    } else if (this.state.date !== "") {
      console.log(
        "Datechanged",
        this.state.text,
        this.state.date,
        this.state.type
      );
      this.updateReminderAPICall(
        this.state.text,
        this.state.date,
        this.state.type
      );
    } else if (this.state.type !== "") {
      console.log(
        "Type changed",
        this.state.text,
        this.state.date,
        this.state.type
      );
      this.updateReminderAPICall(
        this.state.text,
        this.state.date,
        this.state.type
      );
    } else if (
      this.state.text !== "" &&
      this.state.date !== "" &&
      this.state.type !== ""
    ) {
      console.log(
        "<--All change-->",
        this.state.text,
        this.state.date,
        this.state.type
      );
      this.updateReminderAPICall(
        this.state.text,
        this.state.date,
        this.state.type
      );
      // this.updateReminderAPICall()
    }
  };
  updateReminderAPICall = async (taskName, taskDate, taskType) => {
    let token = localStorage.getItem("token");
    let id = this.state.reminderID;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        reminder_id: id,
        name: taskName,
        date: taskDate,
        type: taskType,
      }),
    };
    fetch(updateReminder, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.onClose();
            this.getReminderAPICall();
            this.setState({ value: "" });
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  deleteReminderAPICall = (id) => {
    let deleteReminderAPI = deleteReminder + "?" + "reminder_id=" + id;
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(deleteReminderAPI, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.onClose();
            this.getReminderAPICall();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  Addtask = () => {
    console.log("Add task function call");
    this.onOpen();
    this.setState({ isUpdate: false, date: "", text: "", type: "", date: "" });
  };

  onClose = () => {
    this.setState({ show: false });
    this.setState({ date: "", text: "", type: "" });
  };

  onOpen = () => {
    this.setState({ show: true });
  };

  handleInputChange = async (item) => {
    console.log("Date", item.target.value);
    await this.setState({ date: item.target.value });
  };

  saveText = () => {
    if (this.state.isUpdate) {
      this.checkBeforeCallUpdateAPI();
    } else {
      this.postReminderAPICall();
    }
  };

  //     saveEditText = () => {
  //         console.log("edit task api call")
  //         this.updateReminderAPICall()
  // }

  onChangeText = async (item) => {
    await this.setState({ text: item.target.value });
  };
  onChangeType = async (item) => {
    await this.setState({ type: item.target.value });
  };

  deleteReminder = (item) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      this.deleteReminderAPICall(item.reminder_id);
    }
  };

  editReminder = (item) => {
    this.onOpen();
    console.log("EDIT", item);
    this.setState({
      isUpdate: true,
      text: item.name,
      date: item.date,
      reminderID: item.reminder_id,
      type: item.reminder_type,
    });
  };

  reminderList = () => {
    if (this.state.reminderData) {
      return this.state.reminderData.map((item) => (
        <ul className="inbox-tabs">
          <li
            className="col-sm-12 inbox-tab-li"
            style={{ alignItems: "center" }}
          >
            <div className="col-sm-10 padding-0">
              <h4 className="text-ellipses-h4">{item.name}</h4>
              <p style={{ margin: "0" }}>
                {" "}
                <img src={Calendar} height="25px" width="25px" />
                {item.date}
              </p>
              <p style={{ margin: "0" }}> Type: {item.reminder_type}</p>
            </div>
            <div className="col-sm-2 test-align-right">
              <img
                onClick={this.deleteReminder.bind(this, item)}
                src={Delete}
                height="25px"
                width="25px"
                style={{
                  background: "white",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                alt=""
              />
              <img
                onClick={this.editReminder.bind(this, item)}
                src={Edit}
                height="25px"
                width="25px"
                style={{ background: "white", cursor: "pointer" }}
                alt=""
              />
            </div>
          </li>
        </ul>
      ));
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontSize: "24px", fontWeight: "400" }}>No Data</p>
        </div>
      );
    }
  };

  notifyWarning = (text) =>
    toast.warning(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  render() {
    return (
      <div>
        <Header header="Monthly Reminder" />
        <Addtask
          buttonname="Add"
          Addtask="Add Reminder"
          onClick={this.Addtask}
        />
        {this.state.isloading ? (
          this.reminderList()
        ) : (
          <div style={{ marginTop: "20%", marginLeft: "45%" }}>
            {" "}
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={50}
              width={50}
              timeout={100000} //3 secs
            />
          </div>
        )}
        <ModalComponent
          open={this.state.show}
          onCloseModal={this.onClose}
          onOpen={this.onOpen}
          handleInputChange={this.handleInputChange}
          saveText={this.saveText}
          onChange={this.onChangeText}
          value={this.state.text}
          dateValue={this.state.date}
          type={this.state.type}
          onChangeType={this.onChangeType}
        />

        {/* <ModalEditComponent
                    open={this.state.show}
                    onCloseModal={this.onClose}
                    onOpen={this.onOpen}
                    handleInputChange={this.handleInputChange}
                    saveEditText={this.saveEditText}
                    onChange={this.onChangeText}
                    value={this.state.value}
                    dateValue={this.state.dateValue}
                    type = {this.state.type}
                    onChangeType = {this.onChangeType}
                /> */}
        <ToastContainer hideProgressBar={false}></ToastContainer>
      </div>
    );
  }
}
