import React, { Component } from 'react'
// import Question1 from '../data-components/question'
import '../TestLink/testlink.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { questionsId } from "../../../constants/LocalStorageConsts"
import { questions, postAnswer, getAnswers, updateAnswer } from "../../../constants/api-config";
import ContentEditable from 'react-contenteditable'
import { withRouter } from 'react-router-dom';
import Header from '../../Functional-component/Header/Header';

class Art extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.contentEditable = React.createRef();

        this.state = {
            question: [],
            counter: 0,
            quesCount: 1,
            ques: '',
            quesIsCompleted: false,
            question_ID: '',
            userToken: "",
            serviceId: "",
            answers: [],
            textareaContent1: "",
            textareaContent2: "",
            answer1: "",
            answer2: "",
            tempVar: "",
            foundAnswer: false,
            answerId: '',
            prevResponse1: '',
            prevResponse2: '',
            postCounter: 0,
            sessionName :localStorage.getItem('sessionName')
        }
        this.go = this.go.bind(this)
    }

    onClick = () => {
        this.child.current.get();
    };

    getItemStatusFromLocalStorage = item => {
        let res = localStorage.getItem(item)
        return res ? true : false;
    }

    async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let value = localStorage.getItem('token')
        let service_id = localStorage.getItem('discoverySessionId')
        console.log("idd", service_id)
        await this.setState({ userToken: value, serviceId: service_id });
        this.getQuestionsApiCall(service_id, value);
    }

    getQuestionsApiCall = (seviceId, token) => {
        //console.log("id", serviceId)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                discovery_session_id: seviceId
            })
        }
        fetch(questions, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result) {
                    let data = [];
                    for (let i = 0; i < result.length; i++) {
                        data.push({ ...result[i], isCompeleted: this.getItemStatusFromLocalStorage(`${questionsId}${result[i].question_id}`) });
                    }
                    if (data && data.length > 0) {
                        this.setState({ question: data, ques: result[0].name, question_ID: result[0].question_id, quesIsCompleted: data[0].isCompeleted })
                    }
                    if (this.state.question && this.state.question.length > 0) this.getAnswersApiCall(this.state.question[0].question_id, token);
                } else {
                    //Other than expected result or error handling

                }
            })
    }

    //function that update answer
    updateAnswersApiCall = (token, answerId, response1, response2) => {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "answer_id": answerId,
                "response_1": response1,
                "response_2": response2

            })
        }
        fetch(updateAnswer, requestOptions)
            .then(response => response.json())
            .then((result) => {
                console.log(result)
                if (result.success === "true") {
                    this.notifySuccess(result.massage)
                }
            })
    }

    getAnswersApiCall = (questionId, token) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                question_id: questionId
            })
        }
        fetch(getAnswers, requestOptions)
            .then(response => response.json())
            .then((result) => {
                if (result.length > 0) {
                    this.setState({ textareaContent1: result[0].response_1, textareaContent2: result[0].response_2, foundAnswer: true, answerId: result[0].answer_id, prevResponse1: result[0].response_1, prevResponse2: result[0].response_2 })
                } else {
                    this.setState({ textareaContent1: "", textareaContent2: "", foundAnswer: false, answerId: "", prevResponse1: '', prevResponse2: '' })
                }
            })
    }

    highLight = () => {
        document.execCommand("foreColor", false, "#FF0000");
        if (this.state.textareaContent2.length > 0) {
            let data = `${this.state.textareaContent2}
            <br/>
            ${window.getSelection().toString()}`
            this.setState({ textareaContent2: data })
        } else {
            this.setState({ textareaContent2: window.getSelection().toString() });
        }
    }

    go = () => {
        let n = this.state.counter + 1;
        let answer = this.state.foundAnswer;
        this.setState({ counter: n });
        let questionsArray = this.state.question;
        let token = this.state.userToken;
        if (n < questionsArray.length) {
            if (!answer) {
                this.postAnswerApiCall(token, this.state.question_ID, this.state.textareaContent1, this.state.textareaContent2)
            }
            else {
                if (this.state.prevResponse1 !== this.state.textareaContent1 || this.state.prevResponse2 !== this.state.textareaContent2) {
                    this.updateAnswersApiCall(token, this.state.answerId, this.state.textareaContent1, this.state.textareaContent2)
                }
                else {
                    console.log("not calling update answer api");
                }
            }
            this.setState({ ques: this.state.question[n].name, question_ID: this.state.question[n].question_id });
            this.getAnswersApiCall(questionsArray[n].question_id, token);
        } else if (n === questionsArray.length) {
            if (!answer) {
                this.postAnswerApiCall(token, this.state.question_ID, this.state.textareaContent1, this.state.textareaContent2, this.state.userToken)
                this.props.history.push({
                    pathname: '/continue',
                    state: { completed: true }
                })
            } else {
                if (this.state.prevResponse1 !== this.state.textareaContent1 || this.state.prevResponse2 !== this.state.textareaContent2) {
                    this.updateAnswersApiCall(token, this.state.answerId, this.state.textareaContent1, this.state.textareaContent2)
                }
                else {
                    console.log("not calling update answer api");
                }
                //navigate to some other screen
                console.log("not calling length equal else");
                // this.props.history.push({ pathname: '/science' })
                this.props.history.push({
                    pathname: '/continue',
                    state: { completed: true }
                })
            }
        }
        else {
            console.log("not calling else");
        }
    }

    postAnswerApiCall = (token, questionId, resp1, resp2) => {
        console.log("quesID", this.state.questionId)
        if (resp1 && resp2 !== "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.state.userToken}` },
                body: JSON.stringify({
                    question_id: questionId,
                    response_1: resp1,
                    response_2: resp2

                })
            }
            fetch(postAnswer, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.success === "true") {
                            this.setState({ textareaContent1: "", textareaContent2: "" })
                            console.log(result)
                            this.notifySuccess(result.message)
                        }
                        else {
                            console.log(result)
                            this.notifyWarning(result.message)
                        }
                    },
                    (error) => {
                        console.log("error", error)
                    }
                )
        }
    }

    notifySuccess = (text) => toast.success(text, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { }
    });

    notifyWarning = (text) => toast.warning(text, {
        hideProgressBar: false,
        autoClose: 1000,
    });

    render() {
        return (
            <div className="test-container col-sm-12 padding-0">
                <Header header={this.state.sessionName} />
                <div className="test-header">
                    <p className="artQuestion">{this.state.ques ? this.state.ques : 'Arts'}</p>
                </div>
                {this.state.question.length > 0
                    ?
                    <div className="testChild">
                        <header className="test-link-div1">
                            <div>
                                <button className="highlightBtn" onClick={this.highLight}>Highlight</button>
                            </div>
                            <ContentEditable
                                className="textEditor content-editor-one"
                                style={{ height: "100%", color: 'black', overflow: "auto" }}

                                innerRef={this.contentEditable}
                                html={this.state.textareaContent1}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textareaContent1: e.target.value })
                                }}
                            />
                        </header>
                        <br></br>
                        <header className="test-link-div2">
                            <ContentEditable
                                className="textEditor content-editor-two"
                                style={{ height: "100%", color: 'black', overflow: "auto" }}

                                html={this.state.textareaContent2}
                                disabled={false}
                                onChange={(e) => {
                                    this.setState({ textareaContent2: e.target.value })
                                }}
                            />
                        </header>
                        <button id="btn" onClick={this.go}>Next</button>
                    </div>
                    :
                    <div className="test-header">
                        <p className="artQuestion">No Questions found</p>
                        <br></br>
                        <button onClick={() => {
                            this.props.history.push('/science')

                        }}>Go Back</button>
                    </div>

                }
                <ToastContainer hideProgressBar={false} ></ToastContainer>
            </div>


            // <div className="artContainer">
            //     <div className="question">
            //         <Question1 name={this.state.ques} />
            //     </div>


            //     <div className="testChild" >
            //         <header className="App-header">

            //             <button className="highlightBtn" onClick={this.highLight}>Highlight</button>
            //             <p></p>
            //             <ContentEditable
            //                 className="textEditor"
            //                 innerRef={this.contentEditable}
            //                 html={this.state.textareaContent1}
            //                 disabled={false}
            //                 style={{ height: "100%", overflowY: 'scroll' }}
            //                 onChange={(e) => {
            //                     this.setState({ textareaContent1: e.target.value })
            //                 }}
            //             />
            //         </header>
            //         <br></br>
            //         <header className="App-header">
            //             <ContentEditable
            //                 className="textEditor"
            //                 html={this.state.textareaContent2}
            //                 style={{ height: "100%", overflowY: 'scroll' }}
            //                 disabled={false}
            //                 onChange={(e) => {
            //                     this.setState({ textareaContent2: e.target.value })
            //                 }}
            //             />
            //         </header>
            //         <button className="buttonContainer" onClick={this.go}>Next</button>
            //     </div>

            // <ToastContainer hideProgressBar={false}
            // >
            // </ToastContainer>
            // </div>


        )
    }
}

export default withRouter(Art)