import React from 'react'
import './Card-style.css'
import { withRouter } from 'react-router-dom';
import { buyService } from '../../constants/api-config'

const Card = (props) => {
    const { serviceId, history, imgsrc, title, description, price, subscribed, priceId } = props;
    const value = localStorage.getItem('token');
    const redirectTo = () => {
        if (serviceId) localStorage.setItem('id', serviceId);
        console.log(serviceId)
        history.push('/page4');
    }
    return (
        <div className="image-container">
            <img className="img img-fluid" src={imgsrc} alt="image1" style={{height:"240px",borderTopLeftRadius: "12px",borderTopRightRadius: "12px"}} />
            <h4 className="h-title">{title}</h4>
            <p className="text-secondary">{description}</p>
            <h5 className="h-title">Rs.{price}</h5>
            {!subscribed && <a href={buyService + priceId + '&token=' + value} target="_blank" className="btn btn-outline-success">Buy</a>}
            {subscribed && <a className="btn btn-outline-success" onClick={() => redirectTo()}>Go To Service</a>}

        </div>
    )
}

export default withRouter(Card);