import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CDN } from "../../constants/api-config";
import "./pdfviewer.css";
import DirectiveLoader from "../Functional-component/Loader/loader.component";

export default function PDFViewer() {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const location = useLocation();
  const { data } = location.state;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setloading] = useState(true);
  // const [pageWidth, setpageWidth] = useState(window.innerWidth);
  let pageWidth = window.innerWidth - 255;

  const canvasRef = useRef();


  if (window.innerWidth < 769) {
    pageWidth = isPortrait ? window.innerWidth - 25 : window.innerWidth - 255;
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setloading(false);
  }

  // width: 348px;
  // height: 260px;

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div className="mainPDFContainer">
      <Document
        file={CDN + data.pdffile}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={() => (
          <div style={{ marginTop: "25%", marginLeft: "47%" }}>
            <DirectiveLoader />
            <h5>Loading Pdf . . .</h5>
          </div>
        )}
      >
        <Page pageNumber={pageNumber} width={pageWidth} canvasRef={canvasRef} />
      </Document>

      {!loading && (
        <div class="page-controls">
          <div>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              ‹
            </button>
            <span>
              {pageNumber} of {numPages}
            </span>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              ›
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
