import React from "react";
import Card from "./Card";
import "./paradigm.css";
import { CDN } from "../../constants/api-config";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import PdfViewer from "./PdfViewer1";
import { useParams, useHistory } from "react-router-dom";
import PageNotFound from "../PageNotFound";
import Header from "../Functional-component/Header/Header";

export const SessionLists = ({
  item,
  showCardsHandler,
  sessionTitleHandler,
  sessionIDHandler,
}) => {
  console.log({ list: item });
  return (
    // <div className="paradigm_container">
    <Card
      src={CDN + item.thumbnail}
      onClick={() => {
        sessionIDHandler(item.session_id);
        showCardsHandler(true);
        sessionTitleHandler(item.name);
      }}
    >
      {item.name}
    </Card>
    // </div>
  );
};

const Article = () => {
  const location = useLocation();
  return (
    <div className="paradigm_articleList">
      <h1 className="paradigm_title-heading">{location.state.data.title}</h1>
      {parse(location.state.data.content)}
    </div>
  );
};

export const ArticleLists = ({ item }) => {
  const history = useHistory();

  return (
    // <div className="paradigm_container">
    <Card
      src={CDN + item.thumbnail}
      onClick={() => history.push("/video/article-content", { data: item })}
    >
      {item.title}
    </Card>
    // </div>
  );
};

// export const PDFLists = ({ item }) => {
//   const history = useHistory();
//   console.log({ pdf: item.downloadable });

//   return (
//     // <div className="paradigm_container">
//     <div>
//       <Card
//         src={CDN + item.thumbnail}
//         content={item.description}
//         downloadLink={CDN + item.pdffile}
//         onClick={() => history.push("/video/pdf-viewer", { data: item })}
//         className="paradigm_pdfCard"
//       >
//         {item.title}
//       </Card>
//       {item.downloadable && (
//         <a href={CDN + item.pdffile} download>
//           <button className="paradigm__card_button" type="button">
//             Download
//           </button>
//         </a>
//       )}
//     </div>
//     //<PdfViewer item={item} />
//     // </div>
//   );
// };

export const PDFLists = ({ item }) => {
  const history = useHistory();
  console.log({ pdf: item.downloadable, item });

  return (
    <div>
      <div
        className="paradigm__cardContainer paradigm_pdfCard"
        onClick={() => history.push("/video/pdf-viewer", { data: item })}
      >
        <img
          src={CDN + item.thumbnail}
          alt=""
          className="paradigm__PDFcard_img"
        />
        <h3>
          {item.title.length > 26
            ? `${item.title.slice(0, 26)} . .`
            : item.title}
        </h3>
        {item.description && (
          <p className="paradigm__pdfContainer_p">
            {item.description.slice(0, 100) + ". ."}
          </p>
        )}
      </div>
      {item.downloadable && (
        <a href={CDN + item.pdffile} download>
          <button className="paradigm__card_button" type="button">
            Download
          </button>
        </a>
      )}
    </div>
  );
};
export const VideoLists = ({ item }) => {
  const videoID = item.video_url.includes("youtu.be")
    ? item.video_url.slice(17)
    : item.video_url.includes("youtube")
    ? item.video_url.slice(32)
    : item.video_url;

  console.log({ videosList: item, videoID });

  return (
    <div className="paradigm_container ">
      {item.video_source_type === "YouTube" ? (
        <div className="paradigm_videoCard  ">
          <iframe
            // width="560"
            // height="315"
            src={`https://www.youtube.com/embed/${videoID}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <a href={`https://www.youtube.com/watch?v=${videoID}`}>
            <h3>{item.heading}</h3>
          </a>
        </div>
      ) : (
        <a
          href={item.video_url}
          target="_blank"
          rel="noreferrer"
          // className="paradigm_videoCard_a"
        >
          <div className="paradigm__cardContainer paradigm_vid_Box">
            <img
              src={CDN + item.thumbnail}
              alt=""
              className="paradigm__card_img"
            />
            <h3>{item.heading}</h3>
          </div>
        </a>
      )}
    </div>
  );
};

const Lists = () => {
  const { screen } = useParams();
  const location = useLocation();
  console.log({ screen, location });
  switch (screen) {
    case "session":
      return <SessionLists />;
    // case "sessionCard":
    //   return <SessionCards />;
    case "article":
      return (
        <>
          <Header header={`Articles - ${location.state.sessionTitle}`} />
          <div className="paradigm_parentcontainer">
            <div className="paradigm_container">
              <div className="paradigm_video-container">
                {location.state.articles.response.map((item, i) => (
                  <ArticleLists item={item} key={i} />
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "article-content":
      return (
        <>
          {/* <Header header="Articles" /> */}
          <div className="paradigm_parentcontainer">
            <div className="paradigm_container">
              <Article />
            </div>
          </div>
        </>
      );
    case "video-list":
      return (
        <>
          <Header header={`Videos - ${location.state.sessionTitle}`} />
          <div className="paradigm_video-container">
            {location.state.videos.response.map((item, i) => (
              <VideoLists item={item} key={i} />
            ))}
          </div>
        </>
      );
    case "pdf":
      return (
        <>
          <Header header={`PDFs - ${location.state.sessionTitle}`} />

          <div className="paradigm_pdfParentContainer">
            <div className="paradigm_container">
              <div className="paradigm_video-container">
                {location.state.pdfs.response.map((item, i) => (
                  <PDFLists item={item} key={i} />
                ))}
              </div>
            </div>
          </div>
        </>
      );
    case "pdf-viewer":
      return (
        <div className="paradigm_container">
          <PdfViewer />
        </div>
      );
    default:
      return <PageNotFound />;
  }
};

export default Lists;
