/** Review all project with mupp, picos*/
import React, { Component } from 'react'
import './mupp-picos-project.css';
import { getMuppPicosProjects } from '../../constants/api-config';
import Header from '../Functional-component/Header/Header'

export default class ProjectReview extends Component {
    constructor(props) {
        super(props);
        this.value = localStorage.getItem('token');
        this.id = localStorage.getItem('id');
        this.state = {
            results: []
        }
    }

    componentDidMount = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        fetch(getMuppPicosProjects + "/?service=" + this.id, {
            "method": "GET",
            "headers": {
                'Content-Type': 'application/json', "Authorization": `JWT ${this.value}`,
            },
        })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    results: response
                });
                console.log(this.state.results)
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="project-review">
                <Header header="Review" />
                <div className="col-sm-12 table-responsive" style={{ marginLeft: "10px", paddingLeft: "4px" ,overflowX:'hidden'}}>
                    <table className="table table-bordered" style={{maxHeight:100,overflowY:"auto"}}>
                      

                       
                        <thead>
                            <tr>
                                <th>Mupp</th>
                                <th>Picos</th>
                                <th>Projects</th>
                            </tr>
                        </thead>
                       
                        <tbody>
                            {(() => {
                                if (this.state.results && this.state.results.length > 0) {
                                    return (
                                        this.state.results.map((data, i) => (
                                              
                                           <tr >
                                                <td className="mupptitle">
                                                <div style={{ minHeight: '20vh', maxHeight: "50vh",overflowY:'auto'}}>

                                                Path - {data.mupp_path}
                                                    <br />
                                                    Description - {data.picos_description}
                                                </div>
                                                   
                                                </td>
                                                <td className="mupptitle">
                                                <div style={{ minHeight: '20vh', maxHeight: "50vh",overflowY:'auto'}}>

                                                Path - {data.picos_heading}
                                                    <br />
                                                    Description - {data.picos_description}
</div>
                                                  
                                                </td>
                                                <td className="padd-0">
                                                <div style={{ minHeight: '20vh', maxHeight: "50vh" ,overflowY:'auto'}}>

                                                    <table className="table table-bordered project-review-inner-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Rank</th>
                                                                <th>Important</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.projects.map((project, i) => (
                                                                <tr key={i}>
                                                                    <td>{project.title ? project.title : 'NA'}</td>
                                                                    <td>{project.rank ? project.rank : '0'}</td>
                                                                    <td>{project.important ? 'Y' : 'N'}</td>
                                                                </tr>
                                                            ))
                                                            }
                                                        </tbody>
                                                    </table>
                                               </div>
                                                </td>
                                          
                                            </tr>
                                        
                                        ))
                                    )
                                } else {
                                    return (
                                        <tr>
                                            <td colSpan="3" className="no-result-found">No Results Found</td>
                                        </tr>
                                    )
                                }
                            })()}
                        </tbody>
                    </table>
                    <div style={{ textAlign: "end" }}>
                    <button onClick={() => {this.props.history.push('/page4')}}>Back</button>
                </div>
                </div>
            </div>
        )
    }
}

// let styles = {

//     textMultiLineLimit: {
//         display: '-webkit-box',
//         WebkitBoxOrient: 'vertical',
//         WebkitLineClamp: 5,
//         overflow: 'scroll',
//         textOverflow: 'scroll'
//     }
// }