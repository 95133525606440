import React, { Component } from 'react'
import './Forget.css'
import { resetPassword } from '../../constants/api-config'
export default class Forget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            repassword: '',
            token: ''
        }
        this.handlePassword = this.handlePassword.bind(this)
        this.handleRepassword = this.handleRepassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        let url = window.location.href
        let arr = url.toString()
        let arr1 = arr.split("/")
        let value = arr1[4]
        this.setToken(value)
    }

    // function that set token
    setToken(value) {
        this.setState({ token: value })
    }

    // function that handle password
    handlePassword = async function (e) {
        await this.setState({ password: e.target.value })
    }

    // function that handle Repassword
    handleRepassword = async function (e) {
        await this.setState({ repassword: e.target.value })
    }

    // function that handle submit
    handleSubmit() {
        const { password, repassword } = this.state;
        if (password !== repassword) {
            alert("Passwords doesn't match");
        }
        else{
            console.log("Password matched");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: this.state.token,
                password: this.state.password
            })
        }

        // API call for resetPassword
        fetch(resetPassword, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === "true") {
                        alert("Password reset successfully")
                        this.props.history.push('/sign')
                    }
                },
                (error) => {
                }
            )
     }
    }


    render() {
        return (
            <div className="parent">
                <div className="child">
                    <h3>Reset Password</h3>
                    <input className="forgetInput" name="password" type="password" placeholder="Password" onChange={this.handlePassword} />
                    <br />
                    <input className="forgetInput" name="repassword" type="password" placeholder="Repassword" onChange={this.handleRepassword} />
                    <br />
                    <button onClick={this.handleSubmit} >Submit</button>
                </div>
            </div>
        )
    }
}
