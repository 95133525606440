import React, { useEffect, useState } from 'react'
import Picosmodal from '../Functional-component/add-picos-modal/Picosmodal';
import Editpicosmodal from '../Functional-component/edit-picos-modal/Editpicosmodal';

import Addtask from '../Functional-component/Add-task/Addtask';
import { picos,CDN } from "../../constants/api-config";

import Header from '../Functional-component/Header/Header'
import DirectiveLoader from '../Functional-component/Loader/loader.component';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Picos = (props) => {
    const [heading, setHeading] = useState("");
    const [description, setDescription] = useState("");
    const [picosId, setPicosId] = useState(0);
    const [startLoading, setStartLoading] = useState(true);
    const [photoOne, setPhotoOne] = useState(null);
    const [photoTwo, setPhotoTwo] = useState(null);
    const [picosFound, setPicosFound] = useState(false);
    const [token, setToken] = useState("");
    const [muppId, setMuppId] = useState("");

    const [AddPicosModal, setAddPicosModal] = useState(false);
    const [EditPicosModal, setEditPicosModal] = useState(false);
    const [ZoomImageModal1, setZoomImageModal1] = useState(false);
    const [ZoomImageModal2, setZoomImageModal2] = useState(false);





    const getPicosApiCall = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${token}` },

        }
        fetch(`${picos}?mupp=${muppId}`, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {

                    let flag = Object.keys(result).length === 0 && result.constructor === Object;

                    if (flag) {
                        setPicosFound(false);
                        setDescription(" ");
                        setHeading(" ");
                        setPhotoOne("");
                        setPhotoTwo("");
                        setPicosId(0);
                    } else {
                        setPicosFound(true);
                        setDescription(result.description);
                        setHeading(result.heading);
                        setPhotoOne(result.image1_url);
                        setPhotoTwo(result.image2_url);
                        setPicosId(result.id);
                        console.log("result.id", result.id);
                    }
                    setStartLoading(false);
                },
                (error) => {
                    console.log("error", error)
                }
            )


    }

    useEffect(() => {
        let tkn = localStorage.getItem('token')
        let data = props.location.state.muppId;
        localStorage.setItem('muppid', data)

        setMuppId(data);
        setToken(tkn);
    }, []);

    useEffect(() => {
        if (muppId) {
            getPicosApiCall();
        }
    }, [muppId, token])


    const onClose = () => {
        setAddPicosModal(false)
    }


    const onCloseEditModal = () => {
        setEditPicosModal(false)
    }

    const editModal = () => {
        onCloseEditModal()
        getPicosApiCall()
    }


    const addModal = () => {
        getPicosApiCall()
        onClose()
    }

    const onCloseZoomImageModal1 = () =>{
        setZoomImageModal1(false)
    }
    const onCloseZoomImageModal2 = () =>{
        setZoomImageModal2(false)
    }


    if (startLoading) {
        return (<div>
            <Header header={"Picos"} />
            <div style={{ marginTop: "25%", marginLeft: "47%" }}>
                <DirectiveLoader />
            </div>
        </div>)
    }


    return (
        <div>
            <Header header={"Picos"} />
            {picosFound ?
                <ul>
                    <li className=" this-week-li" >
                        <div>
                            <i className="fa fa-edit" style={{ marginRight: '5%' }} onClick={() => {
                                setEditPicosModal(true);
                                console.log("opening edit modal");
                            }}></i>


                        </div>

                    </li>
                    <li className="all-categories-li this-week-li" >
                        <div>
                            <h4> Picos Description</h4>
                            <h5> {heading}</h5>

                        </div>

                    </li>
                    <li className="all-categories-li this-week-li" >
                        <div>
                            <h4> Picos Instance</h4>
                            <h5> {description}</h5>
                        </div>

                    </li>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ marginRight: "15%", marginTop: "5%" }}>
                            {photoOne && (
                                <div >
                                    <img height="250" width="250" 
                                        style={{borderRadius:"5px",cursor:"pointer"}}
                                        src={`${CDN}${photoOne}`}
                                        onClick={() => {setZoomImageModal1(true);
                                            console.log("SETZOOMIMAGEMODALTRUE");}}
                                    />

                                    {ZoomImageModal1 && (
                                        <Lightbox
                                            mainSrc={`${CDN}${photoOne}`}
                                            onCloseRequest={onCloseZoomImageModal1}
                                         />
                                    )}  


                                </div>
                            )}

                        </div>
                        <div style={{ marginTop: '5%' }}>

                            {photoTwo && (
                                <div>
                                    <img height="250" width="250"
                                        style={{borderRadius:"5px",cursor:"pointer"}}
                                        src={`${CDN}${photoTwo}`}
                                        onClick={() => {setZoomImageModal2(true);
                                            console.log("SETZOOMIMAGEMODALTRUE");}}
                                    />


                                    {ZoomImageModal2 && (
                                        <Lightbox
                                            mainSrc={`${CDN}${photoTwo}`}
                                            onCloseRequest={onCloseZoomImageModal2}
                                         />
                                    )}  

                                </div>
                            )}
                        </div>
                    </div>



                </ul>
                :
                <ul>
                    <li className=" this-week-li" >
                        <div>
                            <i className="fa fa-plus" style={{ marginRight: '5%' }} onClick={() => {
                                setAddPicosModal(true);
                            }} ></i>


                        </div>

                    </li>
                    <li className="all-categories-li this-week-li" >
                        <div>
                            <h4>No Picos Found</h4>
                        </div>

                    </li>

                </ul>
            }
            <Picosmodal
                addModal={addModal}
                open={AddPicosModal} muppId={muppId} token={token} onClose={onClose} onCloseModal={onClose} />
            <Editpicosmodal
                editModal={editModal}
                open={EditPicosModal} muppId={muppId} token={token} heading={heading} description={description} picosId={picosId} onCloseModal={onCloseEditModal} />

        </div>
    )

};

export default Picos;
