import React, { useState } from "react";
import MenuVertical from "../../assests/icons8-menu-vertical-30.png";
import "./journal.css";
import { Modal } from "react-responsive-modal";
import { dailyJournalPass } from "../../constants/api-config";
import axios from "axios";
import { toast } from "react-toastify";

const notifyWarning = (text) =>
  toast.warning(text, {
    hideProgressBar: false,
    autoClose: 3000,
  });
const notifySuccess = (text) =>
  toast.success(text, {
    hideProgressBar: false,
    autoClose: 2000,
  });

export const resetPasscodehandler = async (oldPasscode, newPasscode) => {
  let token = localStorage.getItem("token");

  try {
    const { data } = await axios.put(
      dailyJournalPass,
      {
        old_passcode: oldPasscode,
        new_passcode: newPasscode,
      },
      {
        headers: { Authorization: `JWT ${token}` },
      }
    );
    console.log({ resetpasscode: data });
    notifySuccess(data.message);
  } catch ({ response: { data } }) {
    console.error(data);
    notifyWarning(data.message);
  }
};

export const EditPasscodeModal = ({
  seteditPasscodeModal,
  editPasscodeModal,
}) => {
  const [oldPasscode, setoldPasscode] = useState("");
  const [newPasscode, setnewPasscode] = useState("");
  return (
    <Modal
      open={editPasscodeModal}
      onClose={() => seteditPasscodeModal(false)}
      onOpen={() => seteditPasscodeModal(true)}
    >
      <div style={{ width: "25vw" }}>
        <input
          type="text"
          placeholder="Enter Old Passcode"
          className="form-control my-3"
          onChange={(e) => setoldPasscode(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter New Passcode"
          className="form-control my-3"
          onChange={(e) => setnewPasscode(e.target.value)}
        />
        <button
          type="button"
          className="log__monthlymodalButton"
          onClick={() => resetPasscodehandler(oldPasscode, newPasscode)}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default function Addtask(props) {
  const [modal, setmodal] = useState(false);
  const [editPasscodeModal, seteditPasscodeModal] = useState(false);

  return (
    <>
      <div className="col-sm-12 add-task-header">
        <h4>{props.Addtask}</h4>
        <div>
          <button onClick={props.onClick}>{props.buttonname}</button>
          <img
            src={MenuVertical}
            alt=""
            className="menuVertical"
            onClick={() => setmodal(!modal)}
          />
          {modal && (
            <div
              className="menuVertical_dropdown"
              onClick={() => seteditPasscodeModal(true)}
            >
              Reset passcode
            </div>
          )}
          <EditPasscodeModal
            seteditPasscodeModal={seteditPasscodeModal}
            editPasscodeModal={editPasscodeModal}
          />
        </div>
      </div>
    </>
  );
}
