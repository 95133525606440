import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { getBackup, notification } from "../../constants/api-config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutsideClick from "./OutsideClick";

// export function OutsideClick(ref) {
//   const [isClicked, setIsClicked] = useState();
//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         setIsClicked(true);
//       } else {
//         setIsClicked(false);
//       }
//     }

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [ref]);
//   return isClicked;
// }

export default function SideBarComponent() {
  const [todayBadge, settodayBadge] = useState(0);
  const [todayReminder, settodayReminder] = useState(0);
  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);
  if (boxOutsideClick) closeNav();

  function openNav() {
    console.log("cliking opne");
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  }

  function closeNav() {
    if (window.innerWidth <= 850) {
      console.log("minimizing !!!!!");
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    }
  }
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        openNav();
        document.getElementById("main").style.marginLeft = "0";
      }
    };

    getNotificationApiCall();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getNotificationApiCall() {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(notification, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        console.log({ notification: res });
        settodayBadge(res.total_tasks_sum);
        settodayReminder(res.total_reminder);
      })
      .catch((err) => {
        console.error({ notification: err.response });
      });
  }

  function logOut() {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
      window.location.replace("/sign");
    }
  }

  setTimeout(() => {
    let lastTokenTime = localStorage.getItem("lastTokenTime");
    let currentTime = new Date().getTime();
    let timeDifference = currentTime - lastTokenTime;
    if (timeDifference > 43200000) logOut();
  }, 1000);

  function downloadBackup() {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getBackup, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "True") {
            // notifySuccess("File downloading started")
            if (window.confirm("Do you to download backup file now")) {
              window.open(
                "https://d1xgj9xx6egt0d.cloudfront.net/backup/" +
                  result.response,
                "_blank"
              );
            }
          }
        },
        (error) => {
          console.log("error in download backup", error);
        }
      );
  }

  const notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  return (
    <div ref={boxRef}>
      <div id="mySidebar" className="mob_sidebar">
        <div className="closebtn" onClick={closeNav}>
          <span className="closebutton_link">×</span>
        </div>
        <NavLink onClick={closeNav} to="/cards">
          Home
        </NavLink>
        <NavLink onClick={closeNav} to="/mupps">
          MUPPs
        </NavLink>
        <NavLink onClick={closeNav} to="/learning_log">
          Learning Log
        </NavLink>
        <NavLink onClick={closeNav} to="/daily_journal">
          Daily Journal
        </NavLink>
        <NavLink onClick={closeNav} to="/todos">
          <div className="sidebar__notication">
            Today
            <div>
              <span>{todayReminder}</span>
              <span>{todayBadge}</span>
            </div>
          </div>
        </NavLink>
        <NavLink onClick={closeNav} to="/inbox">
          Inbox
        </NavLink>
        <NavLink onClick={closeNav} to="/search">
          Search
        </NavLink>
        <NavLink onClick={closeNav} to="/calendar">
          Calendar
        </NavLink>
        <NavLink onClick={closeNav} to="/this-week">
          This week
        </NavLink>
        <NavLink onClick={closeNav} to="/waiting">
          Awaiting
        </NavLink>

        <NavLink onClick={closeNav} to="/daily-habbit">
          Daily Habits
        </NavLink>
        <NavLink onClick={closeNav} to="/monthly-reminder">
          Monthly Reminder
        </NavLink>
        <NavLink onClick={closeNav} to="/later">
          Later
        </NavLink>
        <a onClick={downloadBackup} style={{ color: "#293846" }}>
          Download Backup
        </a>
        <NavLink onClick={closeNav} to="/sign" onClick={logOut}>
          Logout
        </NavLink>
      </div>

      <div className="d-block " id="main"  >
        <button className="openbtn" onClick={openNav}>
          ☰
        </button>
      </div>

      <ToastContainer hideProgressBar={false}></ToastContainer>
    </div>
  );
}
