import React, { Component } from "react";
import {
  getTasksByType,
  selectTaskForToday,
  unselectTaskTodayAPI,
  deleteTask,
  getTasksByProject,
  getAllMupps,
  getMuppCategories,
  getProject,
  completeTask,
  postTask,
} from "../../constants/api-config";
import "./laterStyle.css";
import Calendar from "../../assests/calendar.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import Header from "../Functional-component/Header/Header";
import DirectiveLoader from "../Functional-component/Loader/loader.component";
import { LATER } from "../../constants/app-defaults";
import DeleteTask from "../shared/deleteTask";
import EditTask from "../shared/editTask";
import MoveTask from "../shared/moveTask";
import CompleteTask from "../shared/completeTask";
import Movefolder from "../../assests/moveFolder.png";
import Taskmodal from "../Functional-component/task-modal/Taskmodal";

const Tabs = ({ active, count, onClick }) => {
  return (
    <li onClick={onClick} className={active ? "active" : ""}>
      {count}
    </li>
  );
};

class Waiting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muppCategoriesResult: [],
      isLoded: false,
      getTaskData: [],
      isSelected: false,
      chosen: "Personal",
      change: false,
      index: 0,
      isLoading: false,
      categoryID: "",
      editTaskName: "",
      editTaskType: [],
      editTaskDate: "",
      taskID: "",
      editTaskVisibleDate: null,
      muppCategoryData: [],
      personalMuppPathData: [],
      officalMuppPathData: [],
      selectedCategory: "",
      selectedPathID: "",
      projectName: "",
      muppPath: "",
      muppID: "",
      isOffical: false,
      projectData: [],
      projectId: "",
      inboxId: "",
      inboxName: "",
      taskType: "THIS_WEEK",
      taskDate: null,

      muppData: [],
      muppPathData: [],
      isModalVisibile: false,
      taskName: "",
      completed: false,
      //complete
      completeCreateTaskName: "",
      completeCreateTaskType: "",
      completeCreateVisibleDate: "",
      completeCreateTaskDueDate: "",
      completeCreateProjectId: "",
      completeCreateMuppId: "",
      completeCreateTaskId: "",
      //moveStates
      moveTaskName: "",
      moveTaskDate: "",
      moveTaskId: "",
      moveTaskType: "",
      moveMuppCategoryId: "",
      moveMuppPath: "",
      moveProjectId: "",
      moveProjectName: "",
      moveMuppID: "",
    };
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }
  //Function is calling on onLoad and fetching the getMuppCategories and getTasksByType if exist.
  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.APIcall();
    this.getMuppCategoriesApiCall();
    //ReactTooltip.rebuild();
  }

  APIcall = () => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getMuppCategories, requestOptions)
      .then((res) => res.json())
      .then(
        async (result) => {
          console.log("res", result);
          await this.setState({
            muppCategoriesResult: result.category,
            isLoading: true,
            chosen: result.category[0].name,
            categoryID: result.category[0].category_id,
          });
          this.fetchTaskData();
        },
        (error) => {
          console.log(
            "error in getMuppCategories api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  fetchTaskData = () => {
    //preparing task API according to the category_id coming in value
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    let finalURL =
      getTasksByType +
      "?" +
      "category=" +
      this.state.categoryID +
      "&task_type=" +
      "LATER";
    fetch(finalURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let isData = Object.keys(result).length === 0;
          console.log("data", result.tasks);

          if (isData) {
            this.setState({ getTaskData: [] });
          } else {
            let res = result.tasks;
            let arr = res.filter((item) => !item.completed);
            this.setState({ getTaskData: arr, isLoading: true });
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  selectTaskTodayAPI = (id) => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(selectTaskForToday, requestOptions) // moveTask = (item) => {
      //   console.log("move task click",item)
      //   this.setState({ inboxId: item.inbox_id, inboxName: item.name })
      //   //localStorage.setItem("task", JSON.stringify(task));
      //   document.getElementById('moveTaskRightSlidePanel').style.display = 'block';
      //   document.getElementById('moveTaskCloseIcon').style.display = 'block';
      //   this.getAllMuppsAPICall()
      // }
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.fetchTaskData();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  unselectTaskTodayAPI = (id) => {
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(unselectTaskTodayAPI, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.fetchTaskData();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  deleteTaskApicall = (id) => {
    let deleteTaskURL = deleteTask + "?task_id=" + id;

    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(deleteTaskURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("res3", result);
          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.fetchTaskData();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  updateTask = (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    var data = {
      task_id: this.state.taskID,
      task_name: this.state.editTaskName,
      date: this.state.editTaskVisibleDate ? this.state.editTaskDate : null,
      task_type: this.state.editTaskType,
      completed: false,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify(data),
    };
    fetch(getTasksByProject, requestOptions)
      .then((response) => response.json())
      .then(async (response) => {
        console.log("response after updating ", response.data);
        await this.closePanelForEdit();
        if (response.success === "true") {
          this.notifySuccess(response.message);
          this.fetchTaskData();
        }
        // document.getElementById('rightSlidePanel').style.display = 'none';
        document.getElementById("editTaskNameTextArea").value = "";
      })
      .catch((err) => {
        //this.notifyWarning("Something Went Wrong");
      });
  };

  getAllMuppsAPICall = () => {
    let token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getAllMupps, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("res3", result);
          this.setState({ muppData: result });
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  getMuppCategoriesApiCall = () => {
    console.log("waiting mount");
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };

    fetch(getMuppCategories, requestOptions)
      .then((res) => res.json())
      .then(
        async (result) => {
          console.log(result);
          await this.setState({ muppCategoryData: result.category });
          console.log("mupp", this.state.muppCategoryData);
        },
        (error) => {
          console.log(
            "error in getMuppCategories api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  getProjectApiCall = (id) => {
    let getProjectURL = getProject + "?mupp=" + id;

    let token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    fetch(getProjectURL, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("res3", result);
          if (result.projects === undefined) {
            console.log("kuch mat save kro");
          } else {
            this.setState({ projectData: result.projects });
            console.log("project", this.state.projectData);
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  selectTask = (item) => {
    if (item.selected) {
      this.unselectTaskTodayAPI(item.task_id);
      console.log("unselect");
    } else {
      this.selectTaskTodayAPI(item.task_id);
    }
  };

  notifyWarning = (text) =>
    toast.warning(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  notifySuccess = (text) =>
    toast.success(text, {
      hideProgressBar: false,
      autoClose: 1000,
    });

  toggle = async (item, index) => {
    await this.setState({
      chosen: item.name,
      index: index,
      categoryID: item.category_id,
    });
    this.fetchTaskData();
    console.log(item.category_id);
    console.log(index);
  };

  deleteTask = (id) => {
    console.log("Delete TASK ID", id);
    this.deleteTaskApicall(id);
  };

  closePanelForEdit = async () => {
    await this.setState(
      { editTaskName: "", editTaskVisibleDate: "", editTaskDate: "" },
      () => {
        if (document.getElementById("editTaskNameTextArea")) {
          document.getElementById("editTaskNameTextArea").value = "";
          console.log("value is ");
          document.getElementById("rightSlidePanel").style.display = "none";
        }
      }
    );
  };

  handleEditTaskName = (e) => {
    this.setState({ editTaskName: e.target.value });
  };

  handleEditTaskType = (e) => {
    this.setState({ editTaskType: e.target.value });
  };

  handleEditDate = (e) => {
    this.setState({
      editTaskDate: e.target.value,
      editTaskVisibleDate: e.target.value,
    });
  };

  clearEditDate = () => {
    this.setState({ editTaskDate: "", editTaskVisibleDate: null });
  };

  openEditModal = async (task) => {
    await this.setState({
      editTaskName: task.task_name,
      editTaskType: task.task_type,
      editTaskDate: task.date,
      editTaskVisibleDate: task.date,
      taskID: task.task_id,
    });
    console.log(task);
    document.getElementById("rightSlidePanel").style.display = "block";
    document.getElementById("editTaskNameTextArea").value = task.task_name;
    document.getElementById("editTaskDateTextArea").value = task.date;
  };

  moveTask = async (item) => {
    console.log("item before move is ", item);

    console.log("item.mupp_category_id ", item.mupp_category_id);
    // await this.setState({
    //   taskID: item.task_id,
    //   editTaskName: item.task_name,
    //   editTaskType: item.task_type,
    //   editTaskDate: item.date,
    //   projectName: item.project_name,
    //   muppPath: item.mupp_path,
    //   muppID: item.mupp_id,
    //   projectId: item.Project_id,
    // });

    await this.setState({
      moveTaskName: item.task_name,
      moveTaskDate: item.date,
      moveTaskId: item.task_id,
      moveTaskType: item.task_type,
      moveMuppCategoryId: item.mupp_category_id,
      moveMuppPath: item.mupp_path,
      moveProjectId: item.Project_id,
      moveProjectName: item.project_name,
      moveMuppID: item.mupp_id,
    });

    document.getElementById("moveTaskRightSlidePanel").style.display = "block";
    document.getElementById("moveTaskCloseIcon").style.display = "block";
  };

  closePanel = async () => {
    console.log("closed");
    document.getElementById("moveTaskRightSlidePanel").style.display = "none";
    document.getElementById("moveTaskCloseIcon").style.display = "none";
    await this.setState({
      moveTaskName: "",
      moveTaskDate: "",
      moveTaskId: "",
      moveTaskType: "",
      moveMuppCategoryId: "",
      moveMuppPath: "",
      moveProjectId: "",
      moveProjectName: "",
      moveMuppID: "",
    });
  };

  async handleCategoryChange(e) {
    console.log("Fruit Selected!!");
    await this.setState({ selectedCategory: e.target.value });
    console.log(this.state.selectedCategory);
    console.log(this.state.muppData);

    this.state.muppData.forEach(async (item) => {
      console.log(item.category);
      if (this.state.selectedCategory === item.category) {
        console.log("mupp", item.mupps);
        if (item.mupps.length > 0) {
          console.log("value hai");
          await this.setState({ muppPathData: item.mupps });
        } else {
          console.log("nhi hai");
          await this.setState({ muppPathData: [] });
        }
      }
    });
  }

  handlePathChange = async (e) => {
    console.log(e.target.value);
    await this.setState({ selectedPathID: e.target.value, projectData: [] });
    this.getProjectApiCall(this.state.selectedPathID);
  };

  handleDate = (e) => {
    this.setState({ completeCreateTaskDueDate: e.target.value });
  };

  handleTaskType = (e) => {
    this.setState({ completeCreateTaskType: e.target.value });
  };

  handleProjectChange = (e) => {
    this.setState({ projectId: e.target.value });
  };

  handleMoveToInbox = () => {
    this.postMoveToProjectApiCall();
  };

  completeTaskAPICall = (id) => {
    console.log("task id in complete task ", id);
    let token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      body: JSON.stringify({
        task_id: id,
      }),
    };
    fetch(completeTask, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result", result);

          if (result.success === "true") {
            this.notifySuccess(result.message);
            this.fetchTaskData();
          }
        },
        (error) => {
          console.log(
            "error in getTasksByType api, file name - thisWeek.js",
            error
          );
        }
      );
  };

  taskSelect = (item) => {
    console.log("item", item);
    this.setState({
      isModalVisibile: true,
      completeCreateTaskName: item.task_name,
      completeCreateTaskType: item.task_type,
      completeCreateVisibleDate: "",
      completeCreateTaskDueDate: item.date,
      completeCreateProjectId: item.Project_id,
      completeCreateMuppId: item.mupp_id,
      completeCreateTaskId: item.task_id,
    });
  };

  onCloseCompleteModal = () => {
    this.setState({ isModalVisibile: false });
  };

  handleNow = () => {
    this.openCompleteNavR();
    this.onCloseCompleteModal();
  };

  openCompleteNavR = () => {
    document.getElementById("completeRightSlidePanel").style.display = "block";
    document.getElementById("completeCloseIcon").style.display = "block";
  };

  onCloseCompleteNavR = () => {
    console.log("hitt");
    document.getElementById("completeRightSlidePanel").style.display = "none";
    document.getElementById("completeCloseIcon").style.display = "none";
  };

  handleLater = () => {
    let id = this.state.completeCreateTaskId;
    this.completeTaskAPICall(id);
    this.onCloseCompleteModal();
  };

  handleTaskName = (e) => {
    this.setState({ completeCreateTaskName: e.target.value });
  };

  handleSubmitTask = () => {
    this.addTaskAPIcall();
    this.completeTaskAPICall(this.state.completeCreateTaskId);
    this.onCloseCompleteNavR();
  };

  addTaskAPIcall = () => {
    this.value = localStorage.getItem("token");

    var data = {
      project: this.state.completeCreateProjectId,
      name: this.state.completeCreateTaskName,
      due_date:
        this.state.completeCreateTaskDueDate === ""
          ? null
          : this.state.completeCreateTaskDueDate,
      task_type: this.state.completeCreateTaskType,
      completed: false,
      mupp: this.state.completeCreateMuppId,
    };
    console.log("data addTaskAPIcall", data);
    fetch(postTask, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${this.value}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        this.notifySuccess("Successfully");
      })
      .catch((err) => {
        this.notifyWarning("Something Went Wrong");
      });
  };

  render() {
    let { muppCategoriesResult, getTaskData } = this.state;
    let chosenMuppCategory = {};
    return (
      <div>
        <Header header="Later" />
        {this.state.isLoading ? (
          <div>
            <ul
              className="btn-nav-tabs-thisweek"
              style={{ paddingRight: "10px", marginLeft: "10px" }}
            >
              {muppCategoriesResult.map((item, index) => {
                if (item.name === this.state.chosen) {
                  chosenMuppCategory = {
                    id: item.category_id,
                    name: item.name,
                  };
                }
                return (
                  <Tabs
                    key={item.category_id}
                    count={item.name}
                    active={item.name === this.state.chosen}
                    onClick={this.toggle.bind(this, item)}
                  />
                );
              })}
            </ul>
            <ul className="all-categories-tab">
              {getTaskData.length > 0 ? (
                getTaskData.map((item, i) => {
                  return (
                    <li
                      style={{
                        borderStyle: "solid",
                        borderLeftColor: LATER,
                        borderWidth: "8px",
                      }}
                      className="all-categories-li this-week-li"
                      key={i}
                    >
                      <div className="col-sm-9 padding-0">
                        <h4
                          onClick={this.openEditModal.bind(this, item)}
                          style={styles.textMultiLineLimit}
                          data-tip={item.task_name}
                          data-for="registerTipforTask"
                        >
                          {item.task_name}
                        </h4>
                        <ReactTooltip
                          id="registerTipforTask"
                          type="info"
                          place="top"
                          effect="solid"
                        ></ReactTooltip>
                        <div className="dis-flex">
                          <label
                            data-tip={item.project_name}
                            data-for="registerTipforProject"
                            className="elipse-text"
                          >
                            {item.project_name}
                          </label>
                          <ReactTooltip
                            id="registerTipforProject"
                            type="info"
                            place="top"
                            effect="solid"
                          ></ReactTooltip>
                          <label
                            data-tip={item.mupp_path}
                            data-for="registerTipforPath"
                            className="elipse-text"
                          >
                            {item.mupp_path}
                          </label>
                          <ReactTooltip
                            id="registerTipforPath"
                            place="top"
                            type="info"
                            effect="solid"
                          ></ReactTooltip>
                          <label>Awaiting</label>
                          {item.date ? (
                            <label>
                              <img
                                src={Calendar}
                                height="25px"
                                width="25px"
                                alt=""
                              />
                              {item.date}
                            </label>
                          ) : (
                            <label>
                              <img
                                src={Calendar}
                                height="25px"
                                width="25px"
                                alt=""
                              />
                              No Date Available
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3 padding-0">
                        {item.selected ? (
                          <button
                            style={{
                              backgroundColor: "green",
                              padding: "12px 35px",
                            }}
                            onClick={this.selectTask.bind(this, item)}
                          >
                            selected
                          </button>
                        ) : (
                          <button onClick={this.selectTask.bind(this, item)}>
                            select
                          </button>
                        )}
                        <DeleteTask
                          deleteTask={this.deleteTask.bind(this, item.task_id)}
                        />
                        <img
                          onClick={this.moveTask.bind(this, item)}
                          src={Movefolder}
                          height="25px"
                          width="25px"
                          alt=""
                          style={{
                            background: "white",
                            cursor: "pointer",
                            marginLeft: "20px",
                            marginTop: "-10px",
                          }}
                        />
                        <i
                          class="fa fa-check fa-2x"
                          onClick={this.taskSelect.bind(this, item)}
                          style={{
                            background: "white",
                            cursor: "pointer",
                            marginLeft: "20px",
                          }}
                        ></i>
                      </div>
                      {this.state.isModalVisibile && (
                        <Taskmodal
                          open={this.state.isModalVisibile}
                          onCloseModal={this.onCloseCompleteModal}
                          nowText={this.handleNow}
                          laterText={this.handleLater}
                          isvisibile={true}
                        />
                      )}
                    </li>
                  );
                })
              ) : (
                <div style={{ marginTop: "25%", marginLeft: "47%" }}>
                  <p style={{ fontWeight: "300", fontSize: "24px" }}>
                    No Data{" "}
                  </p>
                </div>
              )}
            </ul>
          </div>
        ) : (
          <div style={{ marginLeft: "45%", marginTop: "30%" }}>
            <DirectiveLoader />
          </div>
        )}

        {
          <EditTask
            closePanelForEdit={this.closePanelForEdit}
            editTaskName={this.state.editTaskName}
            handleEditTaskName={this.handleEditTaskName}
            handleTaskType={this.handleEditTaskType}
            editTaskType={this.state.editTaskType}
            editTaskDate={this.state.editTaskDate}
            clearEditDate={this.clearEditDate}
            handleEditDate={this.handleEditDate}
            updateTask={this.updateTask}
          />
        }

        {
          // <MoveTask
          //   taskType={this.state.editTaskType}
          //   taskName={this.state.editTaskName}
          //   taskDate={this.state.editTaskDate}
          //   isLoading={this.state.isLoading}
          //   projectID={this.state.projectId}
          //   projectName={this.state.projectName}
          //   muppID={this.state.muppID}
          //   muppPath={this.state.muppPath}
          //   chosenMuppCategory={chosenMuppCategory}
          //   taskID={this.state.taskID}
          //   notifySuccess={this.notifySuccess}
          //   notifyWarning={this.notifyWarning}
          //   closePanel={this.closePanel}
          //   fetchTaskData={this.fetchTaskData}
          // />

          <MoveTask
            taskType={this.state.moveTaskType}
            taskName={this.state.moveTaskName}
            taskDate={this.state.moveTaskDate}
            isLoading={this.state.isLoading}
            taskID={this.state.moveTaskId}
            notifySuccess={this.notifySuccess}
            notifyWarning={this.notifyWarning}
            closePanel={this.closePanel}
            fetchTaskData={this.fetchTaskData}
            projectID={this.state.moveProjectId}
            projectName={this.state.moveProjectName}
            muppID={this.state.moveMuppID}
            muppPath={this.state.moveMuppPath}
            moveMuppCategoryId={this.state.moveMuppCategoryId}
          />
        }

        {
          <CompleteTask
            onClose={this.onCloseCompleteNavR}
            handleTaskType={this.handleTaskType}
            handleDate={this.handleDate}
            handleTaskName={this.handleTaskName}
            handleSubmitTask={this.handleSubmitTask}
            taskName={this.state.completeCreateTaskName}
            taskType={this.state.completeCreateTaskType}
            taskDate={this.state.completeCreateTaskDueDate}
          />
        }

        <ToastContainer hideProgressBar={false}></ToastContainer>
      </div>
    );
  }
}
export default Waiting;

let styles = {
  textMultiLineLimit: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
};
