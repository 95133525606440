import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { checkSession } from '../../../constants/api-config'
import './scienceStyle.css'
import Header from '../../Functional-component/Header/Header'

class Science extends Component {
    constructor(props) {
        super(props);
        this.state = {
            continue: false,
            completed: false,
            discoverySessionId: '',
            sessionName :localStorage.getItem('sessionName')
        }
        this.handleScience = this.handleScience.bind(this)

    }
    async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

        let isCompleted = localStorage.getItem('completed')
        let discoverySessionId = localStorage.getItem('discoverySessionId')
        await this.setState({ continue: isCompleted, discoverySessionId: discoverySessionId });
        let value = localStorage.getItem('token')
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${value}` },

        }

        let checksessionApi = checkSession + "?discovery_session_id=" + this.state.discoverySessionId;



        fetch(checksessionApi, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    let resData = result.completed
                    this.setState({ completed: resData })
                },
                (error) => {
                    console.log("error", error)
                }
            )
    }

    handleScience = () => {
        this.props.history.push('TestLink')
    }

    handleArt = () => {
        this.props.history.push({
            pathname: '/Art',
            state: {
                completed: false,
                discovery_session_id: this.state.discoverySessionId
            }
        })
    }
    

    render() {
        return (
            <div className="col-sm-12 padding-0">
                <Header header={this.state.sessionName} />
            <div className="self-discovery-div" style={{ marginTop: "15%" }}>
                <div className="scienceText">
                    <p onClick={this.handleScience} >Science</p>
                </div>
                <div className="scienceText" >

                    <p className="text" onClick={this.handleArt} style={{ cursor: 'pointer', fontSize: '25px' }}>Art</p>
                </div>
                <button className="completedBtn" hidden={!this.state.completed} onClick={() => {
                    this.props.history.push({
                        pathname: '/SelfPage1',
                    })
                }}>Next</button>
            </div>
            </div>
        )
    }
}

export default withRouter(Science)