import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../Functional-component/Header/Header';
import { updateMuppRanks, getMuppsWithAllPos, mupp, UpdateMuppPath } from '../../../constants/api-config';
import { UpdateProjectmuppRanks } from '../../../constants/api-config';
import { ToastContainer, toast } from 'react-toastify';
import Delete from "../../../assests/delete.jpg"


import './MuppOrder.css'
class MuppOrder extends Component {
    constructor(props) {
        super(props);
        this.value = localStorage.getItem('token');
        this.state = {
            editCheckbox: null,
            results: [],
            categories: [],
            row: "",
            editpath: {
                category: "",
                status: false,
                path: "",
                rank_for_mupps: 0,
                id: 0,
                description: '',
                select_for_ratings: '',
                rough_text: '',
                select_for_pos: '',
                category_id: ''
            },

        }
    }


    componentDidMount = async () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        await fetch(getMuppsWithAllPos, {
            "method": "GET",
            "headers": { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
        }).then(response => response.json())
            .then(response => {
                let arr = []
                for (let category of response) {
                    let a = []
                    for (let mupp in category.mupps) {
                        a.push(false)
                    }
                    arr.push(a)
                }

                this.setState({
                    categories: response,
                    editCheckbox: arr
                })
            })
            .catch(err => {
                console.log(err);
                //this.notifyWarning("Something Went Wrong");
            });
    }


    updateMuppRankApiCall = () => {
        let tempMuppArray = this.state.categories.map((item, index) => {

            return item.mupps;
        })

        tempMuppArray = tempMuppArray.flat(1);
        console.log("tempMuppArray", tempMuppArray)
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "mupps": tempMuppArray
            })
        }
        fetch(updateMuppRanks, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'true') {
                        console.log("tempMuppArray", result)
                        this.notifySuccess("MUPP order selected")
                    }
                },
                (error) => {
                    console.log("error ", error);
                })
    }


    updateMuppRankApiCallaftergrag = () => {
        let tempMuppArray = this.state.categories.map((item, index) => {

            return item.mupps;
        })

        tempMuppArray = tempMuppArray.flat(1);
        console.log("tempMuppArray", tempMuppArray)
        let token = localStorage.getItem('token');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
            },
            body: JSON.stringify({
                "mupps": tempMuppArray
            })
        }
        fetch(updateMuppRanks, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.success === 'true') {
                        console.log("tempMuppArray", result)
                        this.notifySuccessafterdrag("Rank Updated")
                    }
                },
                (error) => {
                    console.log("error ", error);
                })
    }
    // updateMuppRankApiCall = () => {
    //     let tempMuppArray = this.state.categories.map((item, index) => {
    //         return item.mupps;
    //     })

    //     tempMuppArray = tempMuppArray.flat(1);
    //     console.log("pos")
    //     let token = localStorage.getItem('token');
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json', "Authorization": `JWT ${token}`,
    //         },
    //         body: JSON.stringify({
    //             "mupps": tempMuppArray
    //         })
    //     }
    //     fetch(updateMuppRanks, requestOptions)
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 if (result.success === 'true') {
    //                     // this.notifySuccess("MUPP order selected")
    //                 }
    //             },
    //             (error) => {
    //                 console.log("error ", error);
    //             })
    // }


    // updateMuppsAPICall = (e) => {
    //     e.preventDefault();
    //     const payload = {
    //         mupp_id: this.state.editpath.id,
    //         rank_for_mupps: this.state.editpath.rank_for_mupps,
    //         select_for_ratings: this.state.editpath.select_for_ratings,
    //         description: this.state.editpath.description,
    //         select_for_pos: this.state.editpath.select_for_ratings,
    //         rough_text: this.state.editpath.rough_text
    //     };

    //     fetch(mupp, {
    //         method: "PUT",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `JWT ${this.value}`,
    //         },
    //         body: JSON.stringify(payload),
    //     })
    //         .then((response) => response.json())
    //         .then((response) => {
    //             this.componentDidMount();
    //             this.notifySuccess("Update Mupps Successfully");
    //         })
    //         .catch((err) => {
    //             //this.notifyWarning("Something Went Wrong");
    //         });
    // };

    deleteMuppsAPI = (e) => {
        e.preventDefault();
        fetch(mupp + "/?mupp_id=" + this.state.muppsProjectIdForDelete, {
            method: "Delete",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${this.value}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                this.componentDidMount();
                this.notifySuccess("Delete Mupps Successfully");
            })
            .catch((err) => {
                //this.notifyWarning("Something Went Wrong");
            });
    };

    onEdit = (e) => {
        e.preventDefault();
        console.log("Editpath data ", this.state.editpath)
        // const payload = {
        //     mupp_id: this.state.editpath.mupp_id,
        //     rank_for_mupps: this.state.editpath.rank_for_mupps,
        //     select_for_ratings: this.state.editpath.select_for_ratings,
        //     description:this.state.editpath.description,
        //     select_for_ratings:this.state.editpath.select_for_ratings,
        //     rough_text:this.state.editpath.rough_text
        // };
        // console.log("payload",payload)
        // let formData = new FormData();
        // formData.append("mupp_id",this.state.editpath.mupp_id)
        // formData.append("rank_for_mupps",this.state.editpath.rank_for_mupps)
        // formData.append("select_for_ratings",this.state.editpath.select_for_ratings)
        // formData.append("description",this.state.editpath.description)
        // formData.append("rough_text",this.state.editpath.rough_text)
        // formData.append("mupp_id",this.state.editpath.mupp_id)
        fetch(UpdateMuppPath, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${this.value}`,
            },
            body: JSON.stringify({ "mupp": this.state.editpath }),
        })
            .then((response) => response.json())
            .then((response) => {
                this.componentDidMount();
                // this.notifySuccess("Update Mupps Successfully");
            })
            .catch((err) => {
                //this.notifyWarning("Something Went Wrong");
            });

        //window.location.assign('/MuppOrder')

    }
    // updateMuppRankApiCall = async () => {
    //    let tempMuppArray = this.state.categories.map((item, index) => {

    //         return item.mupps;
    //     })

    //     tempMuppArray=tempMuppArray.flat(1);
    //     console.log("tempMuppArray", tempMuppArray)
    //     const payload = {
    //         mupps: tempMuppArray
    //     }
    //     await fetch(updateMuppRanks, payload, {
    //         "method": "POST",
    //         "headers": { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
    //     }).then(response => response.json())
    //         .then(response => {
    //             console.log("updateMuppRanks", response);

    //         })
    //         .catch(err => {
    //             console.log(err);
    //             //this.notifyWarning("Something Went Wrong");
    //         });
    // }



    // onCloseModal = () => {

    //     this.setState({ openeditmodal: false })
    // }

    notifySuccess = (item) => toast.success(item, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/page4') }
    });

    notifySuccessafterdrag = (item) => toast.success(item, {
        hideProgressBar: false,
        autoClose: 1000,
        // onClose: () => { this.props.history.push('/page4') }
    });

    notifyWarningon = (item) => toast.success(item, {
        hideProgressBar: false,
        autoClose: 1000,
        onClose: () => { this.props.history.push('/') }
    });

    onSubmit = () => {
        this.updateMuppRankApiCall();
        // this.props.history.push('/page4');
    }
    start = (event) => {
        this.row = event.target;
    }

    onClickButton = e => {
        e.preventDefault()
        // this.state.editpath.status = true;
        this.setState({ openeditmodal: true })
    }


    dragover = (e, data) => {
        e.preventDefault();
        console.log("DATA", data);

        let children = Array.from(e.target.parentNode.parentNode.children);
        if (children.indexOf(e.target.parentNode) > children.indexOf(this.row)) {
            e.target.parentNode.after(this.row);
        } else {
            e.target.parentNode.before(this.row);
        }
        console.log("CHILDREN", children.indexOf(this.row) + 1);

        let finalData = this.state.categories.map((item, index) => {
            let categoryMupp = item.mupps.map((mupp, muppIndex) => {
                if (mupp.id === data.id && mupp.rank_for_mupps !== children.indexOf(this.row) + 1) {
                    mupp.rank_for_mupps = children.indexOf(this.row) + 1;
                }
                return mupp;
            })

            return { ...item, mupps: categoryMupp }
        })
        this.setState({
            categories: finalData,
        })
    }


    dragStop = (e) => {
        e.preventDefault()
        this.updateMuppRankApiCallaftergrag()
        // if (this.state.categories && this.state.categories.length > 0) {
        //     var data = {
        //       mupp : this.state.categories
        //     }
        //     var requestOptions = {
        //         method: 'PUT',
        //         headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
        //         body: JSON.stringify(data)
        //     }
        //     fetch(mupp, requestOptions)
        //         .then(res => res.json())
        //         .then((response) => {
        //             this.componentDidMount();
        //             // this.notifyRankupdate("Updated Rank Successfully")
        //         })
        //         .catch((error) => {
        //             this.notifyWarning("Something Went Wrong");
        //         });
        //     // localStorage.setItem('defaultPosition', { valueX: e.target.parentNode.after(this.row) , valueY : e.target.parentNode.before(this.row) });
        // }
    }

    resetEditProjectState = (e) => {
        const temp = document.getElementById(
            "isdeleteImport" + this.state.editpath.rank_for_mupps
        )
        temp && (temp.checked = false);
        this.setState({ editpath: { path: "", rank_for_mupps: 0, id: 0 } })
        this.setState({ path: "" });
        //window.location.assign('/MuppOrder/')
    }


    onChecked(item) {
        let finalData = this.state.categories.map((item, index) => {
            let categoryMupp = item.mupps.map((mupp, muppIndex) => {
                if (mupp.id === item.id) {
                    mupp.select_for_pos = !mupp.select_for_pos;
                }
                return mupp;
            })

            return { ...item, mupps: categoryMupp }
        })
        this.setState({
            categories: finalData,
        })
        // console.log(finalData);
    }


    onDeletemuppId = (e) => {

        e.preventDefault();
        const data1 = {
            id: this.state.editpath.id,
        }
        // var requestOptions = {
        //     method: 'DELETE',
        //     headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
        //     body: JSON.stringify(data)
        // }
        // fetch(mupp, requestOptions)
        fetch(mupp + "/?mupp_id=" + this.state.editpath.id, {
            method: "Delete",
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${this.value}`,
            },

        }).then(res => res.json())
            .then((response) => {
                // this.componentDidMount()
                let delarr = []
                for (let item of this.state.categories) {
                    let flag = false
                    for (let muppitem of item.mupps) {
                        if (muppitem.id !== data1.id) {
                            if (flag) {
                                muppitem.rank_for_mupps = muppitem.rank_for_mupps - 1
                            }
                            delarr.push(muppitem)
                        }
                        else {
                            flag = true
                        }
                    }
                }
                var data2 = {
                    "mupps": delarr
                    // mupp_id: delarr.id,
                    // description: delarr.description,
                    // rank_for_mupps: delarr.rank_for_mupps,
                    // category_id: delarr.categories.category_i
                }
                console.log("data", data2)
                var requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${this.value}` },
                    body: JSON.stringify(data2)
                }
                fetch(UpdateProjectmuppRanks, requestOptions)
                    .then(res => res.json())
                    .then((response) => {
                        this.componentDidMount();
                        // this.notifySuccess("Updated Successfully")
                    })
                    .catch((error) => {
                        console.log(error)
                        this.notifyWarningon();
                    });
            }).catch((error) => {
                // this.notifyWarning("Something Went Wrong");
            });

        //window.location.reload('/MuppOrder')
    }

    handleRefresh = () => {
        // by calling this method react re-renders the component
        this.setState({});
    };

    openeditmodal = () => {
        return (
            <div className="add-project-btn-div">
                <div className="modal fade" id="myModal" role="dialog" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" style={{ color: "black" }}>Edit Path</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={(e) => {
                                    this.setState({
                                        editCheckbox: (() => {
                                            let arr = []
                                            for (let category of this.state.categories) {
                                                let a = []
                                                for (let mupp in category.mupps) {
                                                    a.push(false)
                                                }
                                                arr.push(a)
                                            }
                                            return arr
                                        })()
                                    })
                                    this.resetEditProjectState();
                                }}>&times;</button>
                            </div>
                            <div className="modal-body modal-div">
                                <label className="col-sm-2 form-group" style={{ color: "black" }}>Path</label>
                                <textarea
                                    type="text"
                                    name="path"
                                    value={this.state.editpath.path}
                                    className="form-control col-sm-10"
                                    onChange={(item) => {

                                        this.setState({ editpath: { ...this.state.editpath, path: item.target.value } })
                                        console.log("mupp data", this.state.editpath);
                                    }
                                    }
                                    placeholder="Enter Path"
                                    required
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="continue-button" data-dismiss="modal" onClick={(e) => this.onEdit(e)}>Save</button>
                                <button type="button" className="continue-button" data-dismiss="modal" onClick={(e) => {
                                    this.setState({
                                        editCheckbox: (() => {
                                            let arr = []
                                            for (let category of this.state.categories) {
                                                let a = []
                                                for (let mupp in category.mupps) {
                                                    a.push(false)
                                                }
                                                arr.push(a)
                                            }
                                            return arr
                                        })()
                                    })
                                    this.resetEditProjectState();
                                }}>Cancel</button>
                                <img
                                    onClick={(e) => {
                                        this.componentDidMount();
                                        this.onDeletemuppId(e);
                                        this.resetEditProjectState();
                                    }}
                                    data-dismiss="modal"
                                    src={Delete}
                                    style={{ cursor: "pointer", marginLeft: "15px", marginTop: "-10px" }}
                                    height="25px"
                                    width="25px"
                                    alt=""
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    tableData = () => {
        if (this.state.categories && this.state.categories.length > 0) {
            return (
                this.state.categories.map((data, i) => (
                    <tr key={i}>
                        <td>
                            <div>{data.category}</div>
                        </td>
                        <td>
                            <table>
                                <thead >
                                    <tr>
                                        <th style={{ width: "80%" }} >Path</th>
                                        <th style={{ width: "10%" }}>Selected</th>
                                        <th style={{ width: "10%" }}>Order</th>
                                        <th style={{ width: "5%" }}></th>
                                    </tr>
                                </thead>
                                <tbody onDragEnd={(e) => this.dragStop(e)}>
                                    {data.mupps.map((item, index) => {
                                        return <tr key={index} draggable='true' onDragStart={this.start} onDragOver={(e) => this.dragover(e, item)}>
                                            <td style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px" }}>
                                                {item.path}
                                            </td>
                                            <td>
                                                <div><input className="select-mupp-checkbox" type="checkbox" defaultChecked={item.select_for_pos} value={item.select_for_pos} onClick={(e) => this.onChecked(item)} /></div>
                                            </td>
                                            <td>
                                                <div>{item.rank_for_mupps}</div>
                                            </td>
                                            <td>
                                                <input type="checkbox" name='isdeleteImportant' data-toggle="modal" data-target="#myModal" checked={this.state.editCheckbox[i][index]} onClick={(e) => {
                                                    this.state.editCheckbox[i][index] = !this.state.editCheckbox[i][index]
                                                    this.setState({
                                                        editpath: {
                                                            path: item.path,
                                                            rank_for_mupps: item.rank_for_mupps,
                                                            status: true,
                                                            id: item.id,
                                                            category: data.category,
                                                            description: item.description,
                                                            rough_text: item.rough_text,
                                                            select_for_pos: Boolean(item.select_for_pos),
                                                            category_id: data.category_id
                                                        },
                                                        editCheckbox: [...this.state.editCheckbox]
                                                    })
                                                }} id={'isdeleteImportant' + item.id} className="is-delete-checkbox" />
                                            </td>
                                        </tr>

                                    })}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                ))
            )
        } else {
            return (
                <tr>
                    <td colSpan="3" className="no-result-found">No Results Found</td>
                </tr>
            )
        }
    }

    render() {
        return (
            <div className="col-sm-12 padding-0">
                <Header header="Mupp Order" />
                <div className="col-sm-12 table-responsive">
                    <table className="table table-bordered ">
                        <thead >
                            <tr>
                                <th style={{ width: "10%" }}>Category</th>
                                <th></th>
                            </tr>
                        </thead>
                        {this.tableData()}
                    </table>
                </div>
                <div style={{ textAlign: "right" }}>
                    <button onClick={() => { this.onSubmit(); }}>Submit</button>
                </div>
                <ToastContainer hideProgressBar={false}></ToastContainer>
                {this.openeditmodal()}
            </div>

        )
    }
}

export default withRouter(MuppOrder);