
import './TermsConditions.css';


const TermsCondition = () => {
    return (
        <div className="container " >
            <h3 className="terms">I-INFINITY SUBSCRIPTION TERMS AND CONDITIONS</h3>
            <p style={{marginTop:"20px"}}>PLEASE READ THIS TERMS OF USE AGREEMENT CAREFULLY BEFORE USING THIS SITE.  </p> 
            <p>i-Infinity Transformations (<b>"i-Infinity" "we," "us"</b>) provides this web site i-infinitytransformations.com and the mobile application i-Infinity Transformations (collectively, the <b>"Site"</b>) subject to your compliance with the terms and conditions set forth in this agreement (the <b>"Agreement"</b>). This Agreement governs the relationship between the i-Infinity and you, the Site visitor and/or member (<b>"you"</b> or <b>“Customer”</b>) with respect to your use of the Site. It is important that you read carefully and understand the terms and conditions of this Agreement. By using the Site, you agree to be bound by this Agreement. 
            </p>

            <h6 id="1">1. Scope and use of the subscription</h6>
            <p>The subscription grants the Customer (being the legal entity that has taken out the subscription) the right to use i-Infinity Site and services, (<b>“the Services”</b>). The subscription may not be used by other people or organizations.</p>

            <h6 id="2">2. Acceptance of the subscription</h6>
            <p>The Site may require you to become a registered user. By registering on the Site, you agree to (a) provide accurate, current and complete information as prompted by our registration form; and (b) maintain and update your information to keep it accurate and complete. In case any information provided by you (or on your behalf) is untrue, inaccurate, not current or incomplete or contains a misrepresentation, we reserve the right to terminate this Agreement and your use of the Site.
            </p>
            <p>As part of the registration process, you will create login credentials by providing an email address and selecting a password. Only one person may use the username and password associated with an account. We reserve the right to refuse to grant you a username that impersonates someone else, is or may be illegal, is or may be protected by trademark or other proprietary rights law, is vulgar or otherwise offensive, or may cause confusion, as determined by us in our sole discretion. We reserve the right to suspend and/or terminate your access at any time in our sole discretion. We shall not be responsible for losses incurred as the result of a user’s misuse of email addresses, passwords or their registration.
            </p>
            <p>You will be responsible for the confidentiality and use of your username and password and agree not to transfer or resell your use of or access to the Site to any third party. If you have reason to believe that your account with us is no longer secure, you must promptly change your password by visiting https://apply.commonapp.org and immediately notify us of the problem by clicking here. YOU ARE ENTIRELY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF YOUR USERNAME AND PASSWORD AND FOR ANY AND ALL ACTIVITIES (INCLUDING PURCHASES, AS APPLICABLE) THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.</p>

            <p>In the course of your use of the Site and/or the Services, you may be asked to provide certain personal information to us (such information referred to hereinafter as <b>"User Information"</b>). Our information collection and use policies with respect to the privacy of such User Information are set forth in the privacy policy which is incorporated herein by reference for all purposes. You acknowledge and agree that you are solely responsible for the accuracy and content of User Information.</p>


            <h6 id="3">3. Term and Termination of the subscription</h6>
            <p>When you sign up for an account and agree to this Agreement, the Agreement between you and i-Infinity is formed, and the term of the Agreement (the “Term”) will begin. We may terminate, change, suspend or discontinue any aspect of the Site or the Services at any time. We may restrict, suspend or terminate your access to the Site and/or the Services if we believe you are in breach of our terms and conditions or applicable law, or for any other reason without notice or liability. 
            </p>
            

            <h6 id="4">4. Price and payment terms</h6>
            <p>The use of certain Services may be subject to payment of particular fees, as determined by us in our sole discretion (“<b>Fee</b>(s)”). If you wish to receive or use Services, you are required to pay all applicable Fees in advance.
            </p>
            <p>We reserve the right to change the Fees at any time, upon notice to you if such change may affect your existing subscriptions. If you received a discount or other promotional offer, we would have the right to automatically and without notice renew your subscription to Service(s) at the full applicable Fee.</p>
            <p>To the extent permitted by law (and unless specified otherwise by us in writing), all Fees are exclusive of all taxes (including value added tax, sales tax, goods and services tax, etc.), levies or duties imposed by taxing authorities (“<b>Taxes</b>”), and you shall be responsible for payment of all applicable Taxes relating to your use of the Services, or to any payments or purchases made by you. We recommend that you verify the existence of any additional fees you may be charged by third parties in connection with the purchase of Services or in connection with the renewal thereof (such as international transaction fees, currency exchange fees or fees due to banks or credit card companies). We will not be responsible for any such additional fees or costs.</p>
            <p>We will not be responsible for any loss, damage, costs, expenses or other claims of the Customer or any third party resulting from the suspension of the Services.</p>
            
            
            <h6 id="5">5. Operating stability </h6>
            <p>i-infinity aims to provide the highest possible degree of operating stability but is not responsible for any breakdown caused by factors beyond its control. Such breakdown includes but is not limited to power failures, errors occurring in modem equipment, ADSL connections, telecommunications connections or the like. In all events, i-Infinity aims to re-establish normal operations as quickly as possible.</p>


            <h6 id="6">6. Maintenance of the System</h6>
            <p>i-infinity is entitled to make operational changes to the System for improvements or otherwise (for example by developing or updating software) without giving the Customer prior notice. Notice of such a suspension will be given to the Customer in advance. i-infinity will not be responsible for any consequences of such a suspension where notice has been given.
            </p>


            <h6 id="7">7. Rights</h6>
            <p>The Site remains the full property of i-Infinity. Individually customized software relating to the Services also remains the property of i-Infinity unless otherwise stipulated. i-Infinity may at any time transfer its rights and obligations under this agreement to any economic affiliate, subsidiary or business unit, or any of their affiliated companies or divisions. Except as provided above, the rights and obligations of the Customer under this Agreement may not be transferred to any third party without the written consent of the i-Infinity.</p>


            <h6 id="8">8. Liability of i-Infinity</h6>
            <p>i-Infinity has taken reasonable measures to ensure that the Services are virus-free, but no warranty is given that the Site and the Services are free from infection from viruses etc., and i-Infinity shall have no liability if this is not the case. To the extent permitted by law, i-Infinity disclaims all warranties with respect to the Services, either express or implied, including but not limited to any implied warranties of suitability or fitness for any particular purpose.</p>
            <p>We control and operate the Site from our offices in India. We do not represent that materials on the Site are appropriate or available for use in other locations. Persons who choose to access the Site from other locations do so on their own initiative, and are responsible for compliance with local laws, if and to the extent local laws are applicable. We reserve the right to limit the availability of the Site and/or the provision of any service described thereon to any person, geographic area or jurisdiction, at any time and in our sole discretion, and to limit the quantities of any such service that we provide. We also reserve the right in our sole discretion to suspend account of any Customer without further or special notice.</p>
            <p>For the avoidance of doubt, in no instance will we be liable for any losses or damages you suffer if you use the Service in violation of this Agreement, regardless of whether we terminate or suspend your account due to such violation. Notwithstanding anything contained herein, i-Infinity shall not be liable to the Customer for any loss or damage caused (including business interruption) arising directly or indirectly, except to the extent that such liability may not be lawfully excluded under the applicable law. Notwithstanding anything contained herein, i-Infinity’s liability will be INR 1000/-.</p>


            <h6 id="9">9. Disclaimers</h6>
            <p>THE SITE, INCLUDING, WITHOUT LIMITATION, ALL SERVICES, FEATURES, CONTENT, FUNCTIONS AND MATERIALS PROVIDED THROUGH THE SITE, ARE PROVIDED "AS IS," "AS AVAILABLE," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING SERVICES, UPTIME OR UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY, PLAYABILITY, DISPLAYABILITY, ACCURACY, PRECISION, CORRECTNESS, THOROUGHNESS, COMPLETENESS, USEFULNESS, OR CONTENT OF INFORMATION, AND ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND WE HEREBY DISCLAIM ANY AND ALL SUCH WARRANTIES, EXPRESS AND IMPLIED. WE DO NOT WARRANT THAT THE SERVICES, CONTENT, FUNCTIONS OR MATERIALS PROVIDED THROUGH THE SITE WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. WE MAKE NO WARRANTY THAT THE SITE OR THE PROVIDED SERVICES WILL MEET USERS' REQUIREMENTS. NO ADVICE, RESULTS OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. WE AND OUR AFFILIATES ALSO ASSUME NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR EQUIPMENT ON ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING IN THE SITE OR YOUR DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO CONTENT, OR AUDIO CONTENT FROM THE SITE. IF YOU ARE DISSATISFIED WITH THE SITE, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SITE.</p>
            <p>WE TRY TO ENSURE THAT THE INFORMATION POSTED ON THE SITE IS CORRECT AND UP TO DATE. WE RESERVE THE RIGHT TO CHANGE OR MAKE CORRECTIONS TO ANY OF THE INFORMATION PROVIDED ON THE SITE AT ANY TIME AND WITHOUT ANY PRIOR WARNING. WE NEITHER ENDORSE NOR ARE RESPONSIBLE FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE OR STATEMENT ON THE SITE, NOR FOR ANY OFFENSIVE, DEFAMATORY, OBSCENE, INDECENT, UNLAWFUL OR INFRINGING POSTING MADE THEREON BY ANYONE OTHER THAN OUR AUTHORIZED EMPLOYEE SPOKESPERSONS WHILE ACTING IN THEIR OFFICIAL CAPACITIES (INCLUDING, WITHOUT LIMITATION, OTHER USERS OF THE SITE). IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION, OPINION, ADVICE OR OTHER CONTENT AVAILABLE THROUGH THE SITE. </p>
            <p>The Site may contain links to other internet web sites, including affiliated web sites which may or may not be owned or operated by i-Infinity. We have not reviewed all of the web sites that are or may be linked to the Site and have no control over such sites. We are not responsible for the content of such web sites, any updates or changes to such sites, or the privacy or other practices of such sites, and the fact that we offer such links does not indicate any approval or endorsement of any material contained on any linked site. </p>

            <h6 id="10">10. Confidentiality</h6>
            <p>All information (except Customer’s personal information) is the i-Infinity’s ‘Confidential Information’. You must keep the i-Infinity’s Confidential Information confidential and must not use it for any purpose except for the purposes of making Applications and using this Site in accordance with this Agreement.</p>


            <h6 id="11">11. Marketing</h6>
            <p>i-Infinity shall be entitled to refer to the Customer and the Services provided in its marketing, including a brief description of such Services.</p>

            <h6 id="12">12. Changes to the Agreement, System changes and feature updates</h6>
            <p>i-Infinity shall inform its users on the Site about system changes, feature updates, and other technical news by e-mail. We reserve the right, at our sole discretion, to change, modify, add or remove any portion of this Agreement, in whole or in part, at any time. Changes in the Agreement will be effective when posted. </p>


            <h6 id="13">13. Indemnification.</h6>
            <p>You agree to defend, indemnify and hold harmless us and our affiliates, and our directors, officers, employees, and agents and those of our affiliates, from any and all claims, liabilities, costs, and expenses, including attorneys' fees, arising in any way from your use of the Site or the Services, from your placement or transmission of any message, content, information, software, or other materials on or through the Site, or from your breach or violation of the law or of this Agreement. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with our defense of such claim.</p>

            <h6 id="14">14. Prohibited Use.</h6>
            <p>Unless otherwise specifically indicated in the Agreement or on the Site, any commercial or promotional distribution, publishing, or exploitation of the Site, or any content, code, data, or materials on the Site, is strictly prohibited unless you have received the express prior written permission from our authorized personnel or the otherwise applicable rights holder. Other than as expressly allowed herein or on the Site, you may not download, post, display, publish, copy, reproduce, distribute, transmit, modify, perform, broadcast, transfer, create derivative works from, sell or otherwise exploit any content, code, data, or materials on or available through the Site. You further agree that you may not alter, edit, delete, remove, otherwise change the meaning or appearance of, or repurpose, any of the content, code, data, or other materials on or available through the Site, including, without limitation, the alteration or removal of any trademarks, trade names, logos, service marks, or any other proprietary content or proprietary rights notices. You acknowledge that you do not acquire any ownership rights by downloading or otherwise using any copyrighted material from or through the Site. If you make other use of the Site, or the content, code, data, or materials thereon or available through the Site, except as otherwise provided above, you may violate copyright and other laws of India, other countries, as well as applicable state laws and may be subject to liability for such unauthorized use.</p>

            <h6 id="15">15. Proprietary Rights.</h6>
            <p>As between you and us, we own, solely and exclusively, all rights, title, and interest in and to the Site, all the content (including, for example, audio, photographs, illustrations, graphics, other visuals, video, copy, text, software, titles, Shockwave files, etc.), code, data, and materials thereon, the look and feel, design, and organization of the Site, and the compilation of the content, code, data, and materials on the Site, including but not limited to any copyrights, trademark rights, patent rights, database rights, moral rights, sui generis rights, and other intellectual property and proprietary rights therein. Your use of the Site does not grant to you ownership of any content, code, data, or materials you may access on or through the Site.</p>
            <p>No permission or license is granted or implied to use the trademarks, logos, service marks and trade names displayed on the Site, on content available through the Site, or in connection with the Services (collectively the "<b>Trademarks</b>"). Our Trademarks may not be used without our prior written permission, and the Trademarks of third parties may not be used without the prior written permission of their respective owners.</p>

            <h6 id="16">16. Force Majeure</h6>
            <p>We will not be held liable for any delays or failure in performance of any part of the Service, from any cause beyond our control. This includes, but is not limited to, acts of god, changes to law or regulations, embargoes, war, terrorist acts, riots, fires, earthquakes, nuclear accidents, epidemic, pandemic, floods, strikes, power blackouts, volcanic action, unusually severe weather conditions, government restrictions, lockdown, change in laws, government orders and acts of hackers, or third-party internet service providers.</p>

            <h6 id="17">17. Governing law and dispute resolution</h6>
            <p>These Terms shall be governed by and construed in accordance with the laws of India and the courts of Gurugram, Haryana shall have exclusive jurisdiction to determine any dispute concerning this Agreement. Any matter, questions, disputes, differences and/or claims arising out of and/or in connection and/or in consequences of breaches, termination or invalidity thereof or relating to this Agreement whether or not obligations of either or both Parties under this Agreement be subsisting at the time of such dispute and whether or not this Agreement has been terminated or purported to be terminated or completed shall be settled by arbitration in accordance with the provisions of the Arbitration and Conciliation Act 1996 or any statutory modification or re-enactment thereof for the time being in force. The arbitration shall be referred to the sole arbitrator to be appointed by i-Infinity. The award passed by the sole arbitrator shall be final and binding on both the Parties to this Agreement. Subject to the award of the arbitrator, the cost shall be borne by the Parties equally. The seat and venue of such arbitration proceedings shall be at Gurugram, India. The language of arbitration shall be English.</p>
        
            <h6 id="18">18. Equitable Relief</h6>
            <p>Your violation of this Agreement may cause irreparable harm to us and our team. Therefore, we have the right to seek injunctive relief or other equitable relief if you violate this Agreement.</p>
            <p>In case of any queries please reach out to us on <a href = "mailto: bsuri@i-infinity.in" style={{fontSize:"15px"}}>bsuri@i-infinity.in.</a></p>
        </div>
    );
};



export default TermsCondition;
